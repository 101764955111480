import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getListingsForLanguage } from 'routes/Careers';
import { mapStateToContent } from 'utils/cms';
import bpConnect from 'components/bpConnect';
import './Footer.scss';

export const Footer = ({ setClass, bpIsGreaterThan, bpIsLessThan, nextPage, language, content }) => {
  const FooterNext = (
    <Link className="footernext" to={ nextPage.pathname }>
      <div className="footernext__cta">
        <span className="typ--medium">Next</span><br />
        <h1 className={ setClass({ tabletLg: 'typ--h3' }) }>{ nextPage.label }</h1>
      </div>
      <div className="footernext__circle">
        <span className="icon-long-arrow-right" />
      </div>
    </Link>
  );

  const careerListings = getListingsForLanguage(content.orderedContent, language);

  return (
    <footer className="footer">
      <div className="row">
        { bpIsLessThan('tabletLg') && FooterNext }

        <div className="cf mb6">
          <div className={ setClass({ default: 'col--4', tabletLg: 'col--12 mb5' }) }>
            <h4 className="sectiontitle typ--bold">Menu</h4>

            <div className="cf">
              <div className="footer__col6">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/team">People</Link></li>
                  <li><Link to="/companies">Companies</Link></li>
                  <li><Link to="/approach">Approach</Link></li>
                  <li><Link to="/principles">Principles</Link></li>
                </ul>
              </div>

              <div className="footer__col6 pl2">
                <ul>
                  <li><Link to="/locations">Locations</Link></li>
                  { careerListings.length > 0 && <li><Link to="/careers">Careers</Link></li> }
                  <li><Link to="/blog">Blog</Link></li>
                  <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                  <li><Link to="/terms-of-use">Terms of Use</Link></li>
                  <li><a href="https://www.fundpanel.io/Login" to="/login">LP Login</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className={ setClass({ default: 'col--2', tabletLg: 'col--6' }) }>
            <h4 className="sectiontitle typ--bold">Contact</h4>
            <ul>
              { language==="en" && <li><a href="mailto:contact@wilab.com">contact@wilab.com</a></li> }
              { language==="jp" && <li><Link to="/contact">Contact Form</Link></li> }

              { language==="en" &&
                <li className="footer__address">
                  <a href="https://maps.app.goo.gl/KvyQVDB6RwEVXc3o6">
                    636 Waverley St. Suite 100<br />
                    Palo Alto, CA 94301
                  </a>
                </li>
              }
            </ul>
          </div>

          <div className={ setClass({ default: 'col--2', tabletLg: 'col--6' }) }>
            <h4 className="sectiontitle typ--bold">Social</h4>
            <ul>
              <li><a href="https://www.facebook.com/worldinnovationlab/" rel="noopener noreferrer" target="_blank">Facebook</a></li>
              { language==="en" && <li><a href="https://twitter.com/wilvc_us" rel="noopener noreferrer" target="_blank">Twitter</a></li> }
              { language==="jp" && <li><a href="https://twitter.com/WiL_jp_pr" rel="noopener noreferrer" target="_blank">Twitter</a></li> }
              <li><a href="https://www.linkedin.com/company/world-innovation-lab-wil-/" rel="noopener noreferrer" taget="_blank">Linkedin</a></li>
            </ul>
          </div>

          { bpIsGreaterThan('tabletLg') && <div className="col--4">{ FooterNext }</div> }
        </div>

        <span className="typ--b3 typ--caps">{ `© ${new Date().getFullYear()} World Innovation Lab (WiL, LLC). All Rights Reserved` }</span>
      </div>
    </footer>
  );
}

const { func, object, string } = PropTypes;
Footer.propTypes = {
  setClass: func,
  bpIsGreaterThan: func,
  bpIsLessThan: func,
  nextPage: object,
  language: string,
  content: object
};

Footer.defaultProps = {
  nextPage: { label: 'Team', pathname: '/team' }
};

export default bpConnect(connect(mapStateToContent('careers'))(Footer));
