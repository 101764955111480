import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapStateToContent } from 'utils/cms';
import { get, isEmpty, debounce } from 'lodash';
import { TweenMax, TimelineMax, Power3, Elastic } from 'gsap';
import bpConnect from 'components/bpConnect';
import WhyJapan from 'routes/WhyJapan';
import WhatWeDo from 'routes/WhatWeDo';
import OurValues from 'routes/OurValues';
import Locations from 'routes/Locations';
import { boundsToJSON } from 'utils/helpers';
import Circles from './Circles';
import Lottie from 'lottie-react-web'
import wil_logo from './lottie/wil_logo.json';
import './Home.scss';
import { setFirstLoad } from 'redux/actions';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = { animating: false };
    this.onInComplete = this.onInComplete.bind(this);
    this.onOutComplete = this.onOutComplete.bind(this);
    this.nextSection = this.nextSection.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.animateCircle = this.animateCircle.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);
  }

  resetCirclePosition () {
    if (this.circle) this.circle.style.cssText = '';
  }

  scrollHandler (event) {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    if (event.deltaY > 0) this.animateCircleOut();
  }

  componentDidMount () {
    this.debouncedScrollHandler = debounce(this.scrollHandler, 250, { leading: true, trailing: false });
    window.addEventListener('wheel' , this.debouncedScrollHandler);

    const bounds = get(this.props.location, 'state.circleBounds');
    if (bounds) {
      this.triggerAnimationOnEnter();
    }
  }

  handleMove (e) {
    if (!this.state.animating) {
      this.parallaxIt(e, this.heroCircle, -40);
      this.container.querySelectorAll('.circles__item').forEach((node, index) => {
        this.parallaxIt(e, node, (index + 4) * -5);
      });
    }
  }

  parallaxIt(e, target, movement) {
    if (this.props.bpIsLessThan('desktopSm')) return false;
    const bounds = this.container.getBoundingClientRect();
    var relX = e.pageX - bounds.left;
    var relY = e.pageY - bounds.top;

    TweenMax.to(target, 5, {
      x: (relX - bounds.width / 2) / bounds.width * movement,
      y: (relY - bounds.height / 2) / bounds.height * movement
    });
  }

  triggerAnimationOnEnter () {
    this.setState({ animating: true }, () => {
      this.animateCircle();
    });
  }

  componentWillUnmount () {
    window.removeEventListener('wheel' , this.debouncedScrollHandler);
    this.container.removeEventListener('mousemove', this.handleMove);
  }

  componentDidUpdate (prevProps) {
    if ((!isEmpty(this.props.content) && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering'
         && prevProps.transitionStatus !== 'entering' && !get(this.props.location, 'state.circleBounds'))
    ) {
      if (this.container) {
        this.animateIn();
        this.container.addEventListener('mousemove', this.handleMove);
      }
    }

    if (this.props.transitionStatus === 'exiting' && prevProps !== 'entering') {
      this.animatePageOut();
    }
  }

  animateCircle () {
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;

    if (!bounds) return false;
    if (this.props.bpIsLessThan('tabletLg')) return false;
    if (!this.circle) return;

    tl.set(this.splash, { zIndex: 0, pointerEvents: 'none' });
    tl.set(this.circle, { position: 'fixed' }, 'anim');

    tl.fromTo(this.circle, 0.8, {
      height: bounds.height,
      width: bounds.width,
      left: bounds.left,
      top: bounds.top
    },
    {
      height: this.heroCircle.offsetHeight,
      width: this.heroCircle.offsetWidth,
      left: (window.innerWidth / 2) - (this.heroCircle.offsetWidth / 2),
      top: (window.innerHeight / 2) - (this.heroCircle.offsetHeight / 2),
      ease: Power3.easeInOut
    }, 'anim+=0.3');

    tl.staggerFrom(document.querySelectorAll('.circles__item'), 1.2,
      { scale: 0, ease: Elastic.easeOut.config(0.8, 0.5) },
      0.02, 'anim+=0.4');

    tl.staggerFrom([this.arrow, this.subtitle, this.title], 0.4,
      {
        marginTop: 30,
        opacity: 0,
        onComplete: () => {
          this.setState({ animating: false }, () => {
            this.container.addEventListener('mousemove', this.handleMove);
          })
        }
      }
      , 0.3, 'anim+=0.6');

    tl.set(this.circle, {
      position: 'relative',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%'
    }, 'anim+=0.8');

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateIn () {
    const { dispatch, firstLoad } = this.props;
    const tl = new TimelineMax();

    this.setState({ animating: true });
    const delay = firstLoad ? 0 : 1;

    if (!firstLoad) {
      tl.to(this.logo, 0.6, { opacity: 1 }, 'anim');

      tl.to(this.splash, 0.6,
        { opacity: 0 },
        'anim+=1.5');

      tl.from(document.querySelector('.header'), 0.6, {
        opacity: 0
      }, 'anim+=2.1');

      tl.set(this.splash, {
        zIndex: 0,
        pointerEvents: 'none'
      }, 'anim+=2.1');
    } else {
      tl.set(this.splash,
        { opacity: 0 },
        'anim');
    }

    tl.fromTo(this.circle, 0.6,
      { scale: 0, ease: Power3.easeOut },
      { scale: 1, onComplete: this.onInComplete },
      `anim+=${delay + 0.7}`);

    tl.to(this.logo, 0.6,
      { scale: 0.7, opacity: 0, ease: Power3.easeOut },
      `anim+=${delay + 0.7}`);

    tl.staggerFrom(document.querySelectorAll('.circles__item'), 0.5,
      { scale: 0, ease: Power3.easeOut },
      0.02, `anim+=${delay + 0.75}`);

    tl.staggerFrom([this.title, this.subtitle], 3,
      { opacity: 0, ease: Power3.easeOut },
      0.6, `anim+=${delay + 1.5}`);

    tl.from(this.arrow, 0.7, { opacity: 0, marginTop: -30 }, `anim+=${delay + 2.8}`);

    dispatch(setFirstLoad(true));

    return tl;
  }

  animateCircleOut () {
    const tl = new TimelineMax();
    if (this.props.bpIsLessThan('tabletLg')) return false;

    tl.staggerTo([this.title, this.subtitle, this.arrow], 0.5,
      { marginTop: 30, opacity: 0 }, 0.2, 'anim');

    tl.staggerTo(document.querySelectorAll('.circles__item'), 0.5,
      { scale: 0 },
      0.04, 'anim+=0.2');

    tl.set(this.circle, { onComplete: this.onOutComplete }, 'anim+=0.5');

    return tl;
  }

  animatePageOut () {
    const tl = new TimelineMax();
    if (this.props.bpIsLessThan('tabletLg')) return false;

    tl.to(this.container, 1, { opacity: 0 }, 'anim');

    tl.staggerTo([this.title, this.subtitle, this.arrow], 0.4,
      { marginTop: 30, opacity: 0 }, 0.2, 'anim');

    tl.staggerTo(document.querySelectorAll('.circles__item'), 0.4,
      { scale: 0 },
      0.04, 'anim+=0.2');

    return tl;
  }

  onInComplete () {
    this.setState({ animating: false });
  }

  onOutComplete () {
    this.props.history.push({
      pathname: '/us-japan-global-bridge',
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });
  }

  nextSection () {
    const { bpIsGreaterThan } = this.props;

    if (bpIsGreaterThan('tabletLg')) {
      this.animateCircleOut();
    } else {
      const targetPosition = document.querySelector('.whyjapan').offsetTop;
      TweenMax.to(document.scrollingElement, 0.4, { scrollTop: targetPosition });
    }
  }

  render () {
    const { content, bpIsLessThan, setClass } = this.props;
    const { animating } = this.state;
    const pageContent = get(content, 'orderedContent[0]');
    const splash = (
      <div className="home__splash" ref={ el => { this.splash = el; } } style={ { height: window.innerHeight } }>
        <div ref={ el => { this.logo = el; } } className="home__splash__logo">
          <Lottie
            options={ {
              animationData: wil_logo,
              loop: false,
              autoplay: false
            } }
          />
        </div>
      </div>
    );

    if (!pageContent) return splash;

    return (
      <React.Fragment>
        { splash }
        <div className="home" ref={ el => { this.container = el; } }>
          <div className="home__wrapper">
            { pageContent.subsections &&
              <Circles circleData={ pageContent.subsections } />
            }
            <div
              className={ `herocircle ${animating ? 'is-animating' : ''}` }
              onClick={ this.nextSection }
              ref={ el => { this.heroCircle = el; } }
            >
              <div className="circle" ref={ el => { this.circle = el; } } />
              <div className="circle__content">
                <h1 className={ `typ--semibold typ--jp ${setClass({ default: 'mb3', mobileXsm: 'mb1' })}` } ref={ el => { this.title = el; } }>{ pageContent.title }</h1>
                <h4 className={ `typ--jp ${setClass({ mobileLg: 'typ--b1' })}` } ref={ el => { this.subtitle = el; } }>{ pageContent.subtitle }</h4>
                <button ref={ el => { this.arrow = el; } } className="icon-long-arrow-down" />
              </div>
            </div>
          </div>

          { bpIsLessThan('tabletLg') &&
            <React.Fragment>
              <WhyJapan />
              <WhatWeDo />
              <OurValues />
              <Locations />
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

const { object, string, func, bool } = PropTypes;
Home.propTypes = {
  content: object,
  location: object,
  history: object,
  transitionStatus: string,
  bpIsLessThan: func,
  setClass: func,
  firstLoad: bool,
  dispatch: func
};

export default bpConnect(connect(mapStateToContent('home'))(Home));
