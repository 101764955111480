import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import content from './content';
import language from './language';
import breakpoint from './breakpoint';
import loader from './loader';
import teamSortIndex from './teamSortIndex';

export const initialState = {};

export default history => combineReducers({
  router: connectRouter(history),
  content,
  language,
  breakpoint,
  loader,
  teamSortIndex
});
