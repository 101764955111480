import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import bpConnect from "components/bpConnect";
import "./Header.scss";

import { routes, filterRoutes } from "routes/index";

/** The base header for the application */
// export const Header = ({ language, location, bpIsGreaterThan }) => {

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = { animating: false, Yoffset: 0 };
  }

  componentDidMount() {
    // 先にbindしてから使う
    this.eventHandler = this.onScroll.bind(this);
    // this.intervalEventHandler = this.intervalEvent.bind(this);
    window.addEventListener("scroll", this.eventHandler, true);
    // window.addEventListener("DOMContentLoaded", this.intervalEventHandler, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location){
      this.onScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.eventHandler, true);
    // window.removeEventListener("DOMContentLoaded", this.intervalEventHandler, true);
  }

  onScroll() {
    if (document.querySelector(".scroller") != null) {
      this.setState({ Yoffset: document.querySelector(".scroller").scrollTop });
    } else {
      this.setState({ Yoffset: window.pageYOffset });
    }
  }

  // intervalEvent() {
  //   setInterval(() => {
  //     this.onScroll();
  //   }, 500);
  // }

  render() {
    const { language, location, bpIsGreaterThan } = this.props;

    return (
      <div className="header__wrapper">
        <header className="header" ref={ this.node }>
          <Link to="/">
            <img src={ require("assets/img/wil_logo-dots.svg") } alt="Wil" />
          </Link>

          {bpIsGreaterThan("mobileLg") ? (
            <ul className="header__links">
              {language === "en"
                ? filterRoutes(routes, language)
                  .filter(route => !route.hideInHeader )
                  .map((r, i) => (
                    <li className={ `${this.state.Yoffset > 200 ? "is-hidden" : "is-shown"}` } key={ i }>
                      <Link className={ `header-links__link ${r.path === location.pathname ? "is-active" : ""}` } to={ r.path }>
                        {r.name}
                      </Link>
                    </li>
                  ))
                : filterRoutes(routes, language)
                  .filter(route => !route.hideInHeader || route.showInJP)
                  .map((r, i) => (
                    <li className={ `${this.state.Yoffset > 200 ? "is-hidden" : "is-shown"}` } key={ i }>
                      <Link className={ `header-links__link ${r.path === location.pathname ? "is-active" : ""}` } to={ r.path }>
                        {r.name}
                      </Link>
                    </li>
                  ))}
              <Link className="icon-menu typ--h4" to={ { pathname: "nav", state: { currentPage: location.pathname } } } />
            </ul>
          ) : (
            <Link className="icon-menu typ--h4" to={ { pathname: "nav", state: { currentPage: location.pathname } } } />
          )}
        </header>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  location: state.router.location
});

// Header.setState({
//   theposition: window.pageYOffset
// });

const { object, func, string } = PropTypes;
Header.propTypes = {
  /** The app language controls which links to display in the header */
  language: string,
  location: object,
  bpIsGreaterThan: func
};

export default bpConnect(connect(mapStateToProps)(Header));
