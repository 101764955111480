import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { mapStateToContent } from 'utils/cms';
import Loader from 'components/Loader';
import bpConnect from 'components/bpConnect';
import { TimelineMax } from 'gsap';
import Markdown from 'components/Markdown';
import './TermsOfUse.scss'

export class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate (prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering')) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll('.policypost');

    tl.from(this.container, 0.4, { opacity: 0 }, 'anim+=0.2');

    tl.staggerFrom([this.title, ...entries], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll('.policypost');

    tl.staggerTo([...entries, this.title], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim');

    tl.to(this.container, 0.4, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  render () {
    const { content, setClass, language } = this.props;
    const policies = content.orderedContent;
    if (!policies) return <Loader />;

    return (
      <div className="scroller" ref={ el => { this.container = el; } }>
        <div className="row privacypolicy pagecontent">
          <div className="cf">
            <div className={ `privacypolicy__header ${setClass({ default: 'col--3 layout--relative', tabletLg: ' ' })}` }>

              <h1
                ref={ el => { this.title = el; } }
                className={ `sectiontitle typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }
              >{ content.subtitle }</h1>
            </div>

            <div className={ setClass({ default: 'col--9', tabletLg: ' ' }) }>
              { policies.map((policy, index) => (
                policy.text &&
                <div className="policypost" id={ `policypost${index}` } key={ index }>
                  <h4 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>{ policy.title }</h4>
                  <Markdown source={ policy.text } />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer nextPage={ { label: 'Home', pathname: '/' } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
TermsOfUse.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string,
  language: string
}

export default bpConnect(connect(mapStateToContent('termsofuse'))(TermsOfUse));
