import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import { get, kebabCase } from 'lodash';
import bpConnect from 'components/bpConnect';
import './CompaniesCarousel.scss';

export const CompaniesCarousel = ({ history, data, setClass, bpIsGreaterThan, bpIsLessThan }) => {
  const triggerQuoteModal = section => {
    history.push(`/companies/${kebabCase(get(section, 'name'))}`);
  };

  return (
    <div className="row mb4">
      <div className={ `companiescarousel__tagline ${setClass({ default: 'col--5', tabletLg: ' ' })}` }>
        <h1 className={ `sectiontitle typ--jp ${setClass({
          default: 'mt10', desktopMd: 'typ--h2 mt10', mobileLg: 'typ--h2 mt3'
        })}` }>{ data.subtitle }</h1>
      </div>
      <Carousel
        className="companiescarousel"
        showDots
        showArrows={ false }
        autoPlay
        slides={ data.subsections.map((section, index) => (
          <div key={ index } className="cf">
            { bpIsGreaterThan('tabletLg') && <div className="col--6 gridspacer" /> }

            <div
              onClick={ () => bpIsLessThan('tabletLg') && triggerQuoteModal(section) }
              className={ `companiescarousel__image ${setClass({
                default: 'col--6',
                tabletLg: 'col--9 col--center',
                mobileLg: ' ' })}`
              }
            >
              <img className="companiescarousel__slideimg" src={ get(section, 'image.file.url') } alt={ get(section, 'image.title') } />
              <img className="companiescarousel__slidelogo" src={ get(section, 'logo.file.url') } alt={ get(section, 'logo.title') } />

              { bpIsGreaterThan('tabletLg') &&
                <div className="companiescarousel__quote">
                  <div>
                    { section.quote && <p className={ setClass({ default: 'typ--b1 mb2', desktopMd: 'typ--b2 mb1' }) }>{ `“${section.quote}”` }</p> }
                    { section.author && <p className="mb0 typ--b2 typ--bold">&mdash; { section.author }</p> }
                  </div>
                </div>
              }
            </div>
          </div>
        )) }
      />
    </div>
  )
};

const { object, func } = PropTypes;
CompaniesCarousel.propTypes = {
  data: object,
  setClass: func,
  bpIsGreaterThan: func,
  bpIsLessThan: func,
  history: object
};

export default bpConnect(CompaniesCarousel);
