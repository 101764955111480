import React from 'react';
import { kebabCase, get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Markdown from 'components/Markdown';
import { mapStateToContent, fetchContent } from 'utils/cms';
import Loader from 'components/Loader';
import { TimelineMax, Power2 } from 'gsap';
//import { push } from 'connected-react-router';
import FancyLink from 'components/FancyLink';
import bpConnect from 'components/bpConnect';
import { isRetinaDisplay } from 'utils/helpers';
import { Link } from 'react-router-dom';
import './Article.scss';

export class Article extends React.Component {
  constructor (props) {
    super(props);

    this.state = { authors: [], authorsJp:[] };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount () {
    // ---------- Get team members name for creating link 
    fetchContent("en").then(response => {
      let teamMembersContent = response.items.find(e => e.fields.id === "team").fields.orderedContent;
      let teamMembersName = teamMembersContent.map(el => el.fields.name);
      this.setState({
        authors: teamMembersName
      });
    });
    fetchContent("jp").then(response => {
      let teamMembersContentJp = response.items.find(e => e.fields.id === "team").fields.orderedContent;
      let teamMembersNameJp = teamMembersContentJp.map(el => el.fields.name);
      this.setState({
        authorsJp: teamMembersNameJp
      });
    });
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') this.animateOpen();
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') this.animateClosed();
  }

  animationTargets() {
    return [this.close, this.meta, this.title, this.download, this.hero, this.content].filter(el => el);
  }

  animateOpen () {
    const tl = new TimelineMax();

    tl.from(this.container, 0.3, {
      backgroundColor: "rgba(255, 255, 255, 0)"
    }, 'anim');

    tl.from(this.heading, 0.4, {
      backgroundColor: 'rgb(255, 255, 255)'
    }, 'anim');

    tl.staggerFrom(this.animationTargets(), 0.4, {
      opacity: 0,
      y: 20,
      ease: Power2.easeOut
    }, 0.06, 'anim+=0.2');

    return tl;
  }

  animateClosed () {
    const tl = new TimelineMax();

    if (!this.animationTargets().length) {
      return null;
    }

    tl.staggerTo(this.animationTargets(), 0.4, {
      opacity: 0,
      y: 20,
      ease: Power2.easeOut
    }, 0.06, 'anim');

    tl.to(this.heading, 0.4, {
      backgroundColor: 'rgb(255, 255, 255)'
    }, 'anim');

    tl.to(this.container, 0.8, {
      opacity: 0,
      zIndex: 100
    }, 'anim+=0.3');

    return tl;
  }

  closeModal() {
    const { location, history } = this.props;
    const dest = { pathname: '/blog', state: { duration: 1000 } };

    dest.pathname = get(location, 'state.returnTo') || '/blog';
    dest.state = Object.assign({}, dest.state, get(location, 'state') || {});

    history.push(dest);
  }

  render () {
    //const { content, language, location, dispatch, setClass, bpIsGreaterThan, bpIsLessThan } = this.props;
    const { content, englishContent, japaneseContent, language, location, setClass, bpIsGreaterThan, bpIsLessThan } = this.props;
    const { authors, authorsJp } = this.state;

    const insightData = content.orderedContent;
    const englishInsightData = englishContent.orderedContent;
    const japaneseInsightData = japaneseContent.orderedContent;

    if (!insightData) return <Loader timeoutPath="/insights" />;

    const insightSlug = location.pathname.split('/')[2];
    let insight = insightData.find((insight) => { return kebabCase(insight.title) === insightSlug });

    if (!insight && language==="jp") { 
      let articleIndex = 0;
      if (englishInsightData) { articleIndex = englishInsightData.findIndex((insight) => kebabCase(insight.title) === insightSlug ); }
      insight = japaneseInsightData[articleIndex];
    }

    if (!insight && language==="en") { 
      let articleIndex = 0;
      if (japaneseInsightData) { articleIndex = japaneseInsightData.findIndex((insight) => kebabCase(insight.title) === insightSlug ); }
      insight = englishInsightData[articleIndex];
    }

    if (!insight) { return <Loader timeoutPath="/insights" />; }

    //---------- previous code for backup ----------
    //if (insight.language && insight.language !== language) {
    //dispatch(push('/insights'));
    //return null;
    //}
    
    let url = encodeURI(window.location.href);
    let postBody = 'Check out this article!';

    if (language==="jp") {
      postBody = 'Check out this article!'+'「'+insight.title+'」';
    }
    

    const social = [
      { name: 'Twitter', link: `https://twitter.com/intent/tweet?text=${postBody} ${url} ` },
      { name: 'LinkedIn', link: `//www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=${postBody}&source=` },
      { name: 'Facebook', link: `//www.facebook.com/sharer/sharer.php?u=${url}` },
      { name: 'Email', link: `mailto:?&subject=&body=${url} ${postBody}` }
    ];

    const imgWidth = isRetinaDisplay() ? 800 : 400;
    const insightImage = insight.image && `${insight.image.file.url}?w=${imgWidth}`;

    const featuredImage = (
      <div className="article__image">
        { insight.image &&
          <img
            src={ insightImage }
            alt={ insight.image.file.title }
            ref={ el => { this.hero = el; } }
          />
        }
      </div>
    );

    return (
      <section className="article" ref={ el => { this.container = el; } } style={ { height: window.innerHeight } }>
        <Helmet>
          <meta property="og:image" content={ insightImage } />
        </Helmet>
        <div className="modal__close" onClick={ this.closeModal } ref={ el => { this.close = el; } }>
          <span className="icon-close" />
        </div>

        <div className="article__head typ--jp" ref={ el => { this.heading = el; } }>
          <div className="row">
            <div className={ `article__flex layout--relative cf ${setClass({ default: 'pb6', tabletSm: 'pb4' })}` }>
              { bpIsLessThan('tabletMd') && featuredImage }
              <div className={ `article__title ${setClass({ default: 'col--7', tabletMd: ' ' })}` }>
                <div className="article__meta" ref={ el => { this.meta = el; } }>
                  {language === "en" && (
                    authors && authors.indexOf(insight.author) >= 0 ? 
                    // ----- Link to WiL's members' prof page
                      insight.author && 
                      <Link
                        to={ {
                          pathname:`/team/${kebabCase(insight.author)}`,
                          state: { memberBounds: 0 }
                        } }                          
                        onClick={ () => {
                        // history setting might be added later.
                        } }
                      >
                        <div className="article__author__link">
                          { insight.author }
                        </div>
                      </Link>
                      : 
                      <div className="article__author">
                        { insight.author }
                      </div>
                  )
                  }
                  {language === "jp" && (
                    (authorsJp && authorsJp.indexOf(insight.author) >= 0) || (authors && authors.indexOf(insight.author) >= 0) ?
                    // ----- Link to WiL's members' prof page
                      insight.author && 
                      <Link
                        to={ {
                          pathname:`/team/${ kebabCase(authors[authorsJp.indexOf(insight.author)] || kebabCase(insight.author) )}`,
                          state: { memberBounds: 0 }
                        } }                          
                        onClick={ () => {
                        // history setting might be added later.
                        } }
                      >
                        <div className="article__author__link">
                          { insight.author }
                        </div>
                      </Link>
                      : 
                      <div className="article__author">
                        { insight.author }
                      </div>
                  )
                  }
                  <div>{ insight.tags && insight.tags.map(t => t.name).join(', ') }</div>
                </div>

                <h1
                  className={ `mt2 ${setClass({ tabletSm: 'typ--h3' })}` }
                  ref={ el => { this.title = el; } }
                >{ insight.title }</h1>
                <div ref={ el => { this.download = el; } }>
                  {
                    insight.pdf &&
                      <FancyLink
                        className="btn mt3"
                        target="_blank"
                        to={ insight.pdf.file.url }
                      >
                        Download PDF
                      </FancyLink>
                  }
                </div>
              </div>

              { bpIsGreaterThan('tabletMd') && featuredImage }
            </div>
          </div>
        </div>

        <article className="article__body row pb3 typ--jp" ref={ el => { this.content = el; } }>
          <div className={ `article__social ${setClass({ default: 'col--5', desktopSm: 'col--3', tabletMd: ' ' })}` }>
            <ul>
              {
                social.map((soc, ind) => (
                  <li key={ ind }>
                    <a key={ ind } href={ soc.link } target="_blank" rel="noopener noreferrer">
                      <i className={ `icon-${soc.name.toLowerCase()}` } />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className={ setClass({ default: 'col--7', desktopSm: 'col--9', tabletMd: ' ' }) }>
            <Markdown source={ insight.text } />
          </div>
        </article>
      </section>
    );
  }
}

const { object, string, func } = PropTypes;
Article.propTypes = {
  location: object,
  content: object,
  japaneseContent: object,
  englishContent: object,
  language: string,
  history: object,
  transitionStatus: string,
  //dispatch: func,
  setClass: func,
  bpIsGreaterThan: func,
  bpIsLessThan: func
};

export default bpConnect(connect(mapStateToContent('insights'))(Article));
