import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { TimelineMax } from 'gsap';
import './Accordian.scss';

export class Accordian extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.open && !prevProps.open) this.animateOpen();
    if (!this.props.open && prevProps.open) this.animateClosed();

    if (prevState.dimensions.width === -1 && this.state.dimensions.width > -1 && this.props.open) {
      this.animateOpen();
    } 
  }

  animateOpen () {
    const { duration } = this.props;

    const tl = new TimelineMax();
    tl.to(this.content, duration, { opacity: 1, height: this.state.dimensions.height });
    return tl;
  }

  animateClosed () {
    const { duration } = this.props;

    const tl = new TimelineMax();
    tl.to(this.content, duration * 1.3, { opacity: 0, height: 0 });
    return tl;
  }

  render () {
    const { children } = this.props;

    return (
      <div className="accordian__content" ref={ el => { this.content = el; } }>
        <Measure bounds onResize={ rect => this.setState({ dimensions: rect.bounds }) }>
          { ({ measureRef }) => <div ref={ measureRef }>{ children }</div> }
        </Measure>
      </div>
    );
  }
}

const { node, bool, number } = PropTypes;
Accordian.propTypes = {
  children: node,
  open: bool,
  duration: number
};

Accordian.defaultProps = {
  duration: 0.7
};

export default Accordian;