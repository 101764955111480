import React from 'react';
import Tabs from 'components/Tabs';
import { get } from 'lodash';
import Footer from 'components/Footer';
import { TimelineMax, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import { mapStateToContent } from 'utils/cms';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import bpConnect from 'components/bpConnect';
import { boundsToJSON } from 'utils/helpers';
import './Locations.scss';

export class Locations extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeSortIndex: 0,
      fadeInIndex: 0,
      fadeOutIndex: null,
      lastScrollTime: Date.now()
    };

    this.onSortLocation = this.onSortLocation.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.onOutComplete = this.onOutComplete.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);
  }

  resetCirclePosition () {
    if (this.circle) this.circle.style.cssText = '';
    this.setState({ animating: false });
  }

  scrollHandler (event) {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;

    if (Date.now() > this.state.lastScrollTime + 50) {
      const scrollContainer = this.container;
      if (event.deltaY < 0 && scrollContainer.scrollTop === 0) {
        this.animatePrev('/our-values');
      }
    }

    this.setState({ lastScrollTime: Date.now() });
  }

  componentDidMount () {
    setTimeout(() => { window.addEventListener('wheel' , this.scrollHandler); }, 1000);
  }

  componentWillUnmount () {
    window.removeEventListener('wheel' , this.scrollHandler);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;
    const newBounds = document.querySelector('.locations__circle').getBoundingClientRect();

    if (!bounds) return false;

    this.setState({ animating: true });

    tl.from(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.set(this.circle, { position: 'fixed', zIndex: '10' }, 'anim');

    tl.from(this.circle, 0.4, {
      height: bounds.height,
      width: bounds.width,
      ease: Power3.easeIn
    }, 'anim');

    tl.fromTo(this.circle, 0.6, {
      left: bounds.left + (bounds.width / 2),
      top: bounds.top + (bounds.height / 2),
      ease: Power3.easeOut
    },
    {
      left: newBounds.left + (newBounds.width / 2),
      top: newBounds.top + (newBounds.height / 2),
      ease: Power3.easeOut
    }, 'anim+=0.4');

    tl.staggerFrom([this.title, this.footer, this.map], 0.4, {
      y: 20,
      opacity: 0
    }, 'anim+=0.4');

    tl.set(this.circle, {
      position: 'absolute',
      left: '50%',
      top: '50%',
      x: '-50%',
      y: '-50%'
    }, 'anim+=1');

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateOut () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    tl.staggerTo([this.title, this.map, this.footer], 0.8, {
      y: 20,
      opacity: 0
    }, 0.05,  'anim');

    tl.to([this.circle, this.container], 0.4, {
      opacity: 0
    }, 'anim+=0.2');

    return tl;
  }

  animatePrev (routeTo) {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.to(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.staggerTo([this.title, this.map, this.footer], 0.8, {
      y: 20,
      opacity: 0
    }, 0.06, 'anim');

    tl.set(this.circle, {
      opacity: 0
    }, 'anim+=0.4');

    tl.set(this.circle, {
      onComplete: this.onOutComplete,
      onCompleteParams: [routeTo]
    }, 'anim+=0.3');

    return tl;
  }

  onOutComplete (routeTo) {
    this.props.history.push({
      pathname: routeTo,
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });
  }

  onSortLocation (index) {
    this.setState({ fadeOutIndex: this.state.fadeInIndex, fadeInIndex: index });
  }

  generateMapClasses(index) {
    const { fadeInIndex } = this.state;
    let classes = '';

    if (index === fadeInIndex) {
      classes = 'fade-in';
    } else {
      classes = 'fade-out';
    }

    return classes;
  }

  getAddressHtml (location) {
    const splitAddress = get(location, 'fullAddress').split('\n');

    return (
      <React.Fragment>
        {
          splitAddress.map((add, ind) =>
            <React.Fragment key={ ind }>
              <span>{ add }</span>
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }

  getMapLink (location) {
    const { lat, lon } = location.geolocation;
    return `http://maps.apple.com/?q=${lat},${lon}`;
  }

  render () {
    const { setClass, language } = this.props;
    const { activeSortIndex, fadeInIndex } = this.state;
    if (!this.props.content.orderedContent) return <Loader />;
    const content = this.props.content.orderedContent.find(c => c.id === 'home-locations');
    const locationData = content.subsections;

    return (
      <div className={ `locations ${setClass({ default: 'scroller', tabletLg: ' ' })}` } ref={ el => { this.container = el; } }>
        <div className="row">
          <div
            className={ setClass({ default: 'col--6', tabletLg: ' ' }) }
            style={ { zIndex: 2, position: 'relative' } }
            ref={ el => { this.title = el; } }
          >
            <h1
              className={ `sectiontitle typ--jp ${setClass({ default: 'mt9', mobileLg: 'typ--h2 mt6' })}` }
              style={ { maxWidth: '400px' } }
            >{ content.title }</h1>
            <Tabs
              items={ locationData.map(l => l.name) }
              initialActiveIndex={ activeSortIndex }
              onChange={ this.onSortLocation }
            />

            <div className={ `locations__address ${setClass({ default: 'mt10', mobileLg: 'mt2' })}` }>
              <h4 className="typ--bold typ--jp mb2">{ get(locationData[fadeInIndex], 'name') }</h4>
              { get(locationData[fadeInIndex], 'fullAddress') &&
                <React.Fragment>
                  <p className="typ--light typ--jp mb2">
                    { this.getAddressHtml(locationData[fadeInIndex]) }
                  </p>

                  <a
                    className="btn"
                    href={ this.getMapLink(locationData[fadeInIndex]) }
                    rel="noopener noreferrer"
                  >Directions</a>
                </React.Fragment>
              }
            </div>
          </div>

          <div className="locations__map">
            <div ref={ el => { this.map = el; } } style={ { width: '100%', height: '100%' } }>
              { locationData.map((loc, index) => (
                <img
                  key={ index }
                  className={ this.generateMapClasses(index) }
                  src={ `${get(loc, 'image.file.url')}?w=1156` }
                  alt={ get(loc, 'name') }
                />
              )) }
            </div>
            <div className="locations__circle" ref={ el => { this.circle = el; } } />
          </div>
        </div>

        <div ref={ el => { this.footer = el; } } style={ { zIndex: 2, position: 'relative' } }>
          <Footer nextPage={ { label: 'Team', pathname: '/team' } } language={ language } />
        </div>
      </div>
    );
  }
}

const { object, string, func } = PropTypes;
Locations.propTypes = {
  history: object,
  location: object,
  transitionStatus: string,
  content: object,
  setClass: func,
  language: string,
  bpIsLessThan: func
};

export default bpConnect(connect(mapStateToContent('home'))(Locations));
