import React from 'react';
import ReactHelmet from 'react-helmet';
import { connect } from 'react-redux';
import { array, object, string } from 'prop-types';
import { capitalize, kebabCase, get } from 'lodash';

import { routes } from 'routes/index';
import { findMatchingRoute, getParamValues } from 'utils/routing';
import { simplify, findPathToNode } from 'utils/cms';

const fullLanguageCodes = {
  jp: 'ja_JP',
  en: 'en_US'
};

const name = (pageName) => ['WiL - World Innovation Lab', pageName]
  .filter(n => n).join(': ');

export const Helmet = ({ language, route, content, englishContent, paramValues }) => {
  let pageName;
  let node;
  let descriptionNode;

  try {
    // On individual team member page - show team meta data
    if (route.name === 'Member') {
      const enNodePath = findPathToNode(englishContent, el => el.name === paramValues.memberid.split('-').map(capitalize).join(' '));
      node = get(content, enNodePath);
      descriptionNode = get(content, findPathToNode(content, el => el.id === 'team'));
      pageName = node ? node.name : 'Team';

    // On Insight article - show insights meta data, and article title if parseable
    } else if (route.name === 'Article') {
      const urlGeneratedEnglishTitle = paramValues.article.split('-').map(capitalize).join(' ');
      const enNodePath = findPathToNode(englishContent, el => el.title === urlGeneratedEnglishTitle);
      descriptionNode = get(content, findPathToNode(content, el => el.id === 'insights'));
      node = get(content, enNodePath);
      pageName = node ? node.title : (urlGeneratedEnglishTitle || 'Insight');

    // On other page
    // - Show sub-page title/description if on home, or page title
    // - Show page title/description if on other page
    } else {
      const id = route.contentfulId || kebabCase(route.name);
      const nodePath = findPathToNode(content, el => el.id === id);
      node = get(content, nodePath);
      // On home sub-page? Then grab that section's information
      // Otherwise, grab info from the page
      descriptionNode = route.contentfulId
        ? node
        : get(content, findPathToNode(content, el => el.id === route.path.replace('/', '')));
      pageName = node ? node.title : route.name;
    }
  } catch (err) { /* do nothing */ }

  descriptionNode = descriptionNode || node;

  const description = descriptionNode ? descriptionNode.metaDescription || descriptionNode.subtitle : node && node.subtitle;

  return (
    <ReactHelmet defer={ false }>
      <title>{ name(pageName) }</title>
      <meta property="og:locale" content={ fullLanguageCodes[language] } />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={ name(pageName) } />
      <meta property="og:url" content={ `https://wilab.com${route.currentPath}` } />
      <meta property="og:site_name" content={ name() } />
      { description && <meta name="description" content={ description } /> }
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={ name(pageName) } />
    </ReactHelmet>
  );
};

Helmet.propTypes = {
  language: string,
  route: object,
  content: array,
  englishContent: array,
  paramValues: object
};

const mapStateToProps = state => {
  const loc = state.router.location;
  const currentPath = loc.pathname;
  const thisRoute = findMatchingRoute(routes, currentPath);

  let paramValues = getParamValues(currentPath, get(thisRoute, 'path'));

  const content = simplify(state.content[state.language] || [])
  return {
    language: state.language,
    englishContent: state.language === 'en' ? content : simplify(state.content.en || []),
    content,
    route: { ...thisRoute, currentPath: state.router.location.pathname },
    paramValues
  };
};

export default connect(mapStateToProps)(Helmet);
