import React, { Component } from 'react';
import PropTypes, { string } from 'prop-types';
import './Tabs.scss';

export class Tabs extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIndex: props.initialActiveIndex
    };

    this.activeIndexChange = this.activeIndexChange.bind(this);
  }

  activeIndexChange (index) {
    const { onChange } = this.props;
    this.setState({ activeIndex: index });
    if (onChange instanceof Function) onChange(index);
  }

  render () {
    const { items, whereComeFrom } = this.props;
    const { activeIndex } = this.state;

    return (
      <ul className="tabs">
        { items.map((item, index) => (
          <li
            key={ index }
            className={ index === activeIndex ? 'is-active' : '' }
            onClick={ () => activeIndex !== index && this.activeIndexChange(index) }
          >
            { (item === "US" && whereComeFrom === "Companies" ) ? "US/EU" : item}
          </li>
        )) }
      </ul>
    )
  }
}

const { array, number, func } = PropTypes;
Tabs.propTypes = {
  items: array,
  initialActiveIndex: number,
  onChange: func,
  whereComeFrom: string
};

export default Tabs;
