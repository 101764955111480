import React from "react";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { mapStateToContent } from "utils/cms";
// import { get, kebabCase } from 'lodash';
import Loader from "components/Loader";
import bpConnect from "components/bpConnect";
import { TimelineMax } from "gsap";
import Markdown from "components/Markdown";
import FancyLink from "components/FancyLink";
import Carousel from "components/Carousel";
import "./LocationsPage.scss";

export class LocationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) || (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering")) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === "exiting" && prevProps.transitionStatus !== "exiting") {
      this.animateOut();
    }
  }

  animateIn() {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.from(this.container, 0.4, { opacity: 0 }, "anim+=0.2");

    tl.staggerFrom(
      [this.title, ...entries],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.4"
    );

    return tl;
  }

  animateOut() {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.staggerTo(
      [...entries, this.title],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim"
    );

    tl.to(this.container, 0.4, { opacity: 0 }, "anim+=0.4");

    return tl;
  }

  render() {
    const { content, setClass, language } = this.props;
    // const content = this.props.content.orderedContent.find(c => c.id === 'locations-section');
    // const content = this.props.content;
    const locationsPage = content.orderedContent;
    // const locationsPageData = locationsPage[0];
    // const locationsPage = content.subsections;
    // const locationsPageData = content.orderedContent.subsections;
    // const locationData = content.subsections;
    if (!locationsPage) return <Loader />;

    // const width = 200;
    // const height = 200;

    // const styles = {
    //   width,
    //   height
    // };

    return (
      <div
        className="scroller locationsPage"
        ref={ el => {
          this.container = el;
        } }
      >
        <div className="row pt8 pb8">
          <div className="cf">
            <div
              className={ ` ${setClass({
                default: "col--4 layout--relative",
                tabletLg: " "
              })}` }
              ref={ el => {
                this.title = el;
              } }
            >
              <h1
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }
              >
                {locationsPage[0].title}
              </h1>
            </div>

            <div className={ setClass({ default: "col--7", tabletLg: " " }) }>
              <div className="principles__slider">
                {/* <h4 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>abbbbbbb</h4> */}
                <Markdown source={ locationsPage[0].text } />
                <ul className="layout--right btn__container">
                  <li>
                    <FancyLink to={ locationsPage[0].ctaDestination } className="btn layout--right">
                      { locationsPage[0].ctaDestinationText }
                    </FancyLink>
                  </li>
                  <li>
                    <FancyLink to={ locationsPage[0].ctaDestinationSecond } className="btn layout--right">
                      { locationsPage[0].ctaDestinationTextSecond }
                    </FancyLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="gridspacer col--1" />
          </div>

          <div className="cf pt8">
            <div
              className={ `principles__slider ${setClass({
                default: "col--4",
                mobileLg: "col--12"
              })}` }
            >
              <h3 className="mb1">{locationsPage[0].subsections[0].subsections[0].name}</h3>
              <p>{locationsPage[0].subsections[0].subsections[0].fullAddress}</p>
              {/* <div style={styles}> */}
              <div className="">
                <div className="circles__carousel locationsPage__carousel mt4">
                  <Carousel
                    showArrows={ false }
                    draggable={ false }
                    autoPlay={ 4000 }
                    slides={ locationsPage[0].subsections[0].subsections[1].images.map((img, circleIndex) => (
                      <img key={ circleIndex } src={ `${get(img, "file.url")}` } alt={ get(img, "title") } />
                    )) }
                  />
                </div>
              </div>
            </div>
            <div
              className={ `principles__slider ${setClass({
                default: "col--8",
                mobileLg: "col--12"
              })}` }
            >
              <div className="locationsPage__circle__US" />
              <img
                // key={index}
                // className={this.generateMapClasses(index)}
                className="locationsPage__image"
                // src={`${get(loc, "image.file.url")}?w=1156`}
                src={ locationsPage[0].subsections[0].subsections[0].image.file.url }
                // alt={get(loc, "name")}
              />
            </div>
          </div>

          <div className="cf pt8">
            <div
              className={ `principles__slider ${setClass({
                default: "col--4 layout--right",
                mobileLg: "col--12"
              })}` }
            >
              <h3 className="mb1">{locationsPage[0].subsections[1].subsections[0].name}</h3>
              <p>{locationsPage[0].subsections[1].subsections[0].fullAddress}</p>
              <div className="circles__carousel locationsPage__carousel mt4">
                <Carousel
                  showArrows={ false }
                  draggable={ false }
                  autoPlay={ 4000 }
                  slides={ locationsPage[0].subsections[1].subsections[1].images.map((img, circleIndex) => (
                    <img key={ circleIndex } src={ `${get(img, "file.url")}` } alt={ get(img, "title") } />
                  )) }
                />
              </div>
            </div>
            <div
              className={ `principles__slider ${setClass({
                default: "col--8",
                mobileLg: "col--12"
              })}` }
            >
              <div className="locationsPage__circle__JP" />
              <img
                // key={index}
                // className={this.generateMapClasses(index)}
                className="locationsPage__image"
                // src={`${get(loc, "image.file.url")}?w=1156`}
                src={ locationsPage[0].subsections[1].subsections[0].image.file.url }
                // alt={get(loc, "name")}
              />
            </div>
          </div>
        </div>

        <Footer nextPage={ { label: "Careers", pathname: "/careers" } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
LocationsPage.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string,
  language: string
};

export default bpConnect(
  // connect(mapStateToContent("locationsPage"))(LocationsPage)
  connect(mapStateToContent("locationsPage"))(LocationsPage)
);
