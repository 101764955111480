const initialState = null;

const language = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_LANGUAGE': {
      localStorage.setItem('wil-user-language', action.language);
      return action.language;
    }
    default: return state;
  }
}

export default language;
