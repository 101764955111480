const initialState = { firstLoad: false };

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'SET_FIRST_LOAD': {
      return { ...state, firstLoad: action.firstLoad };
    }
    default:
      return state;
  }
}

export default reducer;
