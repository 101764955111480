import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { isEmpty } from "lodash";

class GetOGPImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ogpData: "" };
    this.getOGPUrl = this.getOGPUrl.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.blogpost.link);
    this.getOGPUrl();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.blogpost && isEmpty(prevProps.blogpost)) || this.props.blogpost !== prevProps.blogpost) {
    //   console.log(this.props.blogpost);
      this.getOGPUrl();
    }
  }

  componentWillUnmount() {}

  getOGPUrl() {
    this.setState({ ogpData: "" });
    let sendUrl = this.props.blogpost.link;
    // console.log("changed.changed.changed.changed.changed.changed.")
    // let fetchedData = axios
    axios
      .post("https://wilab.com/.netlify/functions/getOGPurl", sendUrl)
      .then(e => {
        // console.log(e.data);
        this.setState({ ogpData: e.data });
      })
  }

  render() {
    // const { blogpost } = this.props;

    return (
      <>
        {this.state.ogpData.length > 0 && (
          <div className="blog__item__img mr2">
            <img src={ this.state.ogpData } alt="eyecatch image" className="" />
          </div>
        )}
      </>
    );
  }
}

const { object } = PropTypes;
GetOGPImage.propTypes = {
  blogpost: object
};

export default GetOGPImage;
