import React from 'react';
import Isotope from 'isotope-layout';
import { get, kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import imagesLoaded from 'imagesloaded';
import { TimelineMax, Elastic } from 'gsap';
import { connect } from 'react-redux';

import Tabs from 'components/Tabs';
import bpConnect from 'components/bpConnect';
import './ClientGrid.scss';

const sortKeys = ['All', 'US', 'Japan'];

export class ClientGrid extends React.Component {
  constructor (props) {
    super(props);
    this.state = { sortIndex: 1 };
    this.updateSort = this.updateSort.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescription = this.hideDescription.bind(this);
    this.touchHandler = this.touchHandler.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.touched = null;
  }

  componentDidMount () {
    const { startingSortIndex } = this.props;

    this.iso = new Isotope(this.grid, {
      itemSelector: '.iso__item',
      layoutMode: 'fitRows'
    });

    imagesLoaded(this.grid, () => {
      this.iso.layout();
      if (startingSortIndex !== 0) {
        this.reSort(startingSortIndex);
      }
    });
  }

  reSort(sortIndex) {
    if (!this.props.sort) { return; }
    const activeSortKey = kebabCase(sortKeys[sortIndex]);
    const filterValue = activeSortKey === 'all' ? '*' : `.${activeSortKey}`;
    this.iso.arrange({ filter: filterValue });
  }

  updateSort(index) {
    this.setState({ sortIndex: index });
    this.reSort(index);
  }

  showDescription (index) {
    const tl = new TimelineMax();
    const descriptionEl = this.grid.querySelector(`.desc-${index}`);

    tl.to(descriptionEl, 0.4, {
      zIndex: 10,
      scale: 1,
      x: '-50%',
      y: '-50%',
      ease: Elastic.easeOut.config(0.5, 1)
    }, 'anim');

    tl.to(descriptionEl.querySelector('.clientgrid__descriptiontext'), 0.2, {
      y: 0,
      opacity: 1
    }, 'anim+=0.06');

    return tl;
  }

  hideDescription (index, inIndex) {
    const tl = new TimelineMax();
    const descriptionEl = index !== undefined
      ? this.grid.querySelector(`.desc-${index}`)
      : [...this.grid.querySelectorAll(`.clientgrid__description:not(.desc-${inIndex})`)]

    tl.to(descriptionEl, 0.4, {
      scale: 0,
      x: '-50%',
      y: '-50%',
      ease: Elastic.easeOut.config(0.5, 1)
    }, 'anim');

    return tl;
  }

  locationClass(inv) {
    return inv.locations ? inv.locations.map(key => kebabCase(key)).join(' ') : 'all'
  }

  clickHandler (index, link) {
    if (this.touched === index) return false;
    window.open(link);
  }

  touchHandler (index) {
    if (this.touched !== index) {
      this.showDescription(index);
      this.hideDescription(undefined, index);
      this.touched = index;
    } else {
      this.touched = null;
    }
  }

  render () {
    const { clients, title, sort, setClass, startingSortIndex } = this.props;

    return (
      <div className="clientgrid cf">
        <div className={ setClass({ default: 'col--3 pt6', tabletSm: 'mb3', mobileMd: ' ' }) }>
          <h2 className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h3' })}` }>{ title }</h2>

          { sort && <Tabs
            items={ sortKeys }
            initialActiveIndex={ startingSortIndex }
            onChange={ this.updateSort }
          /> }
        </div>

        <div className={ setClass({ default: 'col--9', tabletSm: ' ' }) }>
          <div className="clientgrid__layout" ref={ el => { this.grid = el; } }>
            { clients.filter(client => client.logo !== undefined).map((inv, index) => {
              const link = inv.link ? inv.link : null;

              return (
                <div
                  onMouseEnter={ () => this.showDescription(index) }
                  onMouseLeave={ () => this.hideDescription(index) }
                  className={ `iso__item ${this.locationClass(inv)} ${link ? 'typ--pointer' : ''}` }
                  onClick={ () => link && this.clickHandler(index, link) }
                  onTouchStart={ () => this.touchHandler(index) }
                  key={ index }
                >
                  <img src={ get(inv, 'logo.file.url') } alt={ get(inv, 'logo.title') } />
                  { inv.exitInformation && <p className="clientgrid__exitinfo typ--center">{ inv.exitInformation }</p> }
                  <div className={ `clientgrid__description desc-${index}` } ref={ el => { this.description = el; } }>
                    <div>
                      <div>
                        <h6 className={ `clientgrid__descriptiontext typ--bold typ--jp ${setClass({
                          mobileLg: 'typ--b2'
                        })}` }>{ inv.description }</h6>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) }
          </div>
        </div>
      </div>
    );
  }
}

const { array, string, bool, func, number } = PropTypes;
ClientGrid.propTypes = {
  clients: array,
  title: string,
  sort: bool,
  setClass: func,
  startingSortIndex: number
};

ClientGrid.defaultProps = {
  clients: []
};

const mapStateToProps = state => {
  let index = 0;
  if (state.language === 'en') {
    index = sortKeys.indexOf('US');
  } else if (state.language === 'jp') {
    index = sortKeys.indexOf('Japan');
  }

  return {
    startingSortIndex: index
  };
};

export default bpConnect(connect(mapStateToProps)(ClientGrid));
