import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { mapStateToContent } from 'utils/cms';
import Loader from 'components/Loader';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import JobBody from './JobBody';
import bpConnect from 'components/bpConnect';
import { TimelineMax } from 'gsap';
import { HashLink } from 'react-router-hash-link';
// import { Redirect } from 'react-router-dom';
import './Careers.scss';

export const getListingsForLanguage = (listings, language) => {
  const acceptableCareerCountry = {
    jp: 'Japan',
    en: 'USA'
  }[language];
  return (listings || []).filter(listing => (listing.locations || []).some(l => l.country === acceptableCareerCountry));
}

export class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate (prevProps) {
    if ((this.jobs().length && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering')) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll('.jobpost');

    tl.from(this.container, 0.4, { opacity: 0 }, 'anim+=0.2');

    tl.staggerFrom([this.title, this.img, ...entries], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll('.jobpost');

    tl.staggerTo([...entries, this.img, this.title], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim');

    tl.to(this.container, 0.4, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  jobs() {
    const { content, language } = this.props;
    return getListingsForLanguage(content.orderedContent, language);
  }

  render () {
    // const { content, setClass, bpIsGreaterThan, bps, language } = this.props;
    const { content, setClass, bps, language } = this.props;

    if (!this.jobs()) return <Loader />;
    if (!this.jobs().length) {
      // return <Redirect to="/" />;
    }

    return (
      <div className="scroller" ref={ el => { this.container = el; } }>
        <div className="row careers pagecontent">
          <div className="cf">
            <div className={ `careers__header ${setClass({ default: 'col--3 layout--relative', tabletLg: ' ' })}` }>
              <picture>
                <source media={ `(max-width: ${bps.mobileLg}px)` } srcSet={ require('assets/img/careers/careers_hero-mobile.jpg') } />
                <img ref={ el => { this.img = el; } } src={ require('assets/img/careers/careers_hero.jpg') } alt="Wil office scene" />
              </picture>
              <h1
                ref={ el => { this.title = el; } }
                className={ `sectiontitle typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }
              >{ content.title }</h1>
            </div>

            <div className={ setClass({ default: 'col--9', tabletLg: ' ' }) }>
              { (language==="en" && !this.jobs().length) && <div className="oneSecDelay">We are not hiring at the moment.</div> }
              { (language==="jp" && !this.jobs().length) && <div className="oneSecDelay">現在、募集は行っておりません。</div> }
              { this.jobs().map((job, index) => (
                <div className="jobpost" key={ index }>
                  <img
                    className="jobpost__bgmap"
                    src={ get(job, 'locations[0].image.file.url') }
                    alt={ get(job, 'locations[0].image.title') }
                  />

                  <div className="jobpost__content">
                    <div className="typ--bold mb1 typ--jp">{ job.locations.map(l => l.name).join(', ') }</div>
                    <h2 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>{ job.title }</h2>
                    <h5 className="mb2 typ--jp">
                      { job.subtitle }
                    </h5>
                    { language==="en" &&
                      <div
                        className="jobpost__description typ--light mb2 typ--jp"
                        dangerouslySetInnerHTML={ { __html: documentToHtmlString(job.description) } }
                      />
                    }
                    { language==="jp" &&
                      <div
                        className="jobpost__description typ--light mb2 typ--jp"
                        dangerouslySetInnerHTML={ { __html: documentToHtmlString(job.description) } }
                      />
                    }

                    <JobBody job={ job } />
                  </div>
                </div>
              )) }
              { language==="jp" &&
                <div className="link__privacypolicy">
                  <HashLink
                    to="/privacy-policy#policypost1"
                    scroll={ el => el.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
                  >応募者向け個人情報保護方針</HashLink>
                </div>
              }
            </div>
          </div>
        </div>

        <Footer nextPage={ { label: 'Blog', pathname: '/blog' } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Careers.propTypes = {
  content: object,
  setClass: func,
  // bpIsGreaterThan: func,
  bps: object,
  transitionStatus: string,
  language: string
}

export default bpConnect(connect(mapStateToContent('careers'))(Careers));
