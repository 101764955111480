import React from 'react';
import { get } from 'lodash';
import { TimelineMax, Power3 } from 'gsap';
import PropTypes from 'prop-types';

import { mapStateToContent } from 'utils/cms';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import bpConnect from 'components/bpConnect';
import { boundsToJSON } from 'utils/helpers';
import Accordian from 'components/Accordian';
import Carousel from 'components/Carousel';
import './OurValues.scss';
import { isRetinaDisplay } from '../../utils/helpers';

export class OurValues extends React.Component {
  constructor (props) {
    super(props);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.animateNext = this.animateNext.bind(this);
    this.animatePrev = this.animatePrev.bind(this);
    this.onNextComplete = this.onNextComplete.bind(this);
    this.onPrevComplete = this.onPrevComplete.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);

    this.state = {
      activeIndex: 0,
      lastScrollTime: Date.now(),
      animating: false
    };
  }

  resetCirclePosition () {
    if (this.circle) this.circle.style.cssText = '';
    this.setState({ animating: false });
  }

  scrollHandler (event) {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;

    if (Date.now() > this.state.lastScrollTime + 50) {
      let scrollContainer = this.container;
      if (!scrollContainer) return false;

      if (event.deltaY < 0 && scrollContainer.scrollTop === 0) {
        this.animatePrev();
      }

      if (event.deltaY > 0 && scrollContainer.scrollTop === scrollContainer.scrollHeight - scrollContainer.offsetHeight) {
        this.animateNext();
      }
    }

    this.setState({ lastScrollTime: Date.now() });
  }

  componentDidMount () {
    setTimeout(() => { window.addEventListener('wheel' , this.scrollHandler); }, 1000);
  }

  componentWillUnmount () {
    window.removeEventListener('wheel' , this.scrollHandler);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;
    const newBounds = document.querySelector('.ourvalues__circle').getBoundingClientRect();
    if (!bounds) return false;

    this.setState({ animating: true });

    tl.set(this.circle, { position: 'fixed', zIndex: '10' }, 'anim');

    tl.from(this.container, 1, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.fromTo(this.circle, 0.6, {
      height: bounds.height,
      width: bounds.width,
      paddingBottom: 0,
      left: bounds.left,
      top: bounds.top,
      ease: Power3.easeInOut
    },
    {
      height: newBounds.height,
      width: newBounds.width,
      top: newBounds.top,
      left: newBounds.left
    }, 'anim+=0.1');

    tl.set(this.circle, {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 'auto'
    }, 'anim+0.12');

    tl.staggerFrom([this.heading, this.blurb, this.grid, this.img, this.arrow], 0.4, {
      y: 20,
      opacity: 0
    }, 0.1, 'anim+=0.6');

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateOut () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    tl.staggerTo([this.heading, this.blurb, this.grid, this.img, this.arrow], 0.4, {
      y: -20,
      opacity: 0
    }, 0.1, 'anim+=0.1')

    tl.to(this.circle, 0.4, {
      opacity: 0
    }, 'anim+=0.2');

    return tl;
  }

  animateNext () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.staggerTo([this.heading, this.blurb, this.grid, this.img, this.arrow], 0.4, {
      y: -20,
      opacity: 0
    }, 0.1, 'anim+=0.1')

    tl.set(this.circle, {
      opacity: 0,
      onComplete: this.onNextComplete
    }, 'anim+=0.4');

    return tl;
  }

  animatePrev () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.to(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.to(this.heading, 0.4, {
      y: 20,
      opacity: 0
    }, 'anim');

    tl.staggerTo([this.blurb, this.grid, this.img, this.arrow], 0.4, {
      y: 20,
      opacity: 0
    }, 0.1, 'anim+=0.1')

    tl.set(this.circle, {
      opacity: 0,
      onComplete: this.onPrevComplete
    }, 'anim+=0.4');


    return tl;
  }

  onNextComplete () {
    this.props.history.push({
      pathname: '/locations',
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });
  }

  onPrevComplete () {
    this.props.history.push({
      pathname: '/what-we-do',
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });
  }

  render () {
    const { setClass, bpIsGreaterThan, bpIsLessThan } = this.props;
    const { activeIndex } = this.state;
    if (!this.props.content.orderedContent) return <Loader />;
    const content = this.props.content.orderedContent.find(c => c.id === 'home-our-values');

    return (
      <div className={ `ourvalues ${setClass({ default: 'scroller', tabletLg: ' ', tabletSm: 'mt9' })}` } ref={ el => { this.container = el; } }>
        <div className="row">
          <div className="cf">
            <div className={ setClass({ default: 'col--4', tabletLg: ' ' }) }>
              <h1
                className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h2' })}` }
                ref={ el => { this.heading = el; } }
              >{ content.title }</h1>
            </div>

            <div
              ref={ el => { this.blurb = el; } }
              className={ `cf typ--jp ${setClass({ default: 'mb6', tabletLg: 'mb2' })}` }
            >
              <p style={ { maxWidth: 700 } }>{ content.text }</p>
            </div>
          </div>

          { bpIsGreaterThan('tabletLg')
            ? <div className="ourvalues__sections">
              <div className="ourvalues__imgcontainer" style={ { position: 'relative' } }>
                <div className="ourvalues__circle" ref={ el => { this.circle = el; } } />
                <div className="ourvalues__hero" ref={ el => { this.img = el; } }>
                  { content.subsections.map((section, index) => (
                    <img
                      key={ index }
                      className={ `ourvalues__img ${index === activeIndex ? 'is-active' : ''}` }
                      src={ `${get(section, 'image.file.url')}?w=${isRetinaDisplay() ? 413 : 826 }` }
                      alt={ get(section, 'image.title') }
                    />
                  )) }
                </div>
              </div>

              <div className="ourvalues__grid" ref={ el => { this.grid = el; } }>
                { content.subsections.map((value, index) => {
                  const isActive = index === activeIndex;

                  return (
                    <div
                      key={ index }
                      className={ isActive ? 'is-active' : '' }
                      onMouseEnter={ () => this.setState({ activeIndex: index }) }
                    >
                      <h4 className={ `typ--bold typ--jp ${isActive ? 'typ--primary' : ''}` }>{ value.title }</h4>
                      <Accordian open={ isActive || bpIsLessThan('tabletLg') } duration={ 0.3 }>
                        <p className="typ--light typ--jp">{ value.newText }</p>
                      </Accordian>
                    </div>
                  )
                }) }
              </div>
            </div>
            : <Carousel
              slides={ content.subsections.map((section, index) => (
                <div key={ index }>
                  <img
                    key={ index }
                    className={ `ourvalues__img ${index === activeIndex ? 'is-active' : ''}` }
                    src={ get(section, 'image.file.url') }
                    alt={ get(section, 'image.title') }
                  />
                  <h4 className="typ--bold typ--jp">{ section.title }</h4>
                  <p className="typ--light typ--jp">{ section.text }</p>
                </div>
              )) }
              showArrows={ false }
              wrapAround={ false }
              showDots
            />
          }
        </div>

        { bpIsGreaterThan('tabletLg') &&
          <span
            ref={ el => { this.arrow = el; } }
            className="scrollindicator icon-long-arrow-down"
            onClick={ () => this.animateNext() }
          />
        }
      </div>
    );
  }
}

const { object, string, func } = PropTypes;
OurValues.propTypes = {
  history: object,
  transitionStatus: string,
  location: object,
  content: object,
  bpIsLessThan: func,
  bpIsGreaterThan: func,
  setClass: func
};

export default bpConnect(connect(mapStateToContent('home'))(OurValues));
