import React from 'react';
import PropTypes from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Accordian from 'components/Accordian';
import './JobBody.scss';

export class JobBody extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      moreInfo: false
    };
  }

  render () {
    const { moreInfo } = this.state;
    const { job } = this.props;

    return (
      <React.Fragment>
        { !moreInfo &&
          <button className="btn" onClick={ () => this.setState({ moreInfo: !moreInfo }) }>More info</button>
        }

        <Accordian open={ moreInfo }>
          <div className="jobpost__body typ--jp">
            <div dangerouslySetInnerHTML={ { __html: documentToHtmlString(job.body) } } />
            <div className="jobpost__body__other">
              <h4>Other</h4>
            Position is based in <b>{ job.locations.map(l => l.name).join(', ') }</b>
            </div>
          </div>
        </Accordian>
      </React.Fragment>
    );
  }
}

const { object } = PropTypes;
JobBody.propTypes = {
  job: object
};

export default JobBody;