import React from 'react';
import { connect } from 'react-redux';
import { string, func } from 'prop-types';
import { push } from 'connected-react-router';
import './Loader.scss';

export class Loader extends React.Component {
  constructor(props) {
    super(props);

    if (props.timeoutPath) {
      this.timeout = setTimeout(() => {
        props.dispatch(push(props.timeoutPath));
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render () {
    return (
      <div className="loader" />
    );
  }
}

Loader.propTypes = {
  timeoutPath: string,
  dispatch: func
};

export default connect()(Loader);
