import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Transition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { string, object } from 'prop-types';
// import ScrollToTop from 'react-router-scroll-top';
import AppContainer from 'containers/AppContainer';
import CookieConsent from "react-cookie-consent";
// import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import Home from './Home';
import HomeNew from './HomeNew';
import Team from './Team';
// import Insights from './Insights';
import Blog from './Blog';
import Article from './Article';
import Companies from './Companies';
// import Library from './Library';
import Careers from './Careers';
import Member from './Member';
// import WhyJapan from './WhyJapan';
// import WhatWeDo from './WhatWeDo';
// import OurValues from './OurValues';
// import Locations from './Locations';
import MobileNav from './MobileNav';
import Quote from './Quote';
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfUse from './TermsOfUse'
import Approach from './Approach'
import StrategicPartners from './StrategicPartners'
import Principles from './Principles'
import LocationsPage from './LocationsPage'
import Contact from './Contact'
import 'nodelist-foreach-polyfill';
import FourOhFour from './FourOhFour';

/**
  App routes.
  Path is path match.
  Component points to React component.
  Name is for link display.
**/
export const routes = [
  { path: '/home-old', component: Home, name: 'Home', hideInHeader: true, showInMobileNav: false },
  { path: '/', component: HomeNew, name: 'Home', hideInHeader: true, showInMobileNav: false },
  { path: '/us-japan-global-bridge', component: HomeNew, name: 'US-Japan Global Bridge', hideInHeader: true, showInMobileNav: false, contentfulId: 'home' },
  { path: '/what-we-do', component: HomeNew, name: 'What We Do', hideInHeader: true, showInMobileNav: false, contentfulId: 'home' },
  { path: '/our-values', component: HomeNew, name: 'Our Values', hideInHeader: true, showInMobileNav: false, contentfulId: 'home' },
  { path: '/team', component: Team, name: 'People', showInMobileNav: true },
  { path: '/companies', component: Companies, name: 'Companies', showInMobileNav: true },
  { path: '/companies/:quoteid', component: Quote, name: 'Quote', hideInHeader: true, showInMobileNav: false },
  // { path: '/library', component: Library, name: 'Library', hideInHeader: true, showInMobileNav: false },
  { path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy', hideInHeader: true, showInMobileNav: false },
  { path: '/terms-of-use', component: TermsOfUse, name: 'TermsOfUse', hideInHeader: true, showInMobileNav: false },
  { path: '/approach', component: Approach, name: 'Approach', hideInHeader: false, showInMobileNav: true },
  { path: '/wilsp', component: StrategicPartners, name: 'StrategicPartners', hideInHeader: true, showInMobileNav: false },
  { path: '/principles', component: Principles, name: 'Principles', hideInHeader: false, showInMobileNav: true },
  { path: '/locations', component: LocationsPage, name: 'Locations', hideInHeader: true, showInMobileNav: true },
  { path: '/team/:memberid', component: Member, name: 'Member', hideInHeader: true, showInMobileNav: false },
  { path: '/careers', component: Careers, name: 'Careers', hideInHeader: true, showInMobileNav: true, showInJP: true },
  { path: '/blog/:article', component: Article, name: 'Article', hideInHeader: true, showInMobileNav: false },
  { path: '/insights/:article', component: Article, name: 'Article', hideInHeader: true, showInMobileNav: false },
  { path: '/nav', component: MobileNav, name: 'Nav', hideInHeader: true, showInMobileNav: false },
  // { path: '/insights', component: Insights, name: 'Insights' },
  { path: '/blog', component: Blog, name: 'Blog', showInMobileNav: true },
  { path: '/contact', component: Contact, name: 'Contact', hideInHeader: true, showInMobileNav: false }
];

/** Filter routes by language **/
export const filterRoutes = (routes, language) => routes
  .filter(r => !r.show || r.show.includes(language));

/**
 * Defines the base routes of the application.
 */
const Routes = ({ language, router }) => {
  const { location } = router;
  return (
    <AppContainer>
      <TransitionGroup>
        <Transition
          key={ location.key }
          timeout={ (location.state && location.state.duration) ? location.state.duration : 400 }
        >
          {(status) => (
            <Switch location={ location }>
              {
                filterRoutes(routes, language)
                  .map((route, index) => (
                    <Route
                      render={ (props) => <route.component { ...props } transitionStatus={ status } /> }
                      path={ route.path }
                      exact
                      key={ index }
                    />
                  ))
              }
              <Route path="/people" render={ () => <Redirect to="/team" /> } />
              <Route path="/jp/people" render={ () => <Redirect to="/team" /> } />
              <Route path="/partners" render={ () => <Redirect to="/companies" /> } />
              <Route path="/jp/partners" render={ () => <Redirect to="/companies" /> } />
              <Route path="/investment" render={ () => <Redirect to="/companies" /> } />
              <Route path="/jp/investment" render={ () => <Redirect to="/companies" /> } />
              <Route path="/jp" render={ () => <Redirect to="/" /> } />
              <Route path="/jp/news-blog" render={ () => <Redirect to="/blog" /> } />
              <Route path="/jp/about" render={ () => <Redirect to="/" /> } />
              <Route path="/jp/careers" render={ () => <Redirect to="/careers" /> } />
              <Route path='/download' component={ () => { window.location.href = 'https://www.fundpanel.io/Login'; return null; } } />
              <Route exact path="/insights" render={ () => <Redirect to="/blog" /> } />
              {/* <Route path="/insights/:article" render={ (data) => <Redirect to={ `/blog/${data.match.params.article}` } transitionStatus="entering" /> } /> */}
              <Route path="/locationspage" render={ () => <Redirect to="/locations" /> } />
              <Route path="*" render={ () => <FourOhFour /> } />
            </Switch>
          )}
        </Transition>
      </TransitionGroup>
      { language==="en" &&
      <CookieConsent enableDeclineButton flipButtons
        style={ {  alignItems: "center" ,fontSize: "14px", lineHeight: "16px" } }
        buttonText="ACCEPT ALL COOKIES" declineButtonText="REJECT ALL COOKIES"
        buttonStyle={ { fontFamily: "sofia-pro", fontWeight: "600", color: "#FFF", fontSize: "16px", background: "#888", margin: "13px 0px 17px 15px", padding: "6px 20px 10px 20px" } }
        declineButtonStyle={ { fontFamily: "sofia-pro", fontWeight: "600", color: "#FFF", fontSize: "16px", background: "#888", margin: "13px 20px 17px 15px", padding: "6px 20px 10px 20px" } }
      >
        We use necessary cookies to make our site work. We also like to employ analytics cookies that help us make improvements by measuring how you use the site. These will be set only if you accept. For more detailed information about the cookies we use, see our <Link style={ { color: "#ccc" } } to="/privacy-policy">Privacy Policy page</Link>.
      </CookieConsent> }
      { language==="jp" &&
      <CookieConsent enableDeclineButton flipButtons 
        style={ {  alignItems: "center" ,fontSize: "14px", lineHeight: "16px" } }
        buttonText="すべてのCookieを受け入れる" declineButtonText="すべてのクッキーを拒否する"
        buttonStyle={ { color: "#FFF", fontSize: "14px", fontWeight: "600", background: "#888", margin: "13px 0px 17px 15px", padding: "8px 20px 8px 20px" } }
        declineButtonStyle={ { color: "#FFF", fontSize: "14px", fontWeight: "600", background: "#888", margin: "13px 20px 17px 15px", padding: "8px 20px 8px 20px" } }
      >
        当社は、当社のサイトを機能させるために必要なクッキーを使用しています。また、お客様がサイトをどのように使用されているかを測定し、改善に役立てるために分析クッキーを利用することもあります。これらは、お客様が同意した場合にのみ有効化されます。当社が使用するクッキーの詳細については、<Link style={ { color: "#ccc" } } to="/privacy-policy">プライバシーポリシーのページ</Link>をご覧ください。
      </CookieConsent> }
    </AppContainer>
  );
};

const mapStateToProps = state => ({
  language: state.language,
  router: state.router
});

Routes.propTypes = {
  /* The language in which to display the site (from the Redux store). */
  language: string,

  router: object
};

export default connect(mapStateToProps)(Routes);
