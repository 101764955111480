import React from 'react';
import PropTypes from 'prop-types';
import flkty from 'flickity';
import imagesLoaded from 'imagesloaded';
import './Carousel.scss';

export class Carousel extends React.Component {
  constructor (props) {
    super(props);
    this.state = { isInitialized: false };
  }

  componentDidMount () {
    const { delayAutoplay } = this.props;

    if (delayAutoplay) {
      setTimeout(() => {
        this.setup(this.props);
      }, delayAutoplay);
    } else {
      this.setup(this.props);
    }

    if (navigator.userAgent.match(/iPhone|iPad|iPod|Android.+Mobile/)) {
      this.visibilityHandler = this.playMobile.bind(this);
      document.addEventListener("visibilitychange", this.visibilityHandler, true);
    } else {
      this.blurHandler = this.pause.bind(this);
      window.addEventListener("blur", this.blurHandler, true);
      this.focusHandler = this.play.bind(this);
      window.addEventListener("focus", this.focusHandler, true);
    }

  }

  setup (props) {
    this.flickity = new flkty(this.carousel, {
      wrapAround: this.props.wrapAround,
      cellSelector: '.carousel__slide',
      prevNextButtons: false,
      pageDots: this.props.showDots,
      draggable: this.props.draggable,
      autoPlay: this.props.autoPlay
    });

    imagesLoaded(this.carousel, () => {
      this.flickity.resize();
      this.flickity.on('change', nextIndex => props.onChange instanceof Function && props.onChange(nextIndex));
    });

    this.setState({ isInitialized: true });
  }

  play(){
    // this.flickity.unpausePlayer();
    // this.setup();
    if (this.state.isInitialized){
      // console.log(this.flickity.options.autoPlay);
      this.flickity.options.autoPlay = Math.random()*2000+3000;
      this.flickity.playPlayer();
    }
  }

  pause(){
    if (this.state.isInitialized){
      // this.flickity.stopPlayer();
    }
  }

  playMobile(){
    if (this.state.isInitialized){
      if (document.visibilityState === "visible") {
        this.flickity.options.autoPlay = Math.random()*2000+3000;
        this.flickity.playPlayer();
      }

      if (document.visibilityState === "hidden") {
        // console.log("Content is now background on mobile");
      }
    }
  }

  render () {
    const { slides, showDots, showArrows, className } = this.props;

    return (
      <div
        className={ `carousel ${showDots ? 'show-dots' : ''} ${showArrows ? 'show-arrows' : ''} ${className}` }
        ref={ el => { this.carousel = el; } }
      >
        { slides.map((slide, index) => (
          <div className="carousel__slide" key={ index }>
            { slide }
          </div>
        )) }

        { showArrows &&
          <div className="carousel__arrows">
            <span className="icon-long-arrow-left" onClick={ () => this.flickity.previous() } />
            <span className="icon-long-arrow-right" onClick={ () => this.flickity.next() } />
          </div>
        }
      </div>
    );
  }
}

const { array, bool, string, number, oneOfType, func } = PropTypes;
Carousel.propTypes = {
  slides: array.isRequired,
  showDots: bool,
  showArrows: bool,
  className: string,
  wrapAround: bool,
  draggable: bool,
  autoPlay: oneOfType([bool, number]),
  // eslint-disable-next-line
  onChange: func,
  delayAutoplay: oneOfType([bool, number])
};

Carousel.defaultProps = {
  className: '',
  wrapAround: true,
  showArrows: true,
  draggable: true,
  autoPlay: false,
  delayAutoplay: false
};

export default Carousel;
