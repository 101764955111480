import React from "react";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { mapStateToContent } from "utils/cms";
import Loader from "components/Loader";
import bpConnect from "components/bpConnect";
import { TimelineMax } from "gsap";
import Markdown from "components/Markdown";
import FancyLink from "components/FancyLink";
import Lottie from "lottie-react-web";
// import Waypoint from "react-waypoint";

import "./Approach.scss";
import bm_combine from "./lottie/bm_combine.json";
import bm_duplicate from "./lottie/bm_duplicate.json";
import bm_expand from "./lottie/bm_expand.json";

export class Approach extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) || (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering")) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === "exiting" && prevProps.transitionStatus !== "exiting") {
      this.animateOut();
    }
  }

  animateIn() {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.from(this.container, 0.4, { opacity: 0 }, "anim+=0.2");

    tl.staggerFrom(
      [this.title, ...entries],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.4"
    );

    return tl;
  }

  animateOut() {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.staggerTo(
      [...entries, this.title],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim"
    );

    tl.to(this.container, 0.4, { opacity: 0 }, "anim+=0.4");

    return tl;
  }

  render() {
    const { content, setClass, language, bpIsGreaterThan, bpIsLessThan } = this.props;
    const approach = content.orderedContent;
    if (!approach) return <Loader />;

    const animations = [bm_combine, bm_expand, bm_duplicate];

    return (
      <div
        className="scroller"
        ref={ el => {
          this.container = el;
        } }
      >
        <div className="row pt8 pb2">
          <div className={ `${setClass({ default: "cf mb20", mobileLg: "cf approach__adjust__marginBottom" })}` }>
            <div
              className={ `principles__slider ${setClass({
                default: "col--4 layout--relative",
                mobileLg: "col--12"
              })}` }
            >
              <h2
                ref={ el => {
                  this.title = el;
                } }
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }
              >
                {approach[0].title}
              </h2>
              {bpIsGreaterThan("mobileLg") && (
                <div className="approach__japan__base">
                  <div
                    className="approach__japan__circle"
                    ref={ el => {
                      this.circle = el;
                    } }
                  />
                  <img
                    className="approach__japan__image"
                    ref={ el => {
                      this.map = el;
                    } }
                    src={ require("assets/img/home/whyjapan.png") }
                    alt="Country of Japan"
                  />
                </div>
              )}
            </div>
            <div className={ setClass({ default: "col--8", mobileLg: "col--12" }) }>
              <div className="principles__slider">
                {/* <h4 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>abbbbbbb</h4> */}
                <Markdown source={ approach[0].text } />
              </div>
              {bpIsLessThan("mobileLg") && (
                <div className="approach__japan__base">
                  <div
                    className="approach__japan__circle"
                    ref={ el => {
                      this.circle = el;
                    } }
                  />
                  <img
                    className="approach__japan__image"
                    ref={ el => {
                      this.map = el;
                    } }
                    src={ require("assets/img/home/whyjapan.png") }
                    alt="Country of Japan"
                  />
                </div>
              )}
            </div>
            {/* <div className="gridspacer col--1" /> */}
          </div>

          <div className="cf">
            <div className={ `principles__slider ${setClass({ default: "col--4", mobileLg: "col--12" })}` }>
              <h2
                ref={ el => {
                  this.title = el;
                } }
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }
              >
                {approach[1].title}
              </h2>
            </div>
            <div className="col--8" />
          </div>

          {/* -------------------- Approach 1 ---------- */}
          <div className="cf mb8 approach__section__container">
            <div className={ `principles__slider ${setClass({ default: "col--4", mobileLg: "col--12" })}` }>
              <div className="approach__lottie__box">
                <div className="lottie__circle" />
                <Lottie options={ { animationData: animations[0] } } />
              </div>
            </div>
            <div className={ `principles__slider ${setClass({ default: "col--8", mobileLg: "col--12" })}` }>
              <h3 className="mb2">{approach[1].subsections[0].title}</h3>
              <div className="approach__section__text  mb2"><Markdown source={ approach[1].subsections[0].text } /></div>
              { approach[1].subsections[0].bullets &&
                <div className="mb2">
                  <ul className="approach__bullet__list typ--light">
                    {approach[1].subsections[0].bullets.map((bullet, bulletIndex) => (
                      bullet.length > 1 &&
                      <li className="" key={ bulletIndex }>
                        {bullet}
                      </li>
                    ))}
                  </ul>
                </div>
              }
              <div className={ `${setClass({ mobileLg: "btn__container" })}` }>
                <FancyLink to={ approach[1].subsections[0].ctaDestination } className="btn layout--right">
                  {approach[1].subsections[0].ctaDestinationText}
                </FancyLink>
              </div>
            </div>
          </div>

          {/* -------------------- Approach 2 ---------- */}
          <div className="cf mb8 approach__section__container">
            <div className={ `principles__slider ${setClass({ default: "col--4", mobileLg: "col--12" })}` }>
              <div className="approach__lottie__box">
                <div className="lottie__circle" />
                <Lottie options={ { animationData: animations[1] } } />
              </div>
            </div>
            <div className={ `principles__slider ${setClass({ default: "col--8", mobileLg: "col--12" })}` }>
              <h3 className="mb2">{approach[1].subsections[1].title}</h3>
              <div className="approach__section__text  mb2"><Markdown source={ approach[1].subsections[1].text } /></div>
              { approach[1].subsections[1].bullets &&
                <div className="mb2">
                  <ul className="approach__bullet__list typ--light">
                    {approach[1].subsections[1].bullets.map((bullet, bulletIndex) => (
                      bullet.length > 1 &&
                      <li className="" key={ bulletIndex }>
                        {bullet}
                      </li>
                    ))}
                  </ul>
                </div>
              }
              <ul className={ `typ--right layout--right ${setClass({ mobileLg: "btn__container" })}` }>
                <li>
                  <FancyLink to={ approach[1].subsections[1].ctaDestination } className="btn">
                    {approach[1].subsections[1].ctaDestinationText}
                  </FancyLink>
                </li>
                <li>
                  <FancyLink to={ approach[1].subsections[1].ctaDestinationSecond } className="btn">
                    {approach[1].subsections[1].ctaDestinationTextSecond}
                  </FancyLink>
                </li>
              </ul>
            </div>
          </div>

          {/* -------------------- Approach 3 ---------- */}
          <div className="cf approach__section__container">
            <div className={ `principles__slider ${setClass({ default: "col--4", mobileLg: "col--12" })}` }>
              <div className="approach__lottie__box">
                <div className="abcd">
                  <div className="lottie__circle" />
                  <div className="approach__lottie__adjust">
                    <Lottie options={ { animationData: animations[2] } } />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `principles__slider ${setClass({ default: "col--8", mobileLg: "col--12 mb6" })}` }>
              <h3 className="mb2">{approach[1].subsections[2].title}</h3>
              <div className="approach__section__text  mb2"><Markdown source={ approach[1].subsections[2].text } /></div>
              { approach[1].subsections[2].bullets &&
                <div className="mb2">
                  <ul className="approach__bullet__list typ--light">
                    {approach[1].subsections[2].bullets.map((bullet, bulletIndex) => (
                      bullet.length > 1 &&
                      <li className="" key={ bulletIndex }>
                        {bullet}
                      </li>
                    ))}
                  </ul>
                </div>
              }
              <div className={ `${setClass({ mobileLg: "btn__container" })}` }>
                <FancyLink to={ approach[1].subsections[2].ctaDestination } className="btn layout--right">
                  {approach[1].subsections[2].ctaDestinationText}
                </FancyLink>
              </div>
            </div>
          </div>
        </div>

        <Footer nextPage={ { label: "Principles", pathname: "/principles" } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Approach.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string,
  language: string,
  bpIsLessThan: func,
  bpIsGreaterThan: func
};

export default bpConnect(connect(mapStateToContent("approach"))(Approach));
