import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mapStateToContent } from 'utils/cms';
import Footer from 'components/Footer';
import ClientGridColor from 'components/ClientGridColor';
import ClientGrid from 'components/ClientGrid';
import groupBy from 'json-groupby';
import CompaniesCarousel from './CompaniesCarousel';
import bpConnect from 'components/bpConnect';
import Markdown from 'components/Markdown';
import { TweenMax, TimelineMax } from 'gsap';
import { isEmpty } from 'lodash';
import Dropdown from "components/Dropdown";
import './Companies.scss';

class Companies extends React.Component {
  constructor (props) {
    super(props);

    this.state = { selectedIndustry: 0 };

    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
    this.anchorLink = this.anchorLink.bind(this);
    this.anchorLinkNav = this.anchorLinkNav.bind(this);
    this.selectedIndustryChangeHandler = this.selectedIndustryChangeHandler.bind(this);
  }

  componentDidUpdate (prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering')) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const tagline = this.container.querySelector('.companiescarousel__tagline');
    const carousel = this.container.querySelector('.carousel');

    tl.from(this.container, 0.4, { opacity: 0 }, 'anim+=0.2');

    tl.staggerFrom([tagline, carousel, this.content], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();
    const tagline = this.container.querySelector('.companiescarousel__tagline');
    const carousel = this.container.querySelector('.carousel');

    tl.staggerTo([tagline, carousel, this.content], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim');

    tl.to(this.container, 1, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  anchorLink(linkTo) {
    const id = "#industries"+linkTo.sectionIndex.toString();
    const currentScrollPosition = document.querySelector(".scroller").scrollTop;
    const targetPosition = currentScrollPosition + document.querySelector(id).getBoundingClientRect().top + window.pageYOffset - 100;
    TweenMax.to(document.querySelector(".scroller"), 0.4, { scrollTop: targetPosition });
  }

  anchorLinkNav(id) {
    const currentScrollPosition = document.querySelector(".scroller").scrollTop;
    const targetPosition = currentScrollPosition + document.querySelector(id).getBoundingClientRect().top + window.pageYOffset - 100;
    TweenMax.to(document.querySelector(".scroller"), 0.4, { scrollTop: targetPosition });
  }

  selectedIndustryChangeHandler(industryIndex) {
    if (industryIndex > 0){
      const id = "#industries"+(industryIndex-1).toString();
      const currentScrollPosition = document.querySelector(".scroller").scrollTop;
      const targetPosition = currentScrollPosition + document.querySelector(id).getBoundingClientRect().top + window.pageYOffset - 100;
      TweenMax.to(document.querySelector(".scroller"), 0.4, { scrollTop: targetPosition });
    }
    this.setState({ selectedIndustry: industryIndex });
  }

  render () {
    const { content, setClass, history, language, bpIsLessThan } = this.props;

    const companiesData = content.orderedContent;
    if (!companiesData) return false;
    const getSection = (id) => companiesData.find(d => d.id === id);

    const currentInvestmentData = getSection('companies-current-investments');
    const previousInvestmentData = getSection('companies-notable-previous-investments');

    const corporatePartnersData = getSection('corporate-partners');
    const groupedPartners = groupBy(corporatePartnersData.subsections, ['industries.name']);
    let industriesForDropdownLinks = Object.keys(groupedPartners);
    industriesForDropdownLinks.unshift("Select an industry");

    const ventureFunds = getSection('venture-funds');

    return (
      <div className="companies scroller" ref={ el => { this.container = el; } }>
        <CompaniesCarousel history={ history } data={ companiesData.find(data => data.id === 'company-carousel') } />

        {bpIsLessThan("mobileLg") && language === "en" &&
          <div className='row companies__anchor__navigation'>
            <ul>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section0") }>↓ {currentInvestmentData.title}</button></li>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section-prev") }>↓ {previousInvestmentData.title}</button></li>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section1") }>↓ {ventureFunds.title}</button></li>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section2") }>↓ {corporatePartnersData.title}</button></li>
            </ul>
          </div>
        }
        {bpIsLessThan("mobileLg") && language === "jp" &&
          <div className='row companies__anchor__navigation'>
            <ul>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section0") }>↓ {currentInvestmentData.title}</button></li>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section1") }>↓ {ventureFunds.title}</button></li>
              <li><button className="companies__anchorLink" onClick={ () => this.anchorLinkNav("#nav-section2") }>↓ {corporatePartnersData.title}</button></li>
            </ul>
          </div>
        }

        <div className={ `bg--offwhite ${setClass({ default: 'pt10', mobileLg: 'pt4' })}` }>
          <div id="nav-section0" className="row" ref={ el => { this.content = el; } }>
            <ClientGridColor
              title={ currentInvestmentData.title }
              clients={ currentInvestmentData.subsections }
              sort
            />
          </div>

          { language === "en" &&
            <div id="nav-section-prev" className="row" ref={ el => { this.content = el; } }>
              <ClientGrid
                title={ previousInvestmentData.title }
                clients={ previousInvestmentData.subsections }
                sort={ false }
              />
            </div>
          }

          {
            ventureFunds &&
              <div id="nav-section1" className="row venture-funds cf mb10">
                <div className={ setClass({ default: 'col--3', tabletLg: 'mb5', mobileLg: 'mb5' }) }>
                  <h2 className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h3' })}` }>{ ventureFunds.title }</h2>
                </div>

                <div className={ setClass({ default: 'col--9', tabletLg: ' ' }) }>
                  <div className="venture-funds--text">
                    <Markdown source={ ventureFunds.text } />
                  </div>
                </div>
              </div>
          }

          <div id="nav-section2" className="row" ref={ el => { this.content = el; } }>
            <div className="clientgrid cf mb10">
              <div className={ setClass({ default: 'col--3', tabletLg: 'mb5' }) }>
                <h2 className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h3' })}` }>{ corporatePartnersData.title }</h2>
                {bpIsLessThan("tabletLg") ?
                  <div
                    ref={ el => {
                      this.tagsElem = el;
                    } }
                    style={ { maxWidth: "250px" } }
                  >
                    <Dropdown items={ industriesForDropdownLinks } selectedIndex={ this.state.selectedIndustry } onChange={ index => this.selectedIndustryChangeHandler(index) } />
                  </div>
                  :
                  <ul>
                    { Object.keys(groupedPartners).map((section, sectionIndex) => (
                      // <li><a href={ `#${section}` } key={ sectionIndex }>{section}</a></li>
                      <li><button className="companies__anchorLink" ref={ el => { this.arrow = el; } } onClick={ () => this.anchorLink({ sectionIndex }) }>{section}</button></li>
                    )) }
                  </ul>
                }
              </div>

              <div className={ setClass({ default: 'col--9', tabletLg: ' ' }) }>
                <div className="partnergrid">
                  { Object.keys(groupedPartners).map((section, sectionIndex) => (
                    <div className="partnergrid__section" key={ sectionIndex }>
                      <div className="partnergrid__section__header mb2">
                        { groupedPartners[section][0].industries[0].icon? 
                          <img className='partnergrid__industries__icon' src={ groupedPartners[section][0].industries[0].icon.file.url } alt="" />
                          :
                          <img src="https://placehold.co/48x48" alt="" />
                        }
                        <h4 className="typ--bold" id={ `industries${ sectionIndex }` }>{ section }</h4>
                      </div>
                      <div className="partnergrid__container">
                        { groupedPartners[section].map((item, itemIndex) => (
                          <div className={ `partnergrid__item mb2 ${item.link ? 'has-link' : ''}` } key={ itemIndex }>
                            <a href={ item.link } target="_blank" rel="noopener noreferrer">
                              { item.logoImage ?
                                <img src={ item.logoImage.file.url } alt="" />
                                :
                                <img src="https://placehold.co/1920x800" alt="" />
                              }
                              <p className="typ--bold mb1">{ item.title }</p>
                              <p className="typ--light">{ item.description }</p>
                            </a>
                          </div>
                        )) }
                      </div>

                    </div>
                  )) }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cf pb4 typ--center layout--center">
              <p className="companies__disclaimer">
                These logos are trademarks belonging to the respective companies shown above. None of these entities has reviewed or approved any investments considered or made by WiL and none of these entities is endorsing WiL or any affiliate or fund or client thereof.
              </p>
            </div>
          </div>

          <Footer nextPage={ { label: 'Approach', pathname: '/approach' } } language={ language } />
        </div>
      </div>
    );
  }
}


const { object, func, string } = PropTypes;
Companies.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string,
  history: object,
  language: string,
  bpIsLessThan: func
};

export default bpConnect(connect(mapStateToContent('companies-new'))(Companies));
