export const setLanguage = language => ({
  type: 'SET_LANGUAGE', language
});

export const setPages = (pages, language) => ({
  type: 'SET_PAGES', pages, language
});

/**
 * Changes the active breakpoint
 *
 * @param {string} breakpointName          String defining the active breakpoint
 * @param {number} breakpointSize          Number defining the active breakpoint
 * @return {Object} Action object
 */
export const setActiveBreakpoint = (breakpointName, breakpointSize) => ({
  type: 'SET_ACTIVE_BREAKPOINT',
  breakpointName,
  breakpointSize
});

export const setFirstLoad = (firstLoad) => ({
  type: 'SET_FIRST_LOAD',
  firstLoad
});

export const setTeamSortIndex = (sortIndex) => ({
  type: 'SET_TEAM_SORT_INDEX',
  sortIndex
});
