import React from 'react';
import PropTypes from 'prop-types';
import { routes, filterRoutes } from 'routes/index';
import { Link } from 'react-router-dom';
import LanguageFooter from 'components/LanguageFooter';
import { TimelineMax } from 'gsap';
import './MobileNav.scss';

export class MobileNav extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.goBack = this.goBack.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  goBack () {
    this.props.history.goBack();
  }

  animateIn () {
    const tl = new TimelineMax();
    const links = this.nav.querySelectorAll('li');

    tl.from(this.nav, 0.2, { opacity: 0 }, 'anim');
    tl.staggerFrom([this.header, ...links, this.footer], 0.3, { y: 40, opacity: 0 }, 0.04, 'anim+=0.26');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();
    const links = this.nav.querySelectorAll('li');

    tl.staggerTo([this.header, ...links, this.footer], 0.3, { y: 40, opacity: 0 }, 0.04, 'anim');
    tl.to(this.nav, 0.2, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  render () {
    const { language, location } = this.props;

    return (
      <nav className="mobilenav" ref={ el => { this.nav = el; } }>
        <header className="mobilenav__header row"  ref={ el => { this.header = el; } }>
          <div>
            <Link to="/">
              <img
                src={ require('assets/img/wil_logo-white.svg') }
                alt="World Innovation Labs"
              />
            </Link>
            <span className="icon-close" onClick={ this.goBack } />
          </div>
        </header>
        <div className="mobilenav__top">
          <ul>
            <li className={ `${location.state.currentPage === '/' ? 'is-active' : ''}` }>
              <Link to="/">Home</Link>
            </li>
            { filterRoutes(routes, language).filter(route => route.showInMobileNav)
              .map((route, index) => (
                <li className={ `${route.path === location.state.currentPage ? 'is-active' : ''}` } key={ index }>
                  <Link
                    to={ route.path }
                  >{ route.name }</Link>
                </li>
              )) }
          </ul>
          <div className="mobilenav__footer" ref={ el => { this.footer = el; } }>
            <LanguageFooter isCentered />
          </div>
        </div>
      </nav>
    );
  }
}

const { string, object } = PropTypes;
MobileNav.propTypes = {
  language: string,
  history: object,
  transitionStatus: string,
  location: object
};

export default MobileNav;