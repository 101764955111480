import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import { isEmpty } from 'lodash';
import bpConnect from 'components/bpConnect';
import { TimelineMax } from 'gsap';
import './Contact.scss'

export class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
    this.state = { 
      contactCategory: "", 
      nameKanji: "", 
      nameKatakana: "", 
      companyName: "", 
      mailAddress: "", 
      telNumber: "", 
      contactMessage: "",
      formSent : false
    };
  }

  componentDidUpdate (prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering')) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll('.contact__form');

    tl.from(this.container, 0.4, { opacity: 0 }, 'anim+=0.2');

    tl.staggerFrom([this.title, ...entries], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll('.contact__form');

    tl.staggerTo([...entries, this.title], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim');

    tl.to(this.container, 0.4, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  formAnimateOut () {
    const tl = new TimelineMax();

    tl.to('.contact__form', 0.4, {
      y: 40, opacity: 0
    }, 'anim' )
      .to('.contact__form', 0.0, {
        display: "none", onComplete:this.setFormState
      }, 'anim+=0.4' )
    
    return tl;
  }

  setFormState = () => {
    this.setState({ formSent : true });
    const tl = new TimelineMax();

    tl.to('.thanksMessage', 0.4, {
      opacity: 1
    }, 'anim' )
  }

  /* Posting the form submission */
      
  handleSubmit = e => {
    fetch("https://mc9q4xcld1.execute-api.ap-northeast-1.amazonaws.com/latest/sendForm", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ ...this.state })
    })
      .then(() => {
        this.formAnimateOut();
      }
      )
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files.item(0) });
  }

  render () {
    const { setClass } = this.props;
    const { contactCategory, nameKanji, nameKatakana, companyName, mailAddress, telNumber, contactMessage, formSent } = this.state;

    function RenderingThanks(){
      return(
        <div className="thanksMessage">
          <p>お問い合わせありがとうございました。<br />内容を確認のうえ、担当者から折り返しご連絡させていただきます。</p>
          <p>また、ご記入いただきましたメールアドレスへ、自動返信の確認メールを送付しています。<br />自動返信メールが届かない場合、入力いただいたメールアドレスに誤りがあった可能性がございます。<br />その場合、メールアドレスをご確認の上、もう一度フォームよりお問合せくださいますようお願いいたします。</p>
        </div>
      )
    }

    return (
      <div className="scroller" ref={ el => { this.container = el; } }>
        <div className="row contact pagecontent">
          <div className="cf">
            <div className={ `contact__header ${setClass({ default: 'col--3 layout--relative', tabletLg: ' ' })}` }>
              
              <h1
                ref={ el => { this.title = el; } } 
                className={ `sectiontitle typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }
              >Contact</h1>  
            </div>

            <div className={ setClass({ default: 'col--9', tabletLg: ' ' }) }>
              { formSent && <RenderingThanks /> }
              <form className="contact__form" onSubmit={ this.handleSubmit } id="wilContactForm">
                <p>
                  <label className="contact__label" id="contactCategory">問い合わせ項目<span>*必須</span></label>
                  <select className="contact__input" htmlFor="contactCategory" type="text" name="contactCategory" value={ contactCategory } placeholder="お名前をご記入ください" onChange={ this.handleChange } required >
                    <option value="" hidden>選択してください</option>
                    <option value="投資のご依頼">投資のご依頼</option>
                    <option value="取材のご依頼">取材のご依頼</option>
                    <option value="採用に関して">採用に関して</option>
                    <option value="その他のお問い合わせ">その他のお問い合わせ</option>
                  </select>
                </p>
                <p>
                  <label className="contact__label" id="nameKanji">お名前（漢字）<span>*必須</span></label>
                  <input className="contact__input" htmlFor="nameKanji" type="text" name="nameKanji" value={ nameKanji } placeholder="お名前をご記入ください" onChange={ this.handleChange } required />
                </p>
                <p>
                  <label className="contact__label" id="nameKatakana">お名前（フリガナ）</label>
                  <input className="contact__input" htmlFor="nameKatakana" type="text" name="nameKatakana" value={ nameKatakana } placeholder="フリガナをご記入ください" onChange={ this.handleChange } />
                </p>
                <p>
                  <label className="contact__label" id="companyName">会社名</label>
                  <input className="contact__input" htmlFor="companyName" type="text" name="companyName" value={ companyName } placeholder="会社名をご記入ください" onChange={ this.handleChange } />
                </p>
                <p>
                  <label className="contact__label" id="mailAddress">メールアドレス<span>*必須</span></label>
                  <input className="contact__input" htmlFor="mailAddress" type="email" name="mailAddress" value={ mailAddress } placeholder="sample@wil.sample.com" onChange={ this.handleChange } required />
                </p>
                <p>
                  <label className="contact__label" id="telNumber">電話番号</label>
                  <input className="contact__input" htmlFor="telNumber" type="tel" name="telNumber" value={ telNumber } placeholder="0123456789" onChange={ this.handleChange } />
                </p>
                <p>
                  <label className="contact__label" id="message">お問い合わせ内容<br /><span>*必須</span></label>
                  <textarea className="contact__textarea" htmlFor="message" name="contactMessage" value={ contactMessage } placeholder="お問い合わせ内容をご記入ください。添付資料などがある場合は、別途ファイル転送サービスなどを使用し、ダウンロードリンクを貼付してください。" onChange={ this.handleChange } required />
                </p>
                <p className="no-bg">
                  <button type="submit">送信</button>
                </p>
                <div className="contact__caption typ--b2 mb2">
                  ※ 2022年6月、問い合わせフォームに障害が発生し、メールが正常に受信できない状態となっておりましたが、現在は復旧しております。お問い合わせを頂き、もし返信が届いてない場合、大変お手数をおかけいたしますが、再度こちらのフォームよりご連絡くださいますようお願い申し上げます。ご不便をおかけし、誠に申しわけございません。
                </div>
                <div className="contact__caption">
                  現在募集している採用情報に関しては<a href="/careers">こちら</a>をご覧ください。
                </div>
              </form>
            </div>
          </div>
        </div>

        <Footer nextPage={ { label: 'Home', pathname: '/' } } language="jp" />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Contact.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default bpConnect(Contact);