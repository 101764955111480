const getUnits = path => path && path.split('/').filter(u => u);

export const findMatchingRoute = (
  arrayOfRoutes,
  currentPathName,
  getRoutePath = ((r) => r.path)
) => {
  const currentPathUnits = getUnits(currentPathName);
  return arrayOfRoutes.find(route => {
    const routePath = getRoutePath(route);
    const routeUnits = getUnits(routePath);

    return routeUnits.length === currentPathUnits.length &&
      routeUnits.every((unit, index) => currentPathUnits[index] === unit || unit[0] === ':')
  });
};

export const getParamValues = (currentPath, routePath) => {
  const routeUnits = getUnits(routePath);
  const currentPathUnits = getUnits(currentPath);

  return routeUnits && routeUnits.reduce((valueObject, unit, index) => {
    if (unit[0] === ':') {
      return { ...valueObject, [unit.slice(1)]: currentPathUnits[index] };
    } else {
      return valueObject
    }
  }, {})
}
