import React from 'react';
import { TimelineMax, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FancyLink from 'components/FancyLink';
import { mapStateToContent } from 'utils/cms';
import Loader from 'components/Loader';
import bpConnect from 'components/bpConnect';
import Markdown from 'components/Markdown'
import { boundsToJSON } from 'utils/helpers';

import './WhyJapan.scss';

export class WhyJapan extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      lastScrollTime: Date.now()
    };
    this.scrollHandler = this.scrollHandler.bind(this);
    this.animateOut = this.animateOut.bind(this);
    this.animateNext = this.animateNext.bind(this);
    this.animatePrev = this.animatePrev.bind(this);
    this.onNextComplete = this.onNextComplete.bind(this);
    this.onPrevComplete = this.onPrevComplete.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);
  }

  resetCirclePosition () {
    if (this.circle) this.circle.style.cssText = '';
    this.setState({ animating: false });
  }

  scrollHandler (event) {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;
    if ( Date.now() > this.state.lastScrollTime + 50) {
      if (event.deltaY > 0) this.animateNext();
      if (event.deltaY < 0) this.animatePrev();
    }

    this.setState({ lastScrollTime: Date.now() });
  }

  componentDidMount () {
    setTimeout(() => { window.addEventListener('wheel' , this.scrollHandler); }, 1000);
  }

  componentWillUnmount () {
    window.removeEventListener('wheel' , this.scrollHandler);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;
    if (!bounds) return false;

    this.setState({ animating: true });

    tl.from(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.from(this.circle, 0.4, {
      height: bounds.height,
      width: bounds.width,
      ease: Power3.easeIn
    }, 'anim+=0.2');

    tl.from(this.circle, 0.4, {
      left: bounds.left - 110,
      top: bounds.top + (bounds.height / 2),
      ease: Power3.easeOut
    }, 'anim+=0.6');

    tl.from(this.map, 1, {
      opacity: 0
    }, 'anim+=1');

    tl.staggerFrom([this.title, this.copy, this.arrow], 0.6, {
      y: 20,
      opacity: 0
    }, 0.25, 'anim+=0.8');

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateOut () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    tl.to(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.staggerTo([this.title, this.copy, this.arrow], 0.6, {
      y: 20,
      opacity: 0
    }, 0.25, 'anim+=0.2');

    tl.set(this.circle, { opacity: 0 }, 'anim+=0.2');

    tl.to(this.map, 0.6, {
      opacity: 0
    }, 'anim+=0.2');

    return tl;
  }

  animateNext () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.to([this.title, this.copy, this.map, this.arrow], 0.8, {
      y: -20,
      opacity: 0
    }, 'anim+=0.1');

    tl.set(this.circle, {
      opacity: 0,
      onComplete: this.onNextComplete
    }, 'anim+=0.4');

    return tl;
  }

  animatePrev () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.set(this.circle, {
      opacity: 0,
      onComplete: this.onPrevComplete
    }, 'anim+=0.3');

    tl.to(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.to([this.title, this.copy, this.map, this.arrow], 0.8, {
      y: 20,
      opacity: 0
    }, 'anim+=0.1');

    return tl;
  }

  onNextComplete () {
    this.props.history.push({
      pathname: '/what-we-do',
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });

    this.setState({ animting: false });
  }

  onPrevComplete () {
    this.props.history.push({
      pathname: '/',
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });

    this.setState({ animting: false });
  }

  render () {
    const { setClass, bpIsGreaterThan } = this.props;
    if (!this.props.content.orderedContent) return <Loader />;
    const content = this.props.content.orderedContent.find(c => c.id === 'home-why-japan');

    return (
      <div className="whyjapan" ref={ el => { this.container = el; } }>
        <div className="row">
          <div className="whyjapan__circle" ref={ el => { this.circle = el; } } />
          <img
            className="whyjapan__country"
            ref={ el => { this.map = el; } }
            src={ require('assets/img/home/whyjapan.png') } alt="Country of Japan"
          />

          <div className={ setClass({ default: 'col--4', tabletLg: ' ' }) } ref={ el => { this.title = el; } }>
            <h1 className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h2' })}` }>{ content.title }</h1>
          </div>

          <div
            className={ `typ--jp ${setClass({ default: 'col--7', tabletLg: ' ' })}` }
            ref={ el => { this.copy = el; } }
          >
            <div className={ `typ--jp mb3 ${setClass({ default: 'typ--h5', mobileLg: 'typ--b1' })}` }>
              <Markdown source={ content.text } />
            </div>
            <FancyLink to={ content.ctaDestination } className="btn layout--right">Learn more</FancyLink>
          </div>

          <div className="gridspacer col--1" />
        </div>

        { bpIsGreaterThan('tabletLg') &&
          <span
            ref={ el => { this.arrow = el; } }
            className="scrollindicator icon-long-arrow-down"
            onClick={ this.animateNext }
          />
        }
      </div>
    );
  }
}

const { object, string, func } = PropTypes;
WhyJapan.propTypes = {
  history: object,
  transitionStatus: string,
  location: object,
  content: object,
  bpIsLessThan: func,
  setClass: func,
  bpIsGreaterThan: func
};

export default bpConnect(connect(mapStateToContent('home'))(WhyJapan));
