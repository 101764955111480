import React from 'react';
import { connect } from 'react-redux';
import { setClass, bpIsGreaterThan, bpIsLessThan, breakpoints } from 'utils/responsiveHelpers';
import { object } from 'prop-types';

function bpConnect(WrappedComponent) {
  class Component extends React.Component {
    render () {
      const { breakpoint } = this.props;

      return <WrappedComponent
        { ...this.props }
        setClass={ (obj) => setClass(obj, breakpoint) }
        bpIsGreaterThan={ (comparison) => bpIsGreaterThan(comparison, breakpoint) }
        bpIsLessThan={ (comparison) => bpIsLessThan(comparison, breakpoint) }
        bps={ breakpoints }
      />;
    }
  }

  const mapStateToProps = state => ({
    breakpoint: state.breakpoint
  });

  Component.propTypes = {
    breakpoint: object
  };

  return connect(mapStateToProps)(Component);
}

export default bpConnect;
