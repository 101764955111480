import React from "react";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { mapStateToContent } from "utils/cms";
import Loader from "components/Loader";
import bpConnect from "components/bpConnect";
import { TimelineMax } from "gsap";
import Markdown from "components/Markdown";
//import FancyLink from "components/FancyLink";
//import Lottie from "lottie-react-web";
// import Waypoint from "react-waypoint";

import "./StrategicPartners.scss";
// import bm_combine from "./lottie/bm_combine.json";
// import bm_duplicate from "./lottie/bm_duplicate.json";
// import bm_expand from "./lottie/bm_expand.json";

export class StrategicPartners extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) || (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering")) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === "exiting" && prevProps.transitionStatus !== "exiting") {
      this.animateOut();
    }
  }

  animateIn() {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.from(this.container, 0.4, { opacity: 0 }, "anim+=0.2");

    tl.staggerFrom(
      [this.title, ...entries],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.4"
    );

    return tl;
  }

  animateOut() {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.staggerTo(
      [...entries, this.title],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim"
    );

    tl.to(this.container, 0.4, { opacity: 0 }, "anim+=0.4");

    return tl;
  }

  render() {
    const { content, setClass, language } = this.props;
    const approach = content.orderedContent;
    if (!approach) return <Loader />;

    // const animations = [bm_combine, bm_expand, bm_duplicate];

    return (
      <div
        className="scroller"
        ref={ el => {
          this.container = el;
        } }
      >
        <div className="row pt8 pb2">
          {/* -------------------- section one */}
          <div className={ `${setClass({ default: "cf mb10", mobileLg: "cf mb10" })}` }>
            <div
              className={ `principles__slider ${setClass({
                default: "col--4 layout--relative",
                mobileLg: "col--12"
              })}` }
            >
              <h2
                ref={ el => {
                  this.title = el;
                } }
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }
              >
                {approach[0].title}
              </h2>
            </div>
            <div className={ setClass({ default: "col--8", mobileLg: "col--12" }) }>
              <div className="principles__slider">
                <img
                  className=""
                  ref={ el => {
                    this.map = el;
                  } }
                  src={ approach[0].image.file.url }
                  alt=""
                />
              </div>
              <div className="principles__slider">
                {/* <h4 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>abbbbbbb</h4> */}
                <Markdown source={ approach[0].text } />
              </div>
              {/* <div className={ `${setClass({ mobileLg: "btn__container" })}` }>
                <FancyLink to={ approach[0].ctaDestination } className="btn layout--right">
                  {approach[0].ctaDestinationText}
                </FancyLink>
              </div> */}
            </div>
            {/* <div className="gridspacer col--1" /> */}
          </div>

          {/* -------------------- section two 
          <div className={ `${setClass({ default: "cf mb10", mobileLg: "cf mb10" })}` }>
            <div
              className={ `principles__slider ${setClass({
                default: "col--4 layout--relative",
                mobileLg: "col--12"
              })}` }
            >
              <h2
                ref={ el => {
                  this.title = el;
                } }
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }
              >
                {approach[1].title}
              </h2>
              <div className="principles__slider">
                <img
                  className=""
                  ref={ el => {
                    this.map = el;
                  } }
                  src={ approach[1].image.file.url }
                  alt=""
                />
              </div>
            </div>
            <div className={ setClass({ default: "col--8", mobileLg: "col--12" }) }>
              <div className="principles__slider">
                <Markdown source={ approach[1].text } />
              </div>
              <div className={ `${setClass({ mobileLg: "btn__container" })}` }>
                <a className="btn layout--right" href={ approach[1].ctaDestination }>
                  {approach[1].ctaDestinationText}
                </a>
              </div>
            </div>
          </div> */}

        </div>

        <Footer nextPage={ { label: "Principles", pathname: "/principles" } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
StrategicPartners.propTypes = {
  content: object,
  setClass: func,
  transitionStatus: string,
  language: string
  // bpIsLessThan: func,
  // bpIsGreaterThan: func
};

export default bpConnect(connect(mapStateToContent("strategic-partners"))(StrategicPartners));
