const initialState = 0;

const teamSortIndex = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_TEAM_SORT_INDEX': {
      return action.sortIndex;
    }
    default: return state;
  }
}

export default teamSortIndex;
