import React from 'react';
import { Link } from 'react-router-dom';
import Footer from 'components/Footer';
import './FourOhFour.scss';

const FourOhFour = () => (
  <React.Fragment>
    <div className="fourohfour row pb6 typ--center">
      <h2 className="mb1"><strong>404</strong></h2>                  
      <p>Page not found.<br />Click <Link to="/">here</Link> to return home.</p>
    </div>
    <Footer />
  </React.Fragment>
);

export default FourOhFour;
