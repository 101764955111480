import React from 'react';
import { connect } from 'react-redux';
import { string, object, func, bool } from 'prop-types';
//import bpConnect from 'components/bpConnect';

import { fetchContent } from 'utils/cms';
import { setLanguage, setPages } from 'redux/actions';

import './LanguageFooter.scss';

const languages = ['en', 'jp'];

const text = {
  en: 'ENG',
  jp: '日本語'
};

export const LanguageFooter = ({ language, content, dispatch, isCentered }) => {
  const newLanguage = languages.find(lang => lang !== language);

  return (
    <span
      className={ isCentered? "languageselector centered notranslate" : "languageselector notranslate" }
      translate="no"
      href=""
      onClick={ () => {
        // If switching to a language without content in the store,
        // grab that content
        if (!content[newLanguage]) {
          fetchContent(newLanguage)
            .then(response => dispatch(setPages(response.items, newLanguage)))
        }

        dispatch(setLanguage(newLanguage));
      } }
    >
      { text[newLanguage].split('').map((character, index) => (
        <p key={ index } className="languageselector__character">{ character }</p>
      )) }
    </span>
  );
};

const mapStateToProps = state => ({
  content: state.content,
  language: state.language
});

LanguageFooter.propTypes = {
  content: object,
  language: string,
  dispatch: func,
  isCentered: bool
};

export default connect(mapStateToProps)(LanguageFooter);
