import React from "react";
import PropTypes from "prop-types";
import Footer from "components/Footer";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { mapStateToContent } from "utils/cms";
import Loader from "components/Loader";
import bpConnect from "components/bpConnect";
import { TimelineMax } from "gsap";
import Markdown from "components/Markdown";
// import FancyLink from "components/FancyLink";
import "./Principles.scss";

export class Principles extends React.Component {
  constructor(props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.content && isEmpty(prevProps.content)) ||
      (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering")
    ) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === "exiting" && prevProps.transitionStatus !== "exiting") {
      this.animateOut();
    }
  }

  animateIn() {
    const tl = new TimelineMax();
    if (!this.container) return false;
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.from(this.container, 0.4, { opacity: 0 }, "anim+=0.2");

    tl.staggerFrom(
      [this.title, ...entries],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.4"
    );

    return tl;
  }

  animateOut() {
    const tl = new TimelineMax();
    const entries = this.container.querySelectorAll(".principles__slider");

    tl.staggerTo(
      [...entries, this.title],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim"
    );

    tl.to(this.container, 0.4, { opacity: 0 }, "anim+=0.4");

    return tl;
  }

  render() {
    const { content, setClass, language, bpIsGreaterThan } = this.props;
    const principles = content.orderedContent;
    if (!principles) return <Loader />;

    return (
      <div
        className="scroller"
        ref={ (el) => {
          this.container = el;
        } }>
        <div className="row pt8 pb10">
          <div className="cf pb4">
            <div
              className={ `principles__slider ${setClass({
                default: "col--4 layout--relative",
                tabletLg: " "
              })}` }>
              <h1
                ref={ (el) => {
                  this.title = el;
                } }
                className={ ` typ--jp sectiontitle ${setClass({
                  tabletSm: "typ--h3"
                })}` }>
                {principles[0].title}
              </h1>
            </div>

            <div className={ setClass({ default: "col--8", tabletLg: " " }) }>
              <div className="principles__slider">
                {/* <h4 className={ `mb2 typ--jp ${setClass({ tabletSm: 'typ--h3' })}` }>abbbbbbb</h4> */}
                <Markdown source={ principles[0].text } />
              </div>
            </div>
            {/* <div className="gridspacer col--1" /> */}
          </div>

          {principles[0].subsections.map((principle, index) => (
            <div className="cf pt1 principles__section__wrapper principles__slider">
              {bpIsGreaterThan("tabletLg") &&
              <div className="gridspacer col--1" />}
              <div className={ `principles__texts__wrapper ${setClass({ default: "col--7", tabletLg: "col--8 mb4", mobileLg: "col--12" })}` }>
                <div className="principles__circle" />
                <h3 className={ `${setClass({ default: "mb1", tabletLg: "" })}` } key={ index }>{principle.title}</h3>
                <p>{principle.body}</p>
              </div>
              <div className={ `${setClass({ default: "col--4", mobileLg: "principles__image__box col--12 mb6" })}` }>
                <img
                  // key={index}
                  className="principles__image"
                  src={ principle.image.file.url }
                  // alt={get(loc, "name")}
                />
              </div>
            </div>
          ))}
        </div>

        <Footer nextPage={ { label: "Locations", pathname: "/locations" } } language={ language } />
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Principles.propTypes = {
  content: object,
  setClass: func,
  bpIsGreaterThan: func,
  transitionStatus: string,
  language: string
};

export default bpConnect(connect(mapStateToContent("principles"))(Principles));
