import { createClient } from 'contentful';

export const simplify = value => {
  if (value instanceof Array) {
    return value.map(v => simplify(v));
  } else if (typeof value === 'object') {
    if (value.fields && value.sys) {
      return simplify(value.fields);
    } else {
      return Object.keys(value)
        .reduce((obj, key) => ({ ...obj, [key]: simplify(value[key]) }), {});
    }
  } else {
    return value;
  }
}

const getPageContent = pageId => (content, language = 'en') => {
  const localizedContent = content[language] || [];
  const page = localizedContent.find(c => c.fields.id === pageId);
  return simplify(page) || {};
};

export const mapStateToContent = pageId => state => ({
  content: getPageContent(pageId)(state.content, state.language),
  englishContent: getPageContent(pageId)(state.content, 'en'),
  japaneseContent: getPageContent(pageId)(state.content, 'jp'),
  language: state.language,
  firstLoad: state.loader.firstLoad
});

// Fake client for testing
let client = { getEntries: () => Promise.resolve };

if (process.env.REACT_APP_CONTENTFUL_SPACE_ID) {
  client = createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: process.env.REACT_APP_CONTENTFUL_API_TOKEN
  });
}

export const locales = {
  jp: 'ja-JP',
  en: 'en-US'
};

/** Requests CMS content for a given language */
export const fetchContent = (lang) => {
  const language = lang || 'en';
  const options = {
    content_type: 'page',
    limit: 1000, // Don't arbitrarily limit content
    include: 10 // Pre-fetch content 10 levels deep
  };

  // If language isn't English, request content by relevant locale
  if (locales[language]) {
    options.locale = locales[language];
  }

  return new Promise((resolve, reject) => {
    client.getEntries(options)
      .then(resolve)
      .catch(err => {
        /* eslint-disable-next-line */
        console.error(err);
        reject(err);
      });
  });
}

export const getCmsNode = (route, contentArray) => {
  const componentIdMap = {
    WhyJapan: 'home-why-japan',
    OurValues: 'home-our-values',
    Member: 'team',
    Insight: 'insights'
  }

  const id = componentIdMap[route.name] || route.path.split('/').filter(s => s)[0];

  const node = simplify(contentArray).reduce((found, el) => {
    if (found) return found;

    return el.id === id
      ? el
      : (el.subsections || []).find(sec => sec.id === id);
  }, undefined);

  return node;
}

export const findPathToNode = (shape, matchFunction, path = '') => {
  if (matchFunction(shape)) {
    return path;
  } else if (shape instanceof Array) {
    return shape.reduce((p, el, i) => {
      if (p) return p;
      return findPathToNode(el, matchFunction, `${path}[${i}]`);
    }, null);
  } else if (typeof shape === 'object') {
    return Object.keys(shape).reduce((p, k) => {
      if (p) return p;
      return findPathToNode(shape[k], matchFunction, `${path}.${k}`);
    }, null);
  } else {
    return null;
  }
}

export default client;
