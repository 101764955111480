import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

import createRootReducer from './reducers';

// ReactGA.initialize('UA-46732848-1');
ReactGA.initialize('G-M2DZ2CSRHV');

export const history = createBrowserHistory();

// Send routing events and language to Google Analytics
const googleAnalyticsMiddleware = (store) => (next) => (action) => {
  if (['@@router/LOCATION_CHANGE', 'SET_LANGUAGE'].includes(action.type)) {
    const { language, router } = store.getState();
    const route = action.type === '@@router/LOCATION_CHANGE' ? action.payload : router;

    const nextPage = `${route.location.pathname}${route.location.search}`;
    // ReactGA.pageview(nextPage, [], `Language: ${language}`);
    ReactGA.send({ hitType: "pageview", page: nextPage, title: `Language: ${language}` });
  }

  return next(action);
};

const middleware = [routerMiddleware(history), googleAnalyticsMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
);

const configureStore = (initialState) => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    enhancer
  );

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('./reducers', () =>
      store.replaceReducer(require('./reducers'))
    );
  }

  return store;
};

export default configureStore({});
