import React from 'react';
import { TimelineMax, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react-web'
import Waypoint from 'react-waypoint';
import { connect } from 'react-redux';

import FancyLink from 'components/FancyLink';
import { mapStateToContent } from 'utils/cms';
import Loader from 'components/Loader';
import Markdown from 'components/Markdown';
import bpConnect from 'components/bpConnect';
import { boundsToJSON } from 'utils/helpers';
import bm_combine from './lottie/bm_combine.json';
import bm_duplicate from './lottie/bm_duplicate.json';
import bm_expand from './lottie/bm_expand.json';

import './WhatWeDo.scss';

export class WhatWeDo extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      activeAnimationIndex: 0,
      lastScrollTime: Date.now()
    };

    this.scrollHandler = this.scrollHandler.bind(this);
    this.onNextComplete = this.onNextComplete.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);
  }

  resetCirclePosition () {
    if (this.circle) this.circle.style.cssText = '';
    this.setState({ animating: false });
  }

  scrollHandler (event) {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;

    if (Date.now() > this.state.lastScrollTime + 50) {
      let scrollContainer = this.container;
      if (!scrollContainer) return false;
      if (event.deltaY < 0 && scrollContainer.scrollTop === 0) {
        this.animateNext('/us-japan-global-bridge');
      }

      if (event.deltaY > 0 && scrollContainer.scrollTop === scrollContainer.scrollHeight - scrollContainer.offsetHeight) {
        this.animateNext('/our-values');
      }
    }

    this.setState({ lastScrollTime: Date.now() });
  }

  componentDidMount () {
    setTimeout(() => { window.addEventListener('wheel' , this.scrollHandler); }, 1000);
  }

  componentWillUnmount () {
    window.removeEventListener('wheel' , this.scrollHandler);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;

    if (!bounds) return false;

    this.setState({ animating: true });

    tl.from(this.container, 0.4, {
      backgroundColor: 'rgba(255, 255, 255, 0)'
    }, 'anim');

    tl.from(this.circle, 0.4, {
      height: bounds.height,
      width: bounds.width,
      ease: Power3.easeIn
    }, 'anim');

    tl.from(this.circle, 0.6, {
      left: bounds.left + (bounds.width / 2),
      top: bounds.top + (bounds.height / 2),
      ease: Power3.easeOut
    }, 'anim+=0.4');

    tl.from(this.window, 0.4, {
      width: '0%'
    }, 'anim+=0.4');

    tl.staggerFrom([this.title, this.content, this.arrow], 0.4, {
      y: 20,
      opacity: 0
    }, 0.1, 'anim+=0.6');

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateNext (routeTo) {
    if (this.props.bpIsLessThan('tabletLg') || this.state.animating) return false;
    const tl = new TimelineMax();

    this.setState({ animating: true });

    tl.to([this.title, this.content, this.arrow], 0.8, {
      y: 20,
      opacity: 0
    }, 'anim+=0.1');

    tl.to(this.window, 0.4, {
      left: 'auto',
      right: '0%',
      width: '0%'
    }, 'anim');

    tl.set(this.circle, {
      opacity: 0,
      onComplete: this.onNextComplete,
      onCompleteParams: [routeTo]
    }, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    if (this.props.bpIsLessThan('tabletLg')) return false;
    const tl = new TimelineMax();

    tl.to(this.window, 0.4, {
      left: 'auto',
      right: '0%',
      width: '0%'
    }, 'anim');

    tl.to([this.title, this.content, this.arrow], 0.8, {
      y: 20,
      opacity: 0
    }, 'anim+=0.1');

    tl.to(this.circle, 0.3, {
      opacity: 0
    }, 'anim+=0.1');

    return tl;
  }

  onNextComplete (routeTo) {
    this.props.history.push({
      pathname: routeTo,
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()), duration: 1000 }
    });
  }

  updateLottieAnimation (event, index) {
    if (event.previousPosition === 'below' && event.currentPosition === 'inside') {
      this.setState({ activeAnimationIndex: index });
    }

    if (event.previousPosition === 'inside' && event.currentPosition === 'below') {
      this.setState({ activeAnimationIndex: index - 1 });
    }
  }

  render () {
    const { setClass, bpIsGreaterThan, bpIsLessThan } = this.props;
    const { activeAnimationIndex } = this.state;
    if (!this.props.content.orderedContent) return <Loader />;
    const content = this.props.content.orderedContent.find(c => c.id === 'home-what-we-do');

    const animations = [
      bm_combine,
      bm_expand,
      bm_duplicate
    ];

    const generateAside = (section) => (
      <div className="whatwedo__aside">
        <ul className="list--aside">
          { section.bullets.map((bullet, bulletIndex) => (
            <li className="typ--jp" key={ bulletIndex }>{ bullet }</li>
          )) }
        </ul>
      </div>
    )

    return (
      <div className={ `whatwedo ${setClass({ default: 'scroller', tabletLg: ' ' })}` } ref={ el => { this.container = el; } }>
        <div className="row">
          <div className={ setClass({ default: 'col--6 pr6', tabletLg: ' ' }) }>
            <h1
              className={ `sectiontitle typ--jp ${setClass({ mobileLg: 'typ--h2' })}` }
              ref={ el => { this.title = el; } }
            >{ content.title }</h1>

            <div ref={ el => { this.content = el; } } className={ setClass({ default: 'mb10', mobileLg: ' ' }) }>
              { content.subsections.map((section, sectionIndex) => (
                <React.Fragment key={ sectionIndex }>
                  { bpIsLessThan('tabletLg') &&
                    <div className={ `whatwedo__lottie lottie-${sectionIndex}` }>
                      <div className="lottie__circle" />
                      <Lottie options={ { animationData: animations[sectionIndex] } } />
                    </div>
                  }
                  <div className={ `whatwedo__textwrapper ${setClass({ default: 'py6', tabletLg: ' ' }) }` }>
                    <Waypoint
                      onPositionChange={ e => this.updateLottieAnimation(e, sectionIndex) }
                      bottomOffset={ 500 }
                    >
                      <div className={ `whatwedo__block ${sectionIndex === activeAnimationIndex ? 'is-active' : ''}` }>
                        <h4 className="numberedtitle typ--jp">
                          <span>0{ sectionIndex + 1 } &ndash;</span>
                          { section.title }
                        </h4>

                        { bpIsLessThan('mobileLg') && generateAside(section) }

                        <div className="cf">
                          <div className="whatwedo__text">
                            <div className="typ--light typ--jp"><Markdown source={ section.text } /></div>
                            <FancyLink
                              to={ section.ctaDestination }
                              className="btn"
                            >
                              Learn more
                            </FancyLink>
                          </div>

                          { bpIsGreaterThan('mobileLg') && generateAside(section) }
                        </div>
                      </div>
                    </Waypoint>
                  </div>
                </React.Fragment>
              )) }
            </div>

            { bpIsGreaterThan('tabletLg') &&
              <span
                ref={ el => { this.arrow = el; } }
                className="scrollindicator icon-long-arrow-down"
                onClick={ () => this.animateNext('/our-values') }
              />
            }

          </div>
        </div>

        { bpIsGreaterThan('tabletLg') &&
          <div className="whatwedo__window" ref={ el => { this.window = el; } }>
            <div className="whatwedo__circle" ref={ el => { this.circle = el; } } />

            <div ref={ el => { this.lottie = el; } }>
              { animations.map((animationData, index) => (
                <div className={ `whatwedo__lottie lottie-${index} ${activeAnimationIndex === index ? 'is-active' : ''}` } key={ index }>
                  <Lottie options={ { animationData } } />
                </div>
              )) }
            </div>
          </div>
        }
      </div>
    );
  }
}

const { object, string, func } = PropTypes;
WhatWeDo.propTypes = {
  history: object,
  transitionStatus: string,
  location: object,
  content: object,
  bpIsLessThan: func,
  bpIsGreaterThan: func,
  setClass: func
};

export default bpConnect(connect(mapStateToContent('home'))(WhatWeDo));
