import React from 'react';
import { connect } from 'react-redux';
import { func, oneOfType, node, array, string } from 'prop-types';

import { fetchContent, locales } from 'utils/cms';
import { setPages, setLanguage, setActiveBreakpoint } from 'redux/actions';
import Header from 'components/Header';
import LanguageFooter from 'components/LanguageFooter';
import { initReduxBreakpoints } from 'utils/responsiveHelpers';
import bpConnect from 'components/bpConnect';
import Helmet from 'components/Helmet';

import 'styles/core.scss';
import './AppContainer.scss';

class AppContainer extends React.Component {
  componentDidMount () {
    const { dispatch } = this.props;
    // If the IP specifies a language, set it in the Redux store
    // and clear the queryString
    const language = this.getLanguage();

    // Fetch language content, starting with selected language
    Object.keys(locales).sort(a => a === language ? -1 : 1)
      .forEach(lang => fetchContent(lang)
        .then(response => {
          const pages = response.items;
          dispatch(setPages(pages, lang));
        }));

    initReduxBreakpoints.call(
      this, window, (breakpointName, breakpointSize, mediaQueryState) =>
        dispatch(setActiveBreakpoint(breakpointName, breakpointSize, mediaQueryState))
    );
  }

  getLanguage () {
    const { dispatch } = this.props;
    // Don't use localhost locally, because it makes debugging difficult
    const storedLanguage = localStorage.getItem('wil-user-language');
    if (storedLanguage) {
      const language = storedLanguage;
      dispatch(setLanguage(language));
      return language;
    } else {
      let language;

      fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`)
        .then(res => res.json())
        .then(json => {
          const acceptable = { JP: 'jp' };
          language = acceptable[json.country] || 'en';
          dispatch(setLanguage(language));
        })
        .catch(() => {
          // Likely using an adblocker
          dispatch(setLanguage('en'))
        });

      return language || 'en';
    }
  }

  render () {
    const { children, language, bpIsGreaterThan } = this.props;

    return (
      <div className={ `app lang-${language}` }>
        <Helmet />
        <Header />
        <div className="pagecontent">
          { children }
        </div>
        { bpIsGreaterThan('mobileLg') && <LanguageFooter /> }
      </div>
    );
  }
}

AppContainer.propTypes = {
  language: string,
  children: oneOfType([node, array]),
  dispatch: func,

  // Included in initReduxBreakpoints automatically
  // eslint-disable-next-line
  setActiveBreakpoint: func,
  bpIsGreaterThan: func
};

const mapStateToProps = state => ({
  router: state.router,
  language: state.language
});

export default bpConnect(connect(mapStateToProps)(AppContainer));
