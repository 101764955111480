import React from 'react';
import Measure from 'react-measure';
import { getCirclePath } from 'utils/helpers';
import './Circles.scss';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import bpConnect from 'components/bpConnect';
import { isRetinaDisplay } from 'utils/helpers';

const defaultCircleDimensions = [
  { left: 120, top: -111, diameter: 296 },
  { left: -87, top: 122, diameter: 203 },
  { left: 154, top: 238, diameter: 138 },
  { left: -38, top: 423, diameter: 352 },
  { left: 278, top: 732, diameter: 210 },
  { left: 955, top: -139, diameter: 242 },
  { left: 1311, top: -9, diameter: 272 },
  { left: 1119, top: 142, diameter: 138 },
  { left: 1156, top: 322, diameter: 315 },
  { left: 1003, top: 658, diameter: 268 }
];

const tabletLgCircleDimensions = [
  { left: 109, top: -77, diameter: 221 },
  { left: -108, top: 129, diameter: 163 },
  { left: 93, top: 230, diameter: 117 },
  { left: -55, top: 440, diameter: 275 },
  { left: 266, top: 687, diameter: 162 },
  { left: 626, top: -60, diameter: 186 },
  { left: 934, top: -2, diameter: 205 },
  { left: 802, top: 182, diameter: 131 },
  { left: 843, top: 366, diameter: 238 },
  { left: 684, top: 627, diameter: 196 }
];

const tabletMdCircleDimensions = [
  { left: -30, top: 127, diameter: 186 },
  { left: 207, top: -39, diameter: 221 },
  { left: -100, top: 449, diameter: 164 },
  { left: -104, top: 706, diameter: 275 },
  { left: 250, top: 920, diameter: 162 },
  { left: 476, top: 25, diameter: 186 },
  { left: 648, top: 227, diameter: 205 },
  { left: 750, top: 960, diameter: 131 },
  { left: 694, top: 547, diameter: 238 },
  { left: 497, top: 794, diameter: 196 }
];

const mobileLgCircleDimensions = [
  { left: 94, top: 3, diameter: 126 },
  { left: -86, top: 89, diameter: 131 },
  { left: -107, top: 289, diameter: 114 },
  { left: -72, top: 480, diameter: 200 },
  { left: 136, top: 651, diameter: 84 },
  { left: 262, top: 70, diameter: 85 },
  { left: 354, top: 173, diameter: 114 },
  { left: 313, top: 431, diameter: 163 },
  { left: 261, top: 613, diameter: 84 },
  { left: 170, top: 520, diameter: 91 }
];

const mobileXsmCircleDimensions = [
  { left: 97, top: -20, diameter: 126 },
  { left: -86, top: 89, diameter: 131 },
  { left: -107, top: 289, diameter: 114 },
  { left: -75, top: 437, diameter: 200 },
  { left: 126, top: 600, diameter: 84 },
  { left: 262, top: 70, diameter: 85 },
  { left: 316, top: 192, diameter: 114 },
  { left: 273, top: 398, diameter: 163 },
  { left: 238, top: 565, diameter: 84 },
  { left: 150, top: 449, diameter: 91 }
];

export class Circles extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      bounds: {
        width: 1440,
        height: 820
      }
    };

    defaultCircleDimensions.forEach((c, i) => this.state[`circle${i}`] = 0);
  }

  updateBounds (el) {
    if (el) {
      const bounds = el.getBoundingClientRect();
      this.setState({ bounds });
    }
  }

  getPositionClass (circle) {
    if (circle.left > (this.state.bounds.width / 2)) {
      return 'circle--right';
    } else {
      return 'circle--left';
    }
  }

  render () {
    const { bounds } = this.state;
    const { circleData, setClass } = this.props;

    const autoplayElements = circleData.filter(d => d.images.length > 1);

    return (
      <Measure
        bounds
        onResize={ contentRect => {
          this.setState({ bounds: contentRect.bounds })
        } }
      >
        { ({ measureRef })  => (

          <div className="circles" ref={ measureRef }>
            { circleData.map((circleObj, index) => {

              const circleBounds = setClass({
                default: defaultCircleDimensions,
                tabletLg: tabletLgCircleDimensions,
                tabletMd: tabletMdCircleDimensions,
                mobileLg: mobileLgCircleDimensions,
                mobileXsm: mobileXsmCircleDimensions
              })[index];

              const width = circleBounds.diameter;
              const left = `${circleBounds.left / bounds.width * 100}%`;
              const height = circleBounds.diameter;
              const top = `${circleBounds.top / bounds.height * 100}%`;

              const styles = {
                top,
                left,
                width,
                height
              };

              const circleState = this.state[`circle${index}`];
              const carouselDelay = 4;
              const carouselItemIndex = Math.max(0, autoplayElements.findIndex(el => el.title === circleObj.title)) % carouselDelay;

              return (
                <div
                  key={ index }
                  className={ `circles__item circle-${index}` }
                  style={ styles }
                >
                  <div className="circles__carousel">
                    <Carousel
                      showArrows={ false }
                      draggable={ false }
                      autoPlay={ carouselDelay * 1000 }
                      onChange={ event => this.setState({ [`circle${index}`]: event }) }
                      delayAutoplay={ carouselItemIndex * 1000 }
                      slides={
                        circleObj.images.map((img, circleIndex) => (
                          <img
                            key={ circleIndex }
                            src={ `${get(img, 'file.url')}?w=${isRetinaDisplay() ? width * 2 : width}` }
                            alt={ get(img, 'title') }
                          />
                        ))
                      }
                    />
                  </div>

                  { !!get(circleObj.images[circleState], 'description') && (
                    <svg
                      className={ `circles__caption ${this.getPositionClass(circleBounds)}` }
                      viewBox={ `0 0 ${circleBounds.diameter} ${circleBounds.diameter}` }
                    >
                      <path id={ `curve-${index}` } d={ getCirclePath(circleBounds.diameter / 2) } />
                      <text className="typ--jp">
                        <textPath xlinkHref={ `#curve-${index}` }>
                          { circleObj.images[circleState].description }
                        </textPath>
                      </text>
                    </svg>
                  ) }
                </div>
              );
            }) }
          </div>
        ) }
      </Measure>
    );
  }
}

const { array, func } = PropTypes;
Circles.propTypes = {
  circleData: array,
  setClass: func
};

export default bpConnect(Circles);
