import React from 'react';
import ReactMarkdown from 'react-markdown';
import { string } from 'prop-types';
import { isRetinaDisplay } from 'utils/helpers';

import './Markdown.scss';

// Wraps elements containing divs for absolute placement
const wrap = nativeElement => elProps => {

  if (nativeElement === 'img' && elProps.alt.indexOf(':InnerImage') === -1) {
    const dimension = isRetinaDisplay() ? 800 : 400;

    elProps = {
      alt: elProps.alt,
      src: `${elProps.src}?w=${dimension}`
    }
  }

  // If the image's alt contains ':InnerImage', the image will be shown inside main text box
  if (nativeElement === 'img') {
    if (elProps.alt.indexOf(':InnerImage') > -1){
      return (
        <figure className={ `markdown__element-container markdown__${nativeElement}-container-inner` }>
          { React.createElement(nativeElement, elProps) }
          <figcaption>{elProps.alt.replace(':InnerImage','')}</figcaption>
        </figure>
      )
    }
    // Normal images that will be shown left side as circle image
    else {
      return (
        <span className={ `markdown__element-container markdown__${nativeElement}-container` }>
          { React.createElement(nativeElement, elProps) }
        </span>
      )
    }
    
  }
  // Except img tag
  else {
    return (
      <span className={ `markdown__element-container markdown__${nativeElement}-container` }>
        { React.createElement(nativeElement, elProps) }
      </span>
    )
  }

};

const renderers = {
  image: wrap('img'),
  blockquote: wrap('blockquote'),
  link: (props) => <a { ...props } target="_blank" />
};

export const Markdown = ({ source, className }) => (
  <ReactMarkdown
    className={ [className, 'markdown'].filter(s => s).join(' ') }
    source={ source }
    escapeHtml={ false }
    renderers={ renderers }
  />
);

Markdown.propTypes = {
  source: string.isRequired,
  className: string
};

export default Markdown;
