import React from 'react';
import { connect } from 'react-redux';
import TeamMemberGrid from 'components/TeamMemberGrid';
import { mapStateToContent } from 'utils/cms';
import PropTypes from 'prop-types';
import Footer from 'components/Footer';
import bpConnect from 'components/bpConnect';
import { TimelineMax } from 'gsap';
import { isEmpty } from 'lodash';
import './Team.scss';

export class Team extends React.Component {
  constructor (props) {
    super(props);
    this.animateIn = this.animateIn.bind(this);
  }

  componentDidMount () {
    // console.log(this.grid);
  }

  componentDidUpdate (prevProps) {
    // If first mount or entering, animate in
    if ((this.props.content && isEmpty(prevProps.content)) ||
        (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering')) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();
    if (!this.grid) return false;

    tl.from(this.container, 0.6, { backgroundColor: 'transparent' }, 'anim');

    const isoItems = [].slice.call(
      this.grid.querySelectorAll('.iso__item')
    ).filter(item => item.style.display !== 'none');

    tl.staggerFrom([this.teamphoto, ...isoItems, this.footer], 0.4, {
      y: 40, opacity: 0
    }, 0.08, 'anim+=0.4');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();

    tl.to(this.container, 0.6, { backgroundColor: 'transparent' }, 'anim');

    const isoItems = [].slice.call(
      this.grid.querySelectorAll('.iso__item')
    ).filter(item => item.style.display !== 'none');

    tl.staggerTo([this.teamphoto, ...isoItems, this.footer], 0.4, {
      y: 40, opacity: 0
    }, 0.05, 'anim');

    return tl;
  }

  render () {
    const { history, content, setClass, englishContent, language, bpIsLessThan } = this.props;

    const teamData = content.orderedContent;
    const englishTeamData = englishContent.orderedContent;
    if (!teamData) return false;

    return (
      <div className="scroller" ref={ el => { this.container = el; } }>
        <div 
          ref={ el => { this.teamphoto = el; } }
          className="team__teamphoto__container"
        >
          { bpIsLessThan('mobileLg') ? 
            <img src={ require("assets/img/team/WiL-team-photo.jpg") } alt="" />
            :
            <img src={ require("assets/img/team/WiL-team-photo.jpg") } alt="" />
          }
        </div>
        <div
          ref={ el => { this.grid = el; } }
          className={ `team row ${setClass({ default: 'pt8 pb10', mobileLg: 'pt4 pb6' })}` }
        ><TeamMemberGrid history={ history } title={ content.subtitle } data={ teamData } englishData={ englishTeamData } /></div>

        <div ref={ el => { this.footer = el; } }>
          <Footer nextPage={ { label: 'Companies', pathname: '/companies' } } language={ language }  />
        </div>
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Team.propTypes = {
  history: object,
  content: object,
  setClass: func,
  transitionStatus: string,
  englishContent: object,
  language: string,
  bpIsLessThan: func
};

export default bpConnect(connect(mapStateToContent('team'))(Team));
