import React from 'react';
import Tabs from 'components/Tabs';
import Isotope from 'isotope-layout';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import { get, kebabCase } from 'lodash';
import imagesLoaded from 'imagesloaded';
import bpConnect from 'components/bpConnect';
import { connect } from 'react-redux';
import { boundsToJSON } from 'utils/helpers';
import { setTeamSortIndex } from 'redux/actions';
import { isRetinaDisplay } from 'utils/helpers';
import './TeamMemberGrid.scss';

export class TeamMemberGrid extends React.Component {
  constructor (props) {
    super(props);
    this.state = { sortIndex: props.initialActiveIndex || 0, expanded: false };
    this.sortKeys = ['All', 'US', 'Japan'];
    this.updateSort = this.updateSort.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.getFilterValue = this.getFilterValue.bind(this);
  }

  componentDidMount () {
    const filterValue = this.getFilterValue();

    this.iso = new Isotope(this.grid, {
      layoutMode: 'fitRows',
      itemSelector: '.iso__item',
      filter: filterValue
    });

    imagesLoaded(this.grid, () => {
      this.iso.layout();
    });
  }

  componentDidUpdate(prevProps) {
    const { teamSortIndex } = this.props;
    if (prevProps.teamSortIndex !== teamSortIndex) {
      const filterValue = this.getFilterValue();
      this.iso.arrange({ filter: filterValue });
    }
  }

  getFilterValue () {
    const { teamSortIndex } = this.props;
    const activeSortKey = kebabCase(this.sortKeys[teamSortIndex]);
    return activeSortKey === 'all' ? '*' : `.${activeSortKey}`;
  }

  animateOut (member) {
    const tl = new TimelineMax();

    tl.set(member, { opacity: 0 });

    return tl;
  }

  updateSort(index) {
    const { dispatch } = this.props;
    dispatch(setTeamSortIndex(index));
  }

  triggerModal (member, index, englishMember) {
    this.setState(
      { expanded: true },
      () => {
        this.animateOut(this[`member-${index}`]);
        this.props.history.push({
          pathname: `/team/${kebabCase(englishMember.name)}`,
          state: {
            transition: 'team-transition',
            member: member,
            duration: 600,
            memberBounds: this[`member-${index}`] && boundsToJSON(this[`member-${index}`].getBoundingClientRect())
          }
        });
      }
    );
  }

  render () {
    const { expanded } = this.state;
    const { data, setClass, title, englishData, teamSortIndex } = this.props;

    return (
      <div className="teamgrid" ref={ el => { this.grid = el; } }>
        <div className="iso__item japan us">
          <div style={ { maxWidth: setClass({ default: '280px', mobileLg: 'none' }) } }>
            <h2 className={ `sectiontitle typ--jp ${setClass({ default: 'mt5', mobileLg: 'mb4' })}` }>
              { title }
            </h2>
            <Tabs
              items={ this.sortKeys }
              initialActiveIndex={ teamSortIndex }
              onChange={ this.updateSort }
            />
          </div>
        </div>

        { data.map((member, index) => (
          <div
            key={ index }
            className={
              `teamgrid__member iso__item ${
                (member.sortableValues || []).map(key => kebabCase(key)).join(' ')
              } ${
                expanded ? 'is-expanded' : ''
              }`
            }
            onClick={ () => this.triggerModal(member, index, englishData[index]) }
          >
            <div
              className={ `teamgrid__window bg-${kebabCase(member.backgroundColor || 'grey')}` }
              ref={ el => { this[`member-${index}`] = el; } }
            >
              <img src={ `https:${get(member, 'image.file.url')}?w=${isRetinaDisplay() ? 962 : 481}` } alt={ get(member, 'image.title') } />
            </div>
            <div className="teamgrid__memberinfo">
              <h4 className="typ--bold typ--jp">{ member.name }</h4>
              <p className="typ--medium typ--jp">{ member.role }</p>
            </div>
          </div>
        )) }
      </div>
    );
  }
}

const { number, object, array, func, string } = PropTypes;
TeamMemberGrid.propTypes = {
  initialActiveIndex: number,
  history: object,
  data: array,
  setClass: func,
  title: string,
  englishData: array,
  dispatch: func,
  teamSortIndex: number
};

const mapStateToProps = state => ({
  teamSortIndex: state.teamSortIndex
});

export default bpConnect(connect(mapStateToProps)(TeamMemberGrid));
