import React from 'react';
import isExternal from 'is-url-external';
import { Link } from 'react-router-dom';
import { string, any } from 'prop-types';

// Handles external and internal URLs
export const FancyLink = (props) => {
  if (props.to) {
    return isExternal(props.to)
      ? <a href={ props.to } { ...props } target="_blank" rel="noopener noreferrer">{ props.children }</a>
      : <Link { ...props } />
  } else {
    return <button { ...props } />
  }
};

FancyLink.propTypes = {
  to: string,
  children: any
};

export default FancyLink;
