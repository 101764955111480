import React from 'react';
import { Link } from 'react-router-dom';
import { TimelineMax, Power0, Power2 } from 'gsap';
import PropTypes from 'prop-types';
import { mapStateToContent } from 'utils/cms';
import { connect } from 'react-redux';
import { kebabCase, get, chunk } from 'lodash';
import isExternal from 'is-url-external';

import Loader from 'components/Loader';
import bpConnect from 'components/bpConnect';
import Markdown from 'components/Markdown';
import FancyLink from 'components/FancyLink'
import { boundsToJSON, isValidEmail } from 'utils/helpers';

import './Member.scss';

export class Member extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.resetImgPosition = this.resetImgPosition.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') this.animateOpen();
    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') this.animateClosed();
  }

  resetImgPosition () {
    if (this.imgwindow) this.imgwindow.style.cssText = '';
    if (this.img) this.img.style.cssText = '';
  }

  fadeContent() {
    return [this.title, this.name, this.social, this.relatedInsights, this.nextMember, this.companies].filter(el => el);
  }

  animateOpen () {
    const { bpIsGreaterThan } = this.props;
    const tl = new TimelineMax();
    const bounds = this.props.location.state.memberBounds;
    const factElems = this.container.querySelectorAll('.member__fact');

    tl.from(this.container, 0.3, {
      backgroundColor: "rgba(255, 255, 255, 0)"
    }, 'anim');

    if (bpIsGreaterThan('tabletSm') && bounds) {
      tl.from(this.imgwindow, 0.7, {
        position: 'fixed',
        width: bounds.width,
        height: bounds.height,
        top: bounds.top + (bounds.height / 2),
        left: bounds.left,
        ease: Power0.easeOut
      }, 'anim');
    } else {
      tl.from(this.imgwindow, 0.7, {
        opacity: 0,
        y: -40,
        ease: Power0.easeOut
      }, 'anim');
    }

    tl.from(this.img, 1.5, {
      opacity: 0
    }, 'anim+=0.5');

    tl.from(this.keyline, 0.8, { width: '0%', ease: Power2.easeOut }, 'anim+=0.5');

    tl.staggerFrom(this.fadeContent(), 0.4, {
      opacity: 0,
      y: 20,
      ease: Power2.easeOut
    }, 0.06, 'anim+=0.5');

    tl.staggerFrom([...factElems, this.content], 0.4, {
      opacity: 0,
      y: 20,
      ease: Power2.easeOut
    }, 0.06, 'anim+=0.7');

    tl.set(this.container, { onComplete: this.resetImgPosition });

    return tl;
  }

  animateClosed () {
    const tl = new TimelineMax();
    const factElems = this.container.querySelectorAll('.member__fact');

    tl.to(this.keyline, 0.8, { width: '0%', opacity: 0 }, `anim`);

    tl.staggerTo([this.content, ...factElems], 0.4, {
      opacity: 0,
      y: 20
    }, 0.06, 'anim');

    tl.to(this.nextMember, 0.4, { opacity: 0, x: -20 }, 'anim');

    tl.staggerTo(this.fadeContent(), 0.4, {
      opacity: 0,
      y: 20
    }, 0.06, 'anim');

    tl.to(this.imgwindow, 0.6, {
      opacity: 0,
      ease: Power0.easeOut
    }, 'anim');

    tl.to(this.container, 0.8, {
      opacity: 0,
      zIndex: 100
    }, 'anim+=0.3');

    tl.to(this.close, 0.5, {
      opacity: 0,
      z: 0,
      ease: Power0.easeOut
    }, 'anim+=0.3');

    return tl;
  }

  closeModal() {
    this.props.history.push({ pathname: '/team', state: { duration: 1000 } });
  }

  getSocialIcon (type) {
    return ({
      Facebook: 'icon-facebook',
      Twitter: 'icon-twitter',
      LinkedIn: 'icon-linkedin',
      Email: 'icon-email'
    }[type]);
  }

  generateSocialLink (link) {
    return isValidEmail(link) ? `mailto:${link}` : link;
  }

  render () {
    const { content, location, setClass, englishContent, language, history } = this.props;
    const teamData = content.orderedContent;
    if (!teamData) return <Loader />;

    const memberSlug = location.pathname.split('/')[2];
    const memberIndex = (englishContent.orderedContent || []).findIndex(member => kebabCase(member.name) === memberSlug);
    const member = teamData[memberIndex];
    if (!member) return <Loader />;


    const nextMemberIndex = teamData.length - 1 === memberIndex ? 0 : memberIndex + 1;
    const nextMember = content.orderedContent[nextMemberIndex];

    let currentCompanies = member.currentCompanies;

    if (currentCompanies) {
      currentCompanies = currentCompanies.length > 4
        ? chunk(member.currentCompanies, Math.ceil(member.currentCompanies.length / 2))
        : [currentCompanies];
    }

    let previousCompanies = member.previousCompanies;

    if (previousCompanies) {
      previousCompanies = previousCompanies.length > 4
        ? chunk(member.previousCompanies, Math.ceil(member.previousCompanies.length / 2))
        : [previousCompanies];
    }

    /** Returns link in insight data, or produced link to the hosted article */
    const link = insight => insight.link || `/blog/${kebabCase(insight.title)}`;

    let insights = member.relatedInsights;
    if (member.relatedInsights) { insights = insights.filter(insight => insight.language === language) }

    return (
      <div
        className="member"
        ref={ el => { this.container = el; } }
        style={ { height: window.innerHeight } }
      >
        <div className="modal__close" onClick={ this.closeModal } ref={ el => { this.close = el; } }>
          <span className="icon-close" />
        </div>

        <div className="row">
          <div className={ `member__img ${setClass({ default: 'col--6', desktopSm: 'col--4', tabletSm: ' ' })}` }>
            <div
              className={ `member__imgwindow bg-${kebabCase(member.backgroundColor || 'grey')}` }
              ref={ el => { this.imgwindow = el; } }
            >
              <img ref={ el => { this.img = el; } } src={ member.image.file.url } alt={ member.image.title } />
            </div>
          </div>

          <div className={ `member__bio ${setClass({ default: 'col--6', desktopSm: 'col--8', tabletSm: ' ' })}` }>
            <div className="member__name">
              <div className="member__nameline" ref={ el => { this.keyline = el; } } />
              <h1
                className={ `typ--jp ${setClass({ default: 'mb1', tabletSm: 'typ--h3' })}` }
                ref={ el => { this.name = el; } }
              >{ member.name }</h1>
              <h4 className="typ--bold typ--jp" ref={ el => { this.title = el; } }>{ member.role }</h4>

              <ul
                className={ `member__social ${setClass({ default: 'mt4', tabletSm: 'mt2' })}` }
                ref={ el => { this.social = el; } }
              >
                { member.socialLinks && member.socialLinks.map((social, index) => (
                  <li key={ index }>
                    <a href={ this.generateSocialLink(social.link) } rel="noopener noreferrer" target="_blank">
                      <span className={ this.getSocialIcon(social.type) } />
                    </a>
                  </li>
                )) }
              </ul>
            </div>

            <div className={ `member__content typ--h5 ${setClass({ default: 'py5', mobileLg: ' ' })}` }>

              <div className="member__allcompanies" ref={ el => this.companies = el }>
                { currentCompanies &&
                  <React.Fragment>
                    <h4 className="typ--bold mb2">Current Companies</h4>
                    <div className="member__companies mb8 cf">

                      { currentCompanies.map((companyGroup, index) => (
                        <div className={ setClass({ default: 'col--6', mobileMd: ' ' }) } key={ index }>
                          { companyGroup.map((company, ind) => (
                            <div key={ ind }>
                              <FancyLink to={ company.link } className="company-link typ--bold">{ company.name }</FancyLink>
                              { company.exitInformation && <span>&nbsp;({ company.exitInformation })</span> }
                            </div>
                          ))}
                        </div>
                      )) }
                    </div>
                  </React.Fragment>
                }

                { previousCompanies &&
                  <React.Fragment>
                    <h4 className="typ--bold mb2">Notable Previous Investments</h4>
                    <div className="member__companies mb8 cf">

                      { previousCompanies.map((companyGroup, index) => (
                        <div className={ setClass({ default: 'col--6', mobileMd: ' ' }) } key={ index }>
                          { companyGroup.map((company, ind) => (
                            <div key={ ind }>
                              <FancyLink to={ company.link } className="typ--bold">{ company.name }</FancyLink>
                              { company.exitInformation && <span>&nbsp;({ company.exitInformation })</span> }
                            </div>
                          ))}
                        </div>
                      )) }
                    </div>
                  </React.Fragment>
                }
              </div>

              <div className={ `cf ${setClass({ tabletSm: 'mb3' })}` }>
                { member.facts && member.facts.map((fact, index) => (
                  <div key={ index } className={ `member__fact ${setClass({ default: 'col--4', mobileLg: ' ' })}` }>
                    <span className={ `icon-fact-${fact.type.iconId}` } />
                    <h4 className="typ--bold typ--jp">{ fact.type.name }</h4>
                    <p className="typ--b1 typ--light typ--jp">{ fact.description }</p>
                  </div>
                )) }
              </div>

              <div
                className="typ--jp"
                ref={ el => { this.content = el; } }
              >
                <Markdown source={ member.bio } />
              </div>

              { ( insights && insights.length > 0 ) &&
                <div className="member__relatedinsights"
                  ref={ el => { this.relatedInsights = el; } } >
                  <h4 className="typ--bold typ--jp">Latest</h4>
                  <ul>
                    { insights.map((insight, index) => (
                      <li className="insight__item" key={ index }>
                        <FancyLink
                          to={ isExternal(link(insight)) ? link(insight) : null }
                          onClick={ () => {
                            if (isExternal(link(insight))) return null;

                            // Link with this page's history, for returning from article
                            history.push({
                              pathname: link(insight),
                              state: { returnTo: location.pathname, ...location.state }
                            })
                          } }
                        >
                          <p className={ `mb1 typ--jp insight__title` }>{ insight.title }</p>
                        </FancyLink>
                      </li>
                    )) }
                  </ul>
                </div>
              }

            </div>
            <div
              ref={ el => { this.nextMember = el; } }
              className="member__next"
            >
              <Link
                to={ {
                  pathname:`/team/${kebabCase(englishContent.orderedContent[nextMemberIndex].name)}`,
                  state: { memberBounds: this.imgwindow && boundsToJSON(this.imgwindow.getBoundingClientRect()) }
                } }>
                <img src={ get(nextMember, 'image.file.url') } alt={ get(nextMember, 'image.title') } />
                <div>
                  <p className="mb0">Next Bio</p>
                  <p className="typ--bold mb0 typ--jp">{ nextMember.name }</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { object, string, func } = PropTypes;
Member.propTypes = {
  location: object,
  transitionStatus: string,
  history: object,
  content: object,
  englishContent: object,
  setClass: func,
  bpIsGreaterThan: func,
  language: string
};

export default bpConnect(connect(mapStateToContent('team'))(Member));
