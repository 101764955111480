import React from 'react';
import Measure from 'react-measure';
import { getCirclePath } from 'utils/helpers';
import './Circles.scss';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import bpConnect from 'components/bpConnect';
import { isRetinaDisplay } from 'utils/helpers';

const defaultCircleDimensions = [
  { left: 60, top: 240, diameter: 296 },
  { left: 1100, top: 140, diameter: 296 },
  { left: 950, top: 440, diameter: 280 },
  { left: 940, top: 140, diameter: 120 },
  { left: 310, top: 540, diameter: 200 },
  { left: 360, top: -20, diameter: 220 }
];

const tabletLgCircleDimensions = [
  { left: 160, top: 240, diameter: 240 },
  { left: 1000, top: 140, diameter: 240 },
  { left: 950, top: 440, diameter: 230 },
  { left: 900, top: 140, diameter: 80 },
  { left: 350, top: 520, diameter: 160 },
  { left: 360, top: -20, diameter: 220 }
];

const tabletSmCircleDimensions = [
  { left: 270, top: 240, diameter: 200 },
  { left: 980, top: 140, diameter: 200 },
  { left: 920, top: 400, diameter: 200 },
  { left: 880, top: 140, diameter: 80 },
  { left: 400, top: 460, diameter: 140 },
  { left: 440, top: 70, diameter: 120 }
];

const mobileLgCircleDimensions = [
  { left: 320, top: 240, diameter: 200 },
  { left: 920, top: 140, diameter: 200 },
  { left: 860, top: 410, diameter: 200 },
  { left: 840, top: 80, diameter: 80 },
  { left: 460, top: 470, diameter: 140 },
  { left: 460, top: 70, diameter: 120 }
];

const mobileMdCircleDimensions = [
  { left: 480, top: 0, diameter: 160 },
  { left: 840, top: 20, diameter: 180 },
  { left: 840, top: 430, diameter: 160 },
  { left: 480, top: 460, diameter: 120 },
  { left: 420, top: 200, diameter: 120 },
  { left: 720, top: -40, diameter: 100 }
];

const mobileSmCircleDimensions = [
  { left: 520, top: -20, diameter: 140 },
  { left: 780, top: 10, diameter: 160 },
  { left: 760, top: 430, diameter: 140 },
  { left: 560, top: 440, diameter: 120 },
  { left: 520, top: 140, diameter: 80 },
  { left: 720, top: -80, diameter: 100 }
];

const mobileXsmCircleDimensions = [
  { left: 520, top: -20, diameter: 140 },
  { left: 780, top: 10, diameter: 160 },
  { left: 740, top: 440, diameter: 130 },
  { left: 560, top: 440, diameter: 120 },
  { left: 520, top: 140, diameter: 80 },
  { left: 720, top: -80, diameter: 100 }
];


export class Circles extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      bounds: {
        width: 1440,
        height: 820
      }
    };

    defaultCircleDimensions.forEach((c, i) => this.state[`circle${i}`] = 0);
  }

  updateBounds (el) {
    if (el) {
      const bounds = el.getBoundingClientRect();
      this.setState({ bounds });
    }
  }

  getPositionClass (circle) {
    if (circle.left > (this.state.bounds.width / 2)) {
      return 'circle--right';
    } else {
      return 'circle--left';
    }
  }

  render () {
    const { bounds } = this.state;
    const { circleData, setClass } = this.props;

    // const autoplayElements = circleData.filter(d => d.images.length > 1);

    return (
      <Measure
        bounds
        onResize={ contentRect => {
          this.setState({ bounds: contentRect.bounds })
        } }
      >
        { ({ measureRef })  => (

          <div className="circles__new" ref={ measureRef }>
            { circleData.map((circleObj, index) => {

              const circleBounds = setClass({
                default: defaultCircleDimensions,
                tabletLg: tabletLgCircleDimensions,
                tabletSm: tabletSmCircleDimensions,
                mobileLg: mobileLgCircleDimensions,
                mobileMd: mobileMdCircleDimensions,
                mobileSm: mobileSmCircleDimensions,
                mobileXsm: mobileXsmCircleDimensions
              })[index];

              const width = circleBounds.diameter;
              const left = `${circleBounds.left / bounds.width * 100}%`;
              const height = circleBounds.diameter;
              const top = `${circleBounds.top / bounds.height * 100}%`;

              const styles = {
                top,
                left,
                width,
                height
              };

              const circleState = this.state[`circle${index}`];
              const carouselDelay = 4;
              // const carouselItemIndex = Math.max(0, autoplayElements.findIndex(el => el.title === circleObj.title)) % carouselDelay;
              const carouselItemIndex = (index%3)+1;

              return (
                <div
                  key={ index }
                  className={ `circles__item circle-${index}` }
                  style={ styles }
                >
                  <div className="circles__carousel">
                    <Carousel
                      showArrows={ false }
                      draggable={ false }
                      autoPlay={ carouselDelay * 1000 }
                      // autoPlay={ 1000 }
                      onChange={ event => this.setState({ [`circle${index}`]: event }) }
                      delayAutoplay={ carouselItemIndex * 1333 }
                      slides={
                        circleObj.images.map((img, circleIndex) => (
                          <img
                            key={ circleIndex }
                            src={ `${get(img, 'file.url')}?fm=webp&w=${isRetinaDisplay() ? width * 2 : width}` }
                            alt={ get(img, 'title') }
                          />
                        ))
                      }
                    />
                  </div>

                  { !!get(circleObj.images[circleState], 'description') && (
                    <svg
                      className={ `circles__caption ${this.getPositionClass(circleBounds)}` }
                      viewBox={ `0 0 ${circleBounds.diameter} ${circleBounds.diameter}` }
                    >
                      <path id={ `curve-${index}` } d={ getCirclePath(circleBounds.diameter / 2) } />
                      <text className="typ--jp">
                        <textPath xlinkHref={ `#curve-${index}` }>
                          { circleObj.images[circleState].description }
                        </textPath>
                      </text>
                    </svg>
                  ) }
                </div>
              );
            }) }
          </div>
        ) }
      </Measure>
    );
  }
}

const { array, func } = PropTypes;
Circles.propTypes = {
  circleData: array,
  setClass: func
};

export default bpConnect(Circles);
