import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { mapStateToContent } from "utils/cms";
import { kebabCase, get, isEmpty } from "lodash";
import { TweenMax, TimelineMax, Power3, Elastic } from "gsap";
import bpConnect from "components/bpConnect";
// import WhyJapan from "routes/WhyJapan";
// import WhatWeDo from "routes/WhatWeDo";
// import OurValues from "routes/OurValues";
// import Locations from "routes/Locations";
import { boundsToJSON } from "utils/helpers";
import Circles from "./Circles";
import Lottie from "lottie-react-web";
import wil_logo from "./lottie/wil_logo.json";
import { setFirstLoad } from "redux/actions";
import FancyLink from "components/FancyLink";
import Footer from "components/Footer";
import Carousel from "components/Carousel";
import isExternal from 'is-url-external';
import "./HomeNew.scss";

class HomeNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = { animating: false };
    this.onInComplete = this.onInComplete.bind(this);
    this.onOutComplete = this.onOutComplete.bind(this);
    this.nextSection = this.nextSection.bind(this);
    // this.scrollHandler = this.scrollHandler.bind(this);
    this.animateCircle = this.animateCircle.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.resetCirclePosition = this.resetCirclePosition.bind(this);
  }

  resetCirclePosition() {
    if (this.circle) this.circle.style.cssText = "";
  }

  // scrollHandler(event) {
  // if (this.props.bpIsLessThan('tabletLg')) return false;
  // return false;
  // if (event.deltaY > 0) this.animateCircleOut();
  // }

  componentDidMount() {
    //this.debouncedScrollHandler = debounce(this.scrollHandler, 250, { leading: true, trailing: false });
    //window.addEventListener('wheel' , this.debouncedScrollHandler);

    const bounds = get(this.props.location, "state.circleBounds");
    if (bounds) {
      this.triggerAnimationOnEnter();
    }
  }

  handleMove(e) {
    if (!this.state.animating) {
      this.parallaxIt(e, this.heroCircle, -40);
      this.container.querySelectorAll(".circles__item").forEach((node, index) => {
        this.parallaxIt(e, node, (index + 4) * -5);
      });
    }
  }

  parallaxIt(e, target, movement) {
    if (this.props.bpIsLessThan("desktopSm")) return false;
    //return false;
    const bounds = this.container.getBoundingClientRect();
    var relX = e.pageX - bounds.left;
    var relY = e.pageY - bounds.top;

    TweenMax.to(target, 5, {
      x: ((relX - bounds.width / 2) / bounds.width) * movement,
      y: ((relY - bounds.height / 2) / bounds.height) * movement
    });
  }

  triggerAnimationOnEnter() {
    this.setState({ animating: true }, () => {
      this.animateCircle();
    });
  }

  componentWillUnmount() {
    //window.removeEventListener('wheel' , this.debouncedScrollHandler);
    //this.container.removeEventListener('mousemove', this.handleMove);
  }

  componentDidUpdate(prevProps) {
    if ((!isEmpty(this.props.content) && isEmpty(prevProps.content)) || (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering" && !get(this.props.location, "state.circleBounds"))) {
      if (this.container) {
        this.animateIn();
        this.container.addEventListener("mousemove", this.handleMove);
      }
    }

    if (this.props.transitionStatus === "exiting" && prevProps !== "entering") {
      this.animatePageOut();
    }
  }

  animateCircle() {
    const tl = new TimelineMax();
    const bounds = this.props.location.state.circleBounds;

    if (!bounds) return false;
    // if (this.props.bpIsLessThan('tabletLg')) return false;
    //return false;
    if (!this.circle) return;

    tl.set(this.splash, { zIndex: 0, pointerEvents: "none" });
    tl.set(this.circle, { position: "fixed" }, "anim");

    tl.fromTo(
      this.circle,
      0.8,
      {
        height: bounds.height,
        width: bounds.width,
        left: bounds.left,
        top: bounds.top
      },
      {
        height: this.heroCircle.offsetHeight,
        width: this.heroCircle.offsetWidth,
        left: window.innerWidth / 2 - this.heroCircle.offsetWidth / 2,
        top: window.innerHeight / 2 - this.heroCircle.offsetHeight / 2,
        ease: Power3.easeInOut
      },
      "anim+=0.3"
    );

    tl.staggerFrom(document.querySelectorAll(".circles__item"), 1.2, { scale: 0, ease: Elastic.easeOut.config(0.8, 0.5) }, 0.02, "anim+=0.4");

    tl.staggerFrom(
      [this.subtitle, this.title],
      0.4,
      {
        marginTop: 30,
        opacity: 0,
        onComplete: () => {
          this.setState({ animating: false }, () => {
            this.container.addEventListener("mousemove", this.handleMove);
          });
        }
      },
      0.3,
      "anim+=0.6"
    );

    tl.set(
      this.circle,
      {
        position: "relative",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%"
      },
      "anim+=0.8"
    );

    tl.set(this.container, { onComplete: this.resetCirclePosition });

    return tl;
  }

  animateIn() {
    const { dispatch, firstLoad } = this.props;
    const tl = new TimelineMax();

    this.setState({ animating: true });
    const delay = firstLoad ? 0 : 1;

    if (!firstLoad) {
      tl.to(this.logo, 0.6, { opacity: 1 }, "anim");

      tl.to(this.splash, 0.6, { opacity: 0 }, "anim+=1.5");

      tl.from(
        document.querySelector(".header"),
        0.6,
        {
          opacity: 0
        },
        "anim+=2.1"
      );

      tl.set(
        this.splash,
        {
          zIndex: 0,
          pointerEvents: "none"
        },
        "anim+=2.1"
      );
    } else {
      tl.set(this.splash, { opacity: 0 }, "anim");
    }

    tl.fromTo(this.circle, 0.6, { scale: 0, ease: Power3.easeOut }, { scale: 1, onComplete: this.onInComplete }, `anim+=${delay + 0.7}`);

    tl.to(this.logo, 0.6, { scale: 0.7, opacity: 0, ease: Power3.easeOut }, `anim+=${delay + 0.7}`);

    tl.staggerFrom(document.querySelectorAll(".circles__item"), 0.5, { scale: 0, ease: Power3.easeOut }, 0.02,`anim+=${delay + 0.75}`).set(document.querySelectorAll(".circles__caption"),{ fontSize: "14px" }).to(document.querySelectorAll(".circles__caption"), 1, { opacity:1 });

    tl.staggerFrom([this.title, this.subtitle], 3, { opacity: 0, ease: Power3.easeOut }, 0.6, `anim+=${delay + 1.5}`);

    // tl.from(this.arrow, 0.7, { opacity: 0, marginTop: -20 }, `anim+=${delay + 2.8}`);

    dispatch(setFirstLoad(true));

    return tl;
  }

  animateCircleOut() {
    // const tl = new TimelineMax();
    // if (this.props.bpIsLessThan('tabletLg')) return false;
    return false;

    // tl.staggerTo([this.title, this.subtitle, this.arrow], 0.5, { marginTop: 30, opacity: 0 }, 0.2, "anim");

    // tl.staggerTo(document.querySelectorAll(".circles__item"), 0.5, { scale: 0 }, 0.04, "anim+=0.2");

    // tl.set(this.circle, { onComplete: this.onOutComplete }, "anim+=0.5");

    // return tl;
  }

  animatePageOut() {
    // const tl = new TimelineMax();
    // if (this.props.bpIsLessThan('tabletLg')) return false;
    return false;

    // tl.to(this.container, 1, { opacity: 0 }, "anim");

    // tl.staggerTo([this.title, this.subtitle, this.arrow], 0.4, { marginTop: 30, opacity: 0 }, 0.2, "anim");

    // tl.staggerTo(document.querySelectorAll(".circles__item"), 0.4, { scale: 0 }, 0.04, "anim+=0.2");

    // return tl;
  }

  onInComplete() {
    this.setState({ animating: false });
  }

  onOutComplete() {
    this.props.history.push({
      pathname: "/us-japan-global-bridge",
      state: { circleBounds: this.circle && boundsToJSON(this.circle.getBoundingClientRect()) }
    });
  }

  nextSection() {
    const targetPosition = document.querySelector(".home__new__ctabanner__wrapper").getBoundingClientRect().top + window.pageYOffset;
    TweenMax.to(document.scrollingElement, 0.4, { scrollTop: targetPosition });
  }

  markSizeDown(string) {
    string = string.replace("B","<div class=\"stat__text__small\">B</div>");
    string = string.replace("M","<div class=\"stat__text__small\">M</div>");
    string = string.replace("$","<div class=\"stat__text__small\">$</div>");
    return { __html: string }
  }

  render() {
    // const { content, bpIsLessThan, setClass, bps, language } = this.props;
    const { content, setClass, language, bpIsLessThan } = this.props;
    const { animating } = this.state;
    const pageContent = get(content, "orderedContent[0]");
    const orderedContent = content["orderedContent"];

    // ---------- Waiting for data
    if (!content.orderedContent) {
      return null; 
    }

    // const statsContent = orderedContent.find(v => v.id == "home-stats");

    /** Returns link in insight data, or produced link to the hosted article */
    const link = insight => insight.link || `/blog/${kebabCase(insight.title)}`;

    const splash = (
      <div
        className="home__splash"
        ref={ el => {
          this.splash = el;
        } }
        style={ { height: window.innerHeight } }
      >
        <div
          ref={ el => {
            this.logo = el;
          } }
          className="home__splash__logo"
        >
          <Lottie
            options={ {
              animationData: wil_logo,
              loop: false,
              autoplay: false
            } }
          />
        </div>
      </div>
    );

    if (!pageContent) return splash;

    return (
      <React.Fragment>
        {splash}
        <div
          className="home__new"
          ref={ el => {
            this.container = el;
          } }
        >
          <div className="home__wrapper__new">
            {pageContent.subsections && <Circles circleData={ pageContent.subsections } />}
            <div
              className={ `herocircle__new ${animating ? "is-animating" : ""}` }
              ref={ el => {
                this.heroCircle = el;
              } }
            >
              <div
                className="circle__new"
                ref={ el => {
                  this.circle = el;
                } }
              >
                <div className="circle__content__new">
                  <h1
                    className={ `typ--semibold typ--jp ${setClass({ default: "mb2", mobileLg: "mb1" })}` }
                    ref={ el => {
                      this.title = el;
                    } }
                  >
                    {pageContent.title}
                  </h1>
                  <h4
                    className={ `typ--jp ${setClass({ mobileLg: "typ--b1" })}` }
                    ref={ el => {
                      this.subtitle = el;
                    } }
                  >
                    {pageContent.subtitle}
                  </h4>
                  {/* <button
                    ref={ el => {
                      this.arrow = el;
                    } }
                    onClick={ () => this.nextSection() }
                    className="icon-long-arrow-down"
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row layout--back">
            {/* -------------------- CTA banner -----*/}
            { orderedContent.find(v => v.id == "home-cta-banner").text ?
              <div className="cf mt8 pt10 pb10 home__new__ctabanner__wrapper full-bleed">
                <div className={ `${setClass({ default: "gridspacer col--1", mobileLg: "" })}` } />
                <div className={ `home__new__ctabanner__container ${setClass({ default: "col--10", mobileLg: "col--12" })}` }>
                  <div className="home__new__ctabanner__text">
                    <h5>{orderedContent.find(v => v.id == "home-cta-banner").text}</h5>
                  </div>
                  <div className="home__new__ctabanner__button ml2">
                    <FancyLink
                      to={ orderedContent.find(v => v.id == "home-cta-banner").ctaDestination }
                    >
                      {orderedContent.find(v => v.id == "home-cta-banner").ctaDestinationText}
                    </FancyLink>
                  </div>
                </div>
                <div className={ `${setClass({ default: "gridspacer col--1", mobileLg: "" })}` } />
              </div>
              :
              <div className="cf mt8 " />
            }
            {/* -------------------- Our approach -----*/}
            <div className="cf pt8">
              <div className="gridspacer col--3" />
              <div className="col--9 layout--right home__new__bridge">
                <img src={ `${orderedContent.find(v => v.id == "home-our-approach").image.file.url}?fm=webp&w=1000` } alt="bridge" />
              </div>
            </div>
            <div className="cf pt2 pb2 home__new__reverseForMobile">
              <div
                className={ `home__new__approach__circle__container 
                ${setClass({
        default: "col--4",
        desktopSm: "col--5",
        mobileLg: "col--12"
      })}` }
              >
                <div className="circles__carousel home__new__carousel__left mt4">
                  <Carousel
                    showArrows={ false }
                    showDots
                    draggable
                    autoPlay={ 3000 }
                    slides={ orderedContent.find(v => v.id == "home-our-approach").subsections[0].images.map((img, circleIndex) => (
                      <img key={ circleIndex } src={ `${get(img, "file.url")}?fm=webp&w=500` } alt={ get(img, "title") } />
                    )) }
                  />
                </div>
                <div className="home__new__approach__circle" />
              </div>
              <div
                className={ `${setClass({
                  default: "col--8",
                  desktopSm: "col--7",
                  mobileLg: "col--12"
                })}` }
              >
                <h2 className="typ--primary pb2">{orderedContent.find(v => v.id == "home-our-approach").title}</h2>
                <p>{orderedContent.find(v => v.id == "home-our-approach").text}</p>
                <div className="btn__container">
                  <FancyLink to={ orderedContent.find(v => v.id == "home-our-approach").ctaDestination } className="btn layout--right">
                    {orderedContent.find(v => v.id == "home-our-approach").ctaDestinationText}
                  </FancyLink>
                </div>
                <p className="mt8">{orderedContent.find(v => v.id == "home-our-approach-2").text}</p>
                <div className="btn__container">
                  <FancyLink to={ orderedContent.find(v => v.id == "home-our-approach-2").ctaDestination } className="btn layout--right">
                    {orderedContent.find(v => v.id == "home-our-approach-2").ctaDestinationText}
                  </FancyLink>
                </div>
              </div>
            </div>

            {/* -------------------- WiL at a glance -----
            { language==="jp" &&
            <div
              className={ `cf home__new__stats full-bleed ${setClass({
                default: "mb8 pt8 pb7",
                tabletLg: "mb6 mt4 pt6 pb6",
                mobileLg: "mb8 pt6 pb6"
              })}` } 
            >
              <div 
                className={ ` ${setClass({
                  default: "gridspacer col--1",
                  mobileLg: " "
                })}` } 
              />
              <div className={ ` ${setClass({
                default: "col--10",
                mobileLg: "col--12"
              })}` } 
              >
                { statsContent.title &&
                  <h2 className="typ--primary">{statsContent.title}</h2>
                }
                <ul className="home__new__stats__container">
                  { statsContent.subsections.map((stat) => (
                    <li className="home__new__stats__box">
                      <div className="home__new__stat__number" dangerouslySetInnerHTML={ this.markSizeDown(stat.statText) } />
                      <p className="home__new__stat__caption">{ stat.statCaption }</p>
                    </li>
                  )
                  )}
                </ul>
              </div>
              <div 
                className={ ` ${setClass({
                  default: "gridspacer col--1",
                  mobileLg: " "
                })}` } 
              />
            </div>
            }
            */}

            {/* -------------------- Our companies -----*/}
            <div className="cf mb4">
              <div className="gridspacer col--1" />
              <div
                className={ `${setClass({
                  default: "col--7",
                  desktopSm: "col--6",
                  mobileLg: "col--12"
                })}` }
              >
                { orderedContent.find(v => v.id == "home-our-companies").title &&
                  <h2 className="typ--primary">{orderedContent.find(v => v.id == "home-our-companies").title}</h2>
                }
                { orderedContent.find(v => v.id == "home-our-companies").subtitle &&
                  <h3 className="typ--primary mt4 pb2">{orderedContent.find(v => v.id == "home-our-companies").subtitle}</h3>
                }
                <p>{orderedContent.find(v => v.id == "home-our-companies").text}</p>
                { orderedContent.find(v => v.id == "home-our-companies").ctaDestination &&
                <div className="btn__container">
                  <FancyLink to={ orderedContent.find(v => v.id == "home-our-companies").ctaDestination } className="btn layout--right">
                    {orderedContent.find(v => v.id == "home-our-companies").ctaDestinationText}
                  </FancyLink>
                </div>
                }
                <h3 className="typ--primary mt4 pb2">{orderedContent.find(v => v.id == "home-our-companies-2").title}</h3>
                <p>{orderedContent.find(v => v.id == "home-our-companies-2").text}</p>
                { orderedContent.find(v => v.id == "home-our-companies-2").ctaDestination &&
                <div className="btn__container">
                  <FancyLink to={ orderedContent.find(v => v.id == "home-our-companies-2").ctaDestination } className="btn layout--right">
                    {orderedContent.find(v => v.id == "home-our-companies-2").ctaDestinationText}
                  </FancyLink>
                </div>
                }
              </div>
              <div
                className={ `${setClass({
                  default: "col--4",
                  desktopSm: "col--5",
                  mobileLg: "col--12"
                })}` }
              >
                <div className="circles__carousel home__new__carousel__right mt4">
                  <Carousel
                    showArrows={ false }
                    showDots
                    draggable
                    autoPlay={ 4000 }
                    slides={ orderedContent.find(v => v.id == "home-our-companies").subsections[0].images.map((img, circleIndex) => (
                      <img key={ circleIndex } src={ `${get(img, "file.url")}?fm=webp&w=600` } alt={ get(img, "title") } />
                    )) }
                  />
                </div>
              </div>
            </div>

            {/* -------------------- Our focusing area -----*/}
            { language==="en" &&
            <div className="home__new__reverseForMobile home__new__borderTop__mobile">
              <div className="cf mb2">
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
                <div
                  className={ `home__new__ourPrinciples__image ${setClass({
                    default: "col--10",
                    mobileLg: "col--12"
                  })}` }
                >
                  {bpIsLessThan('mobileLg')? 
                    <img src={ `${orderedContent.find(v => v.id == "home-our-focusing-aera").subimage.file.url}?fm=webp&w=800` } alt={ orderedContent.find(v => v.id == "home-our-focusing-aera").subimage.title } /> 
                    : 
                    <img src={ `${orderedContent.find(v => v.id == "home-our-focusing-aera").image.file.url}?fm=webp&w=1600` } alt={ orderedContent.find(v => v.id == "home-our-focusing-aera").image.title } /> }
                </div>
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
              </div>
              <div className="cf mb2">
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
                <div
                  className={ `${setClass({
                    default: "col--10",
                    mobileLg: "col--12"
                  })}` }
                >
                  <h3 className={ `typ--primary typ--center ${setClass({
                    default: "pb6",
                    mobileLg: "pb0"
                  })}` }>
                    {orderedContent.find(v => v.id == "home-our-focusing-aera").subtitle}
                  </h3>
                </div>
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
              </div>
            </div>
            }

            {/* -------------------- Our principles -----*/}
            <div className="home__new__reverseForMobile home__new__borderTop__mobile">
              <div className="cf mb2">
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
                <div
                  className={ `home__new__ourPrinciples__image ${setClass({
                    default: "col--10",
                    mobileLg: "col--12"
                  })}` }
                >
                  {bpIsLessThan('mobileLg')? 
                    <img src={ `${orderedContent.find(v => v.id == "home-our-principles").subimage.file.url}?fm=webp&w=800` } alt={ orderedContent.find(v => v.id == "home-our-principles").subimage.title } /> 
                    : 
                    <img src={ `${orderedContent.find(v => v.id == "home-our-principles").image.file.url}?fm=webp&w=1600` } alt={ orderedContent.find(v => v.id == "home-our-principles").image.title } /> }
                </div>
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
              </div>
              <div className="cf mb2">
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
                <div
                  className={ `${setClass({
                    default: "col--3",
                    mobileLg: "col--12"
                  })}` }
                >
                  <h2 className="typ--primary pb2">{orderedContent.find(v => v.id == "home-our-principles").title}</h2>
                </div>
                <div
                  className={ `${setClass({
                    default: "col--7",
                    mobileLg: "col--12"
                  })}` }
                >
                  <p>{orderedContent.find(v => v.id == "home-our-principles").text}</p>
                  <div className="btn__container">
                    <FancyLink to={ orderedContent.find(v => v.id == "home-our-principles").ctaDestination } className="btn layout--right">
                      {orderedContent.find(v => v.id == "home-our-principles").ctaDestinationText}
                    </FancyLink>
                  </div>
                </div>
                <div
                  className={ `${setClass({
                    default: "gridspacer col--1",
                    mobileLg: "col--0"
                  })}` }
                />
              </div>
            </div>

            {/* -------------------- Our Blog -----*/}
            <div className="cf mt8 pb10 home__new__ourblog__wrapper">
              <div className="gridspacer col--1" />
              <div
                className={ `home__new__ourblog__container ${setClass({
                  default: "col--4",
                  mobileLg: "col--10"
                })}` }
              >
                <div className="home__new__ourblog__circle" />
                <div className="home__new__ourblog__layout">
                  <div className="home__new__ourblog ">
                    {bpIsLessThan("mobileLg") ? <p>Our Blog<br />↓</p> : <p>Our Blog →</p>}
                  </div>
                  {/* { orderedContent.find(v => v.id == "home-our-blog").subsections[0].image &&
                  <div className="home__new__ourblog__image">
                    <img src={ orderedContent.find(v => v.id == "home-our-blog").subsections[0].image.file.url } alt={ orderedContent.find(v => v.id == "home-our-blog").subsections[0].image.title } />
                  </div>
                  } */}
                </div>
              </div>
              <div
                className={ `home__new__ourblog__container ${setClass({
                  default: "col--6",
                  mobileLg: "col--10"
                })}` }
              >
                <div className="home__new__ourblog__mobileBox">
                  <React.Fragment>
                    <h3 className="pb2 typ--jp">{orderedContent.find(v => v.id == "home-our-blog-with-JP").subsections[0].title}</h3>
                    <FancyLink
                      to={ link(orderedContent.find(v => v.id == "home-our-blog-with-JP").subsections[0]) }
                      className="btn layout--right"
                      onClick={ () => {
                        if (isExternal(link(orderedContent.find(v => v.id == "home-our-blog-with-JP").subsections[0]))) return null;

                        // Link with this page's history, for returning from article
                        // history.push({
                        //   pathname: link(insight),
                        //   state: { returnTo: location.pathname, ...location.state }
                        // })
                      } }
                    >
                      {orderedContent.find(v => v.id == "home-our-blog-with-JP").ctaDestinationText}
                    </FancyLink>
                  </React.Fragment>
                </div>
                <div className="gridspacer col--1" />
              </div>
            </div>
          </div>

          {/* <WhyJapan />
              <WhatWeDo />
              <OurValues />
              <Locations /> */}
        </div>

        <Footer nextPage={ { label: "People", pathname: "/team" } } language={ language } />
      </React.Fragment>
    );
  }
}

const { object, string, func, bool } = PropTypes;
HomeNew.propTypes = {
  content: object,
  location: object,
  history: object,
  transitionStatus: string,
  bpIsLessThan: func,
  setClass: func,
  firstLoad: bool,
  dispatch: func,
  // bps: object,
  language: string
};

export default bpConnect(connect(mapStateToContent("home-new"))(HomeNew));
