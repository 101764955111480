import React from 'react';
import PropTypes from 'prop-types';
import './Dropdown.scss';

export class Dropdown extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  selectItem (item) {
    const { items, onChange } = this.props;
    const itemIndex = items.indexOf(item);
    this.setState({ open: false });
    if (onChange instanceof Function) onChange(itemIndex);
  }

  toggleOpen () {
    this.setState({ open: !this.state.open })
  }

  handleBlur () {
    this.setState({ open: false });
  }

  render () {
    const { items, selectedIndex } = this.props;
    const { open } = this.state;

    return (
      <div
        className={ `dropdown ${open ? 'is-open' : ''}` }
        onBlur={ this.handleBlur }
        tabIndex="-1"
      >
        <div className="dropdown__toggle" onClick={ this.toggleOpen }>
          { items[selectedIndex] || 'Select an item' }
          <span className="dropdown__icon icon-arrow-down" />
        </div>
        <ul className="dropdown__menu">
          { items.map((item, index) => (
            <li
              key={ index }
              onClick={ () => this.selectItem(item) }
              className={ selectedIndex === index ? 'is-selected' : '' }
            >{ item }</li>
          )) }
        </ul>

        <select
          className="dropdown__phantom-menu"
          value={ items[selectedIndex] }
          readOnly
        >
          { items.map((item, index) => (
            <option
              key={ index }
              value={ item }
            >{ item }</option>
          )) }
        </select>
      </div>
    );
  }
}

const { array, func, number } = PropTypes;
Dropdown.propTypes = {
  items: array,
  onChange: func,
  selectedIndex: number
};

export default Dropdown;