export function boundsToJSON (bounds) {
  return {
    bottom: bounds.bottom,
    height: bounds.height,
    left: bounds.left,
    right: bounds.right,
    top: bounds.top,
    width: bounds.width,
    x: bounds.x
  };
}

export function getCirclePath(r){
  return `M ${r} ${r} m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${r*2}, 0`;
}

export function isValidEmail (email) {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

export function isRetinaDisplay () {
  if (window.matchMedia) {
    const mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
    return (mq && mq.matches || (window.devicePixelRatio > 1));
  }
}
