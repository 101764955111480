import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapStateToContent } from 'utils/cms';
import { kebabCase, get } from 'lodash';
import bpConnect from 'components/bpConnect';
import { TimelineMax } from 'gsap';
import './Quote.scss';

export class Quote extends React.Component {
  constructor (props) {
    super (props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal () {
    this.props.history.push('/companies');
  }

  componentDidUpdate (prevProps) {
    if (this.props.transitionStatus === 'entering' && prevProps.transitionStatus !== 'entering') {
      this.animateIn();
    }

    if (this.props.transitionStatus === 'exiting' && prevProps.transitionStatus !== 'exiting') {
      this.animateOut();
    }
  }

  animateIn () {
    const tl = new TimelineMax();

    tl.from([this.container, this.img], 0.2, { opacity: 0 }, 'anim');
    tl.staggerFrom([this.close, this.quote, this.author], 0.3, { y: 40, opacity: 0 }, 0.04, 'anim+=0.03');

    return tl;
  }

  animateOut () {
    const tl = new TimelineMax();

    tl.staggerTo([this.author, this.quote, this.close], 0.3, { y: 40, opacity: 0 }, 0.04, 'anim');
    tl.to([this.container, this.img], 0.2, { opacity: 0 }, 'anim+=0.4');

    return tl;
  }

  render () {
    const { content, location, setClass } = this.props;
    const companiesData = content.orderedContent;
    if (!companiesData) return false;

    const quotes = companiesData.find(data => data.id === 'company-carousel');
    const activeQuote = quotes.subsections.find(q => kebabCase(q.name) === location.pathname.split('/')[2]);

    return (
      <div className="quote" ref={ el => { this.container = el; } }>
        <div className="modal__close" onClick={ this.closeModal } ref={ el => { this.close = el; } }>
          <span className="icon-close" />
        </div>

        <div className="row">
          <div className="quote__bg" ref={ el => { this.img = el; } }>
            <img src={ get(activeQuote, 'image.file.url') } alt={ get(activeQuote, 'image.name') } />
          </div>

          <p className={ `mb2 ${setClass({ default: 'typ--h5', mobileXsm: 'typ--b2' })}` } ref={ el => { this.quote = el; } }>
            “{ activeQuote.quote }”
          </p>

          <p className={ `typ--bold ${setClass({ mobileXsm: 'typ--b2' })}` } ref={ el => { this.author = el; } }>
            &mdash; { activeQuote.author }
          </p>
        </div>
      </div>
    );
  }
}

const { object, func, string } = PropTypes;
Quote.propTypes = {
  history: object,
  content: object,
  location: object,
  setClass: func,
  transitionStatus: string
};


export default bpConnect(connect(mapStateToContent('companies'))(Quote));
