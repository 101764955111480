import React from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { flatten, uniq, kebabCase, isEmpty, sortBy } from "lodash";
import queryString from "query-string";
import { mapStateToContent, fetchContent } from "utils/cms";
import FancyLink from "components/FancyLink";
import Dropdown from "components/Dropdown";
import bpConnect from "components/bpConnect";
import Footer from "components/Footer";
import GetOGPImage from "components/GetOGPImage";
import { TimelineMax, TweenMax } from "gsap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import date from "date-and-time";
import "./Blog.scss";

/** Returns list of insight tag strings */
const tagNames = insight => (insight.tags ? insight.tags.map(t => t.name) : []);

/** Returns link in insight data, or produced link to the hosted article */
const link = insight => insight.link || `/blog/${kebabCase(insight.title)}`;

const truncate = (string, length) => {
  if (string.length < length) return string;

  const newString = string.slice(0, length).trim();
  return [newString[newString.length - 1] === "," ? newString.slice(0, newString.length - 1).trim() : newString, "..."].join("");
};

export class Blog extends React.Component {
  constructor(props) {
    super(props);

    const filter = queryString.parse(props.location.search).tag || "All";

    this.state = { filter, start: 0, perPage: 10, authors:[], authorsJp:[] };
    this.animateIn = this.animateIn.bind(this);
    this.animateOut = this.animateOut.bind(this);
    this.filterChangeHandler = this.filterChangeHandler.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.ogpRef = React.createRef([]);
  }

  componentDidMount () {
    // ---------- Get team members name for creating link 
    fetchContent("en").then(response => {
      let teamMembersContent = response.items.find(e => e.fields.id === "team").fields.orderedContent;
      let teamMembersName = teamMembersContent.map(el => el.fields.name);
      this.setState({
        authors: teamMembersName
      });
    });
    fetchContent("jp").then(response => {
      let teamMembersContentJp = response.items.find(e => e.fields.id === "team").fields.orderedContent;
      let teamMembersNameJp = teamMembersContentJp.map(el => el.fields.name);
      this.setState({
        authorsJp: teamMembersNameJp
      });
    });
  }

  componentDidUpdate(prevProps) {
    if ((this.props.content && isEmpty(prevProps.content)) || (this.props.transitionStatus === "entering" && prevProps.transitionStatus !== "entering")) {
      this.animateIn();
    }

    // If exiting, animate out
    if (this.props.transitionStatus === "exiting" && prevProps.transitionStatus !== "exiting") {
      this.animateOut();
    }

    if (prevProps.language && prevProps.language !== this.props.language) {
      this.filterChangeHandler(0, this.tags);
    }
  }

  animateIn() {
    const tl = new TimelineMax();
    if (!this.container) return false;
    let entries = [...this.container.querySelectorAll(".blog__item")].slice(0, 4);

    tl.from(this.container, 0.4, { opacity: 0 }, "anim+=0.2");

    const staggerElems = [this.circle, this.title, this.tagsElem, this.followUsOn, ...entries].filter(el => el);

    tl.staggerFrom(
      staggerElems,
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.4"
    );

    return tl;
  }

  animateOut() {
    const tl = new TimelineMax();
    const entries = [...this.container.querySelectorAll(".blog__item")].slice(0, 4);
    const staggerElems = [this.followUsOn, this.tagsElem, this.title, this.circle].filter(el => el);

    tl.staggerTo(
      [...entries],
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim"
    );

    tl.staggerTo(
      staggerElems,
      0.4,
      {
        y: 40,
        opacity: 0
      },
      0.08,
      "anim+=0.16"
    );

    tl.to(this.container, 0.4, { opacity: 0 }, "anim+=0.4");

    return tl;
  }

  filterChangeHandler(index, tags) {
    this.setState({ filter: tags[index] });
    TweenMax.to(this.scroller, 0.6, { scrollTop: 0 });
  }

  pageChange(data) {
    let pageNumber = data["selected"]; //選択されたページ番号
    this.setState({
      //スタート位置をページ番号 * 1ページあたりの数、とする(例えば2番を選ぶと12 * 1で12番が先頭になる、つまり13番目以降の書籍が表示される)
      start: pageNumber * this.state.perPage
      // start: 10
    });
    // Scroll function
    const currentScrollPosition = document.querySelector(".scroller").scrollTop;
    const targetPosition = currentScrollPosition + document.querySelector(".blog__layout").getBoundingClientRect().top + window.pageYOffset - 100;
    TweenMax.to(document.querySelector(".scroller"), 0.4, { scrollTop: targetPosition });
    // this.ogpRef.current.getOGPUrl();
    // console.log(this.ogpRef);
  }

  render() {
    // const { content, language, setClass, bpIsGreaterThan, bpIsLessThan } = this.props;
    const { content, language, setClass, bpIsGreaterThan } = this.props;
    const { filter, authors, authorsJp } = this.state;

    const truncateBy = bpIsGreaterThan("desktopMd") ? 35 : 25;

    // let url = encodeURI(window.location.href);
    // let postBody = "WiL Blog";

    
    const social = [
      { name: "Facebook", link: `https://www.facebook.com/worldinnovationlab/` }, 
      { name: "Twitter", link: `https://twitter.com/wilvc_us` }, 
      { name: "LinkedIn", link: `https://www.linkedin.com/company/world-innovation-lab-wil-/` }
    ];

    const social_jp = [
      { name: "Facebook", link: `https://www.facebook.com/worldinnovationlab/` }, 
      { name: "Twitter", link: `https://twitter.com/WiL_jp_pr` }, 
      { name: "LinkedIn", link: `https://www.linkedin.com/company/world-innovation-lab-wil-/` }
    ];

    if (!content.orderedContent) {
      return null; // Waiting for data
    } else {
      // Unique list of filterable tags found in insights
      this.tags = [...uniq(flatten(content.orderedContent.filter(insight => insight.language === language).map(insight => tagNames(insight))))].sort();
      this.tags.unshift("All");

      const insights = content.orderedContent.filter(
        insight =>
          // Language appropriate
          (!insight.language || insight.language === language) &&
          // Matches filters
          (filter === "All" || tagNames(insight).includes(filter)) &&
          // Has content
          // && (insight.link || (insight.text && insight.text.length > 0))
          // Has publication date
          (!insight.publicationDate ||
            new moment(insight.publicationDate)
              .utc()
              .add(moment.parseZone(insight.publicationDate).utcOffset(), "m")
              .format("YYYY-MM-DD HH:mm:ss") < new moment.utc().add(moment.parseZone(insight.publicationDate).utcOffset(), "m").format("YYYY-MM-DD HH:mm:ss"))
      );

      const sortPinned = insight => (insight.pinnedTags && insight.pinnedTags.map(tag => tag.name).includes(filter) ? 0 : 1);

      const sortDates = insight => (insight.date ? new Date() - new Date(insight.date) : new Date() - new Date("1900-01-01"));

      const sortedInsights = sortBy(insights, [sortPinned, sortDates]);

      return (
        <div
          className="scroller"
          ref={ el => {
            this.container = el;
          } }
        >
          <div
            className={ `blog ${setClass({
              default: "row pt8 pb6",
              desktopSm: "row pt8 pb6",
              tabletMd: "row py4"
            })}` }
          >
            <div className="cf">
              {/* ---------------------------------------- Title ---------- */}
              <div className={ `insights__filters ${setClass({ default: "col--6 layout--left", tabletMd: "col--12" })}` }>
                {/* { bpIsGreaterThan('tabletMd') &&
                <div className="insights__circle" ref={ el => { this.circle = el; } } />
              } */}

                <div className="insight__title__wrapper" >
                  <h1
                    ref={ el => {
                      this.title = el;
                    } }
                    className={ `sectiontitle typ--jp ${setClass({ desktopMd: "typ--h3" })}` }
                  >
                    {content.subtitle}
                  </h1>

                  <div
                    ref={ el => {
                      this.tagsElem = el;
                    } }
                    style={ { maxWidth: "250px" } }
                  >
                    <Dropdown items={ this.tags } selectedIndex={ this.tags.indexOf(filter) } onChange={ index => this.filterChangeHandler(index, this.tags) } />
                  </div>
                </div>
              </div>
              {/* ---------------------------------------- Follow us on ---------- */}
              {bpIsGreaterThan("tabletMd") && <div className="gridspacer col--1" /> }
              <div
                className={ `blog__followUsOn__wrapper ${setClass({ default: "col--5 layout--right", tabletMd: "col--12" })}` }
                ref={ el => {
                  this.followUsOn = el;
                } }
              >
                <h3 className={ `${setClass({ tabletMd: "typ--h4" })}` }>Follow us on</h3>
                <div className="blog__social mt1">
                  <ul>
                    { language === "en" ?
                      social.map((soc, ind) => (
                        <li key={ ind }>
                          <a key={ ind } href={ soc.link } target="_blank" rel="noopener noreferrer">
                            <i className={ `icon-${soc.name.toLowerCase()}` } />
                          </a>
                        </li>
                      ))
                      :
                      social_jp.map((soc, ind) => (
                        <li key={ ind }>
                          <a key={ ind } href={ soc.link } target="_blank" rel="noopener noreferrer">
                            <i className={ `icon-${soc.name.toLowerCase()}` } />
                          </a>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* -------------------- List of blog articles ----------*/}
          <div className="row">
            <div className="cf">
              <div
                ref={ el => {
                  this.scroller = el;
                } }
                className={ `insights__window ${setClass({ default: "col--12", tabletMd: " " })}` }
              >
                <ul className="blog__layout">
                  {sortedInsights.slice(this.state.start, this.state.start + this.state.perPage).map((insight, index) => (
                    <li className="blog__item" key={ index }>
                      {insight.link || (insight.text && insight.text.length > 0) ? (
                        <FancyLink to={ link(insight) }>
                          <div className="cf mb2">
                            { language==="en" && 
                            (authors && authors.indexOf(insight.author) >= 0 ? 
                              // ----- Link to WiL's members' prof page
                              insight.author && 
                              <Link
                                className="blog__author__link"
                                to={ {
                                  pathname:`/team/${kebabCase(insight.author)}`,
                                  state: { memberBounds: 0 }
                                } }                          
                                onClick={ () => {
                                  // history setting might be added later.
                                } }
                              >
                                <span className="typ--bold layout--left typ--jp">{truncate(insight.author, truncateBy)}</span>
                              </Link>
                              : 
                              // ----- Other authors
                              insight.author && <span className="typ--bold layout--left typ--jp">{truncate(insight.author, truncateBy)}</span>
                            )
                            }
                            { language==="jp" && 
                            ((authorsJp && authorsJp.indexOf(insight.author) >= 0) || (authors && authors.indexOf(insight.author)) >= 0 ? 
                              // ----- Link to WiL's members' prof page
                              insight.author && 
                              <Link
                                className="blog__author__link"
                                to={ {
                                  pathname:`/team/${ kebabCase(authors[authorsJp.indexOf(insight.author)] || kebabCase(insight.author) )}`,
                                  state: { memberBounds: 0 }
                                } }                          
                                onClick={ () => {
                                  // history setting might be added later.
                                } }
                              >
                                <span className="typ--bold layout--left typ--jp">{truncate(insight.author, truncateBy)}</span>
                              </Link>
                              : 
                              // ----- Other authors
                              insight.author && <span className="typ--bold layout--left typ--jp">{truncate(insight.author, truncateBy)}</span>
                            )
                            }
                            {bpIsGreaterThan("tabletMd") && <span className="layout--right">{truncate(tagNames(insight).join(", "), truncateBy)}</span>}
                          </div>

                          {insight.image ? 
                            <div className="blog__item__img mr2">
                              <img src={ insight.image.file.url } alt="" />
                            </div>
                            :
                            <GetOGPImage blogpost={ insight } />
                          }

                          <p className="blog__item__date typ--light">{date.transform(insight.date, "YYYY-MM-DD", "DD MMM, YYYY")}</p>
                          <h4 className={ `mb3 typ--jp ${setClass({ desktopMd: "typ--h4", mobileXsm: "typ--h4" })}` }>{insight.title}</h4>

                          <button className="btn layout--right">Read the article</button>
                        </FancyLink>
                      ) : (
                        <div>
                          <div className="cf mb2">
                            {insight.author && <span className="typ--bold layout--left typ--jp">{truncate(insight.author, truncateBy)}</span>}
                            {bpIsGreaterThan("tabletMd") && <span className="layout--right">{truncate(tagNames(insight).join(", "), truncateBy)}</span>}
                          </div>
                          <h2 className={ ` typ--jp ${setClass({ desktopMd: "typ--h3", mobileXsm: "typ--h4" })}` }>{insight.title}</h2>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="cf pt4 pb8">
              <ReactPaginate
                pageCount={ Math.ceil(insights.length / this.state.perPage) } //総ページ数・。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
                marginPagesDisplayed={ 2 } //先頭と末尾に表示するページの数。今回は2としたので1,2…今いるページの前後…後ろから2番目, 1番目 のように表示されます。
                pageRangeDisplayed={ 5 } //上記の「今いるページの前後」の番号をいくつ表示させるかを決めます。
                onPageChange={ this.pageChange } //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                containerClassName="pagination" //ページネーションリンクの親要素のクラス名
                pageClassName="page-item" //各子要素(li要素)のクラス名
                pageLinkClassName="page-link" //ページネーションのリンクのクラス名
                activeClassName="active" //今いるページ番号のクラス名。今いるページの番号だけ太字にしたりできます
                previousLabel="<" //前のページ番号に戻すリンクのテキスト
                nextLabel=">" //次のページに進むボタンのテキスト
                previousClassName="page-item" // '<'の親要素(li)のクラス名
                nextClassName="page-item" //'>'の親要素(li)のクラス名
                previousLinkClassName="page-link" //'<'のリンクのクラス名
                nextLinkClassName="page-link" //'>'のリンクのクラス名
                disabledClassName="disabled" //先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくするためのクラス
                breakLabel="..." // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
                breakClassName="page-item" // 上記の「…」のクラス名
                breakLinkClassName="page-link" // 「…」の中のリンクにつけるクラス
              />
            </div>
          </div>
          <div className="row">
            <div className="cf pb4 typ--center layout--center">
              <p className="blog__disclaimer">
                <Link to="/privacy-policy">
                  Please refer to our privacy policy page for all disclaimers relating to this content.
                </Link>
              </p>
            </div>
          </div>
          <Footer nextPage={ { label: "Home", pathname: "/" } } language={ language } />
        </div>
      );
    }
  }
}

const { object, string, func } = PropTypes;
Blog.propTypes = {
  content: object,
  language: string,
  location: object,
  setClass: func,
  bpIsGreaterThan: func,
  // bpIsLessThan: func,
  transitionStatus: string
};

export default bpConnect(connect(mapStateToContent("insights"))(Blog));
