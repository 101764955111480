const initialState = {};

const content = (state = initialState, action) => {
  switch(action.type) {
    case 'SET_PAGES': {
      return {
        ...state,
        [action.language]: action.pages
      };
    }
    default: return state;
  }
}

export default content;
